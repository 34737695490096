import React, { useState, useRef, useEffect } from 'react'
import {
  Button,
  Box,
  Typography,
  Popover,
  Avatar,
  Stack,
  Dialog,
  DialogTitle,
} from '@mui/material'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import ChangePasswordModal from '@components/Profile/ChangePassword'
import { useNavigate } from 'react-router-dom'
import { AUTH_LOGOUT, BOX_LOGOUT } from '@/redux/reducers/users/auth.slice'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'
import { ROUTER_PATH } from '@/constants'
import { getIsBox } from '@/utils'

// Profile modal
function AccountProfile() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const ref = useRef(null)
  const [open, setOpen] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [toggleModal, setToggleModal] = useState<boolean>(false)
  const [fullWidth] = useState(true)
  const { currentUser } = useAppSelector(state => state.auth)
  const { isLoading } = useAppSelector(state => state.app)
  const isBox = getIsBox()

  // Open dialog
  const handleOpen = () => {
    setOpen(true)
  }

  // Close dialog
  const handleClose = () => {
    setOpen(false)
  }

  // Open dialog
  const handleSignOut = () => {
    setOpenDialog(true)
  }

  // Call API sign out & navigate to sign in page
  const handleSignOutDialog = () => {
    if (isBox !== '' && JSON.parse(isBox)) {
      dispatch(BOX_LOGOUT())
    } else {
      dispatch(AUTH_LOGOUT())
    }
    navigate(ROUTER_PATH.AUTH.SIGN_IN)
  }
  useEffect(() => {
    // If not sign in, navigate to sign in page
    if (!isLoading && !currentUser.id) navigate(ROUTER_PATH.AUTH.SIGN_IN)
  }, [isLoading])

  return (
    <>
      <Button ref={ref} onClick={handleOpen}>
        <Box sx={{ pl: 1, textAlign: 'right' }}>
          <Typography variant="body1" sx={{ fontWeight: 700 }}>
            {currentUser.name}
          </Typography>
          {!currentUser.isBox && (
            <Typography variant="body2" sx={{}}>
              {t(`header.role_${currentUser.role}`)}
            </Typography>
          )}
        </Box>
        <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
      </Button>
      <Popover
        open={open}
        onClose={handleClose}
        sx={{ width: 300 }}
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mr: 2,
            overflow: 'hidden',
            width: '100%',
            borderRadius: '10px',
            padding: 2.5,
          },
        }}
      >
        <Stack direction="column" spacing={2} alignItems="center">
          {!currentUser.isBox && (
            <Stack direction="column" spacing={2} alignItems="center">
              <Typography textAlign="center">
                {t('header.description_left')}
                <Button
                  variant="text"
                  onClick={() => {
                    navigate(ROUTER_PATH.POLICY)
                  }}
                >
                  BigYouth
                </Button>
                {t('header.description_right')}
              </Typography>
              <Avatar
                alt="avatar"
                src={currentUser.avatar}
                sx={{ width: 100, height: 100 }}
              />
              <Typography textAlign="center" fontWeight="bold" fontSize={18}>
                {currentUser.email}
              </Typography>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                sx={{
                  fontWeight: 600,
                  fontSize: 14,
                  height: 36,
                  borderRadius: '30px',
                }}
                onClick={() => {
                  setOpen(false)
                  navigate(ROUTER_PATH.PROFILE)
                }}
              >
                {t('common.profile_edit')}
              </Button>
            </Stack>
          )}
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            sx={{
              fontWeight: 600,
              fontSize: 14,
              height: 36,
              borderRadius: '30px',
            }}
            onClick={() => setToggleModal(!toggleModal)}
          >
            {t('profile.changePassword')}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              fontWeight: 600,
              fontSize: 14,
              height: 36,
              borderRadius: '30px',
            }}
            onClick={handleSignOut}
          >
            {t('header.sign_out')}
          </Button>
        </Stack>
      </Popover>
      <ChangePasswordModal isOpen={toggleModal} toggleModal={setToggleModal} />
      <Dialog
        onClose={() => setOpenDialog(false)}
        open={openDialog}
        maxWidth="sm"
        fullWidth={fullWidth}
        sx={{
          padding: 3,
        }}
      >
        <Stack direction="column" spacing={8} alignItems="center" p={3}>
          <DialogTitle sx={{ padding: 0, fontWeight: 'bold', fontSize: 45 }}>
            {t('header.sign_out')}
          </DialogTitle>
          <Typography textAlign="center" fontSize={20}>
            {t('confirm.title_confirm')}
          </Typography>
          <Stack direction="row" spacing={4} width="50%">
            <Button
              fullWidth
              variant="contained"
              color="error"
              sx={{
                fontWeight: 600,
                fontSize: 14,
                height: 36,
              }}
              onClick={() => setOpenDialog(false)}
            >
              {t('confirm.cancel')}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                fontWeight: 600,
                fontSize: 14,
                height: 36,
              }}
              onClick={handleSignOutDialog}
            >
              {t('confirm.yes')}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  )
}

export default React.memo(AccountProfile)
