import { all, takeLatest, put, ForkEffect, call } from 'redux-saga/effects'
import {
  FETCH_MONITOR_DETAIL,
  SET_MONITOR_DETAIL,
  FETCH_LOWER_ROLES,
  SET_LOWER_ROLES,
  FETCH_MANAGERS,
  SET_MANAGERS,
  FETCH_GROUP_1,
  SET_GROUP_1,
  FETCH_GROUP_2,
  SET_GROUP_2,
  FETCH_MONITOR_LIST,
  SET_MONITOR_LIST,
  FETCH_AVAILABLE_INFO,
  SET_AVAILABLE_INFO,
  FETCH_AVAILABLE_GROUP_2,
  SET_AVAILABLE_GROUP_2,
  FETCH_MONITOR_MANAGEMENT_INFO,
  SET_MONITOR_MANAGEMENT_INFO,
} from '@/redux/reducers/monitor.slice'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { handleError } from '@/utils'
import { AxiosResponse } from 'axios'
import {
  apiGetMonitorDetail,
  apiGetGroup1,
  apiGetGroup2,
  apiGetLowerRole,
  apiGetManager,
  apiGetMonitorList,
  apiQueryGetGroup1,
  apiQueryGetGroup2,
  apiGetDirectManager,
} from '@/api/monitor'
import { PayloadAction } from '@reduxjs/toolkit'

function* getMonitorDetail(action: PayloadAction<string | undefined>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const detailRes: AxiosResponse = yield call(
      apiGetMonitorDetail,
      action.payload
    )
    yield put(SET_MONITOR_DETAIL(detailRes.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getLowerRoles() {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const roleRes: AxiosResponse = yield call(apiGetLowerRole)
    yield put(SET_LOWER_ROLES(roleRes.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getManagers(action: PayloadAction<any>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const managerRes: AxiosResponse = yield call(apiGetManager, action.payload)
    yield put(SET_MANAGERS(managerRes.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getGroup1(action: PayloadAction<string | number>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const group1Res: AxiosResponse = yield call(apiGetGroup1, action.payload)
    yield put(SET_GROUP_1(group1Res.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}
function* getGroup2(
  action: PayloadAction<{
    managerId: number | null
    boxes1: number[]
  }>
) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const group2Res: AxiosResponse = yield call(apiGetGroup2, action.payload)
    yield put(SET_GROUP_2(group2Res.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getMonitorList(action: PayloadAction<any>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const listRes: AxiosResponse = yield call(apiGetMonitorList, action.payload)
    yield put(SET_MONITOR_LIST(listRes.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getAvailableInfo() {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const group1Res: AxiosResponse = yield call(apiQueryGetGroup1)
    const directManagerRes: AxiosResponse = yield call(apiGetDirectManager)
    yield put(
      SET_AVAILABLE_INFO({
        group1: group1Res.data,
        directManager: directManagerRes.data,
      })
    )
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}
function* getAvailableGroup2(action: PayloadAction<any[]>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const group2Res: AxiosResponse = yield call(
      apiQueryGetGroup2,
      action.payload
    )
    yield put(SET_AVAILABLE_GROUP_2(group2Res.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getManagementInfo(action: PayloadAction<any>) {
  yield put(SET_LOADING(true))
  yield put(RESET_MESSAGE())
  try {
    const { role, id, managerId, boxes1 } = action.payload
    const managerRes: AxiosResponse = yield call(apiGetManager, { role, id })
    let group1Res: AxiosResponse | undefined
    let group2Res: AxiosResponse | undefined
    if (managerId) {
      group1Res = yield call(apiGetGroup1, managerId)
      group2Res = yield call(apiGetGroup2, {
        managerId,
        boxes1,
      })
    }

    yield put(
      SET_MONITOR_MANAGEMENT_INFO({
        managers: managerRes.data,
        groupBox1: group1Res ? group1Res.data : [],
        groupBox2: group2Res ? group2Res.data : [],
      })
    )
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

export default function* monitorSaga() {
  const filteredSagas: ForkEffect[] = [
    takeLatest(FETCH_MONITOR_DETAIL, getMonitorDetail),
    takeLatest(FETCH_LOWER_ROLES, getLowerRoles),
    takeLatest(FETCH_MANAGERS, getManagers),
    takeLatest(FETCH_GROUP_1, getGroup1),
    takeLatest(FETCH_GROUP_2, getGroup2),
    takeLatest(FETCH_MONITOR_LIST, getMonitorList),
    takeLatest(FETCH_AVAILABLE_INFO, getAvailableInfo),
    takeLatest(FETCH_AVAILABLE_GROUP_2, getAvailableGroup2),
    takeLatest(FETCH_MONITOR_MANAGEMENT_INFO, getManagementInfo),
  ]
  yield all(filteredSagas)
}
