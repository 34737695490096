// TODO: remove console.log
/* eslint-disable no-console */
import '@/assets/styles/layout/main.scss'
import React, { useEffect, useState } from 'react'
import {
  Box,
  CssBaseline,
  Stack,
  Button,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Outlet, useLocation } from 'react-router-dom'
import Header from '@/layout/MainLayout/Header'
import Sidebar from '@/layout/MainLayout/Sidebar'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import PageLoading from '@components/common/PageLoading'
import { useTranslation } from 'react-i18next'
import CallingTag from '@/components/common/CallingTag'
import useSocket from '@/hooks/useSocket'
import { HANDLE_CALL, LEAVE_CALL } from '@/redux/reducers/callVideo.slice'
import { ROUTER_PATH, URL_WEBSITE } from '@/constants'
import { generateKey, getTabId } from '@/utils'
import useNotification from '@/hooks/useNotification'
import { FETCH_NOTICE } from '@/redux/reducers/contact.slice'

const Ringtone = require('@/assets/audio/ringtone.mp3')

const theme = createTheme({
  palette: {
    mode: 'light',
  },
  components: {},
})

const RingtoneSound = new Audio(Ringtone)

function MainLayout() {
  useNotification()
  const { t } = useTranslation()

  const location = useLocation()

  const { toggleSidebar, isLoading } = useAppSelector(state => state.app)

  const { isHaveCallPrivate, isCalling, roomName, isFull } = useAppSelector(
    state => state.callVideo
  )
  console.log('hms public call - main layout - state:', {
    isHaveCallPrivate,
    isCalling,
    roomName,
    isFull,
  })

  const {
    currentUser: { isBox },
  } = useAppSelector(state => state.auth)

  const [openDialog, setOpenDialog] = useState(true)

  const [isRing, setIsRing] = useState(false)

  const [incomingCalls, setIncomingCalls] = useState<any[]>([])
  console.log('hms public call - main layout - incomingCalls', incomingCalls)

  const { currentUser, isConnected, socket } = useSocket()

  const dispatch = useAppDispatch()

  const authChannel = new BroadcastChannel('auth')

  const handleOpenDialog = () => {
    setOpenDialog(true)
    setIsRing(false)
  }
  const drawerWidth = toggleSidebar ? 250 : 0

  // Hide incoming calls
  const handleHidden = () => {
    setOpenDialog(false)
    setIsRing(true)
  }

  useEffect(() => {
    if (isConnected && !isFull && !isBox) {
      socket
        .on('connect', () => {})
        .on('customize_course', async (data: any) => {
          let params: any = data
          if (typeof data === 'string') {
            params = JSON.parse(data)
          }
          // 256724: check eventPageId condition
          const eventInformation = params.eventInformation[0]
          if (
            eventInformation.eventButtonId === 'hangup' &&
            eventInformation.eventPageId === 'customer-support-chat'
          ) {
            setIncomingCalls(prevState =>
              prevState.filter(state => state.mirrorCode !== params.mirrorCode)
            )
          }
        })
        .on('mirror_incoming_call', (data: any) => {
          setIncomingCalls(prevState => {
            if (prevState.some(item => item.mirrorCode === data.mirrorCode))
              return prevState
            return [...prevState, data]
          })
          RingtoneSound.currentTime = 0
          RingtoneSound.play()
          RingtoneSound.loop = true
        })
        .on('monitor_accepted', (data: any) => {
          setIncomingCalls(prevState =>
            prevState.filter(state => state.mirrorCode !== data.mirrorCode)
          )
        })
    }
    return () => {
      if (socket) {
        socket
          .off('customize_course')
          .off('mirror_incoming_call')
          .off('monitor_accepted')
      }
    }
  }, [isConnected, isFull])

  const acceptIncomingCall = (data: any) => {
    if (isHaveCallPrivate || isCalling) {
      dispatch(LEAVE_CALL())
      dispatch(HANDLE_CALL(false))
      socket.emit('monitor_hang_customer', {
        mirrorCode: roomName,
        staffId: currentUser.id.toString(),
      })
    }
    setIncomingCalls(prevState =>
      prevState.filter(state => state.mirrorCode !== data.mirrorCode)
    )
    data.eventInformation[0].eventButtonId = 'answer'
    data.userId = currentUser.id
    data.room_id = currentUser.public_room.room_id
    data.passcode = currentUser.public_room.passcode
    socket.emit('monitor_accepted', data)
    const isHaveCall: boolean = JSON.parse(
      localStorage.getItem('isGlobalCall') ?? 'false'
    )
    console.log('hms public call - main layout - isHaveCall: ', isHaveCall)
    if (!isHaveCall) {
      localStorage.setItem('mirrorCode', data.mirrorCode)
      console.log('hms public call - main layout - open new window')
      window.open(URL_WEBSITE + ROUTER_PATH.GLOBAL_CALL, '_blank')
    }
  }

  const rejectIncomingCall = (data: any) => {
    setIncomingCalls(prevState =>
      prevState.filter(state => state.mirrorCode !== data.mirrorCode)
    )
    socket.emit('monitor_hang_customer', {
      mirrorCode: data.mirrorCode,
      staffId: currentUser.id,
    })
  }

  useEffect(() => {
    authChannel.addEventListener('message', e => {
      if (e.data.action === 'login' && e.data.tabId !== getTabId()) {
        window.location.reload()
      }
    })
    return () => {
      RingtoneSound.pause()
    }
  }, [])

  useEffect(() => {
    if (location.pathname !== ROUTER_PATH.CONTACT) {
      dispatch(FETCH_NOTICE())
    }
  }, [location.pathname])

  if (incomingCalls.length === 0) {
    RingtoneSound.pause()
  }

  return (
    <ThemeProvider theme={theme}>
      <Box className="layout-container">
        <CssBaseline />
        {isLoading && <PageLoading />}
        <Header />
        <Box className="layout-wrapper">
          <Sidebar width={drawerWidth} />
          <Box
            component="main"
            className="main-layout"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              position: 'relative',
            }}
          >
            <Dialog
              open={incomingCalls.length > 0 && openDialog}
              onClose={handleHidden}
              scroll="paper"
              PaperProps={{
                sx: {
                  overflow: 'hidden',
                  maxWidth: 450,
                  maxHeight: 350,
                  position: 'absolute',
                  bottom: 20,
                  right: 10,
                  zIndex: 1,
                },
              }}
            >
              <DialogContent dividers>
                {incomingCalls.map((item, index) => (
                  <CallingTag
                    key={generateKey('call_tag', index)}
                    callDetail={item}
                    acceptIncomingCall={() => acceptIncomingCall(item)}
                    setIncomingCalls={setIncomingCalls}
                    incomingCalls={incomingCalls}
                    rejectIncomingCall={() => rejectIncomingCall(item)}
                    socket={socket}
                    currentUser={currentUser}
                  />
                ))}
              </DialogContent>
              <Stack
                justifyContent="flex-end"
                direction="row"
                alignItems="center"
              >
                <Button
                  variant="text"
                  endIcon={<KeyboardArrowDownIcon fontSize="large" />}
                  sx={{ mr: 3 }}
                  onClick={handleHidden}
                >
                  <Typography variant="body2">
                    {t('common.hide_phone')}
                  </Typography>
                </Button>
              </Stack>
            </Dialog>
            <Outlet />
            {incomingCalls.length > 0 && isRing && (
              <IconButton
                className="phone-container"
                sx={{
                  position: 'fixed',
                  bottom: 0,
                  right: 0,
                  zIndex: 1301,
                }}
                onClick={handleOpenDialog}
              >
                <PhoneInTalkIcon
                  className="phone-ring"
                  sx={{
                    position: 'fixed',
                    bottom: 20,
                    right: 20,
                    zIndex: 1,
                    fontSize: 60,
                  }}
                />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default React.memo(MainLayout)
