import '@/assets/styles/pages/forgot.scss'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from '@/redux/hooks'
import { Grid, Button, TextField, Box, Typography } from '@mui/material'
import { AuthForgotPassword } from '@/interfaces'
import { Trans, useTranslation } from 'react-i18next'
import { apiUserForgotPassword } from '@/api/auth'
import { SET_LOADING, SET_MESSAGE } from '@/redux/reducers/app.slice'
import { handleError } from '@/utils'
import { EMAIL_REGEX } from '@/constants'

// Forgot Password Page
function ForgotPassword() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [sent, setSent] = useState(false)
  const defaultValues = {
    email: '',
  }
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required(t('validation.email_required'))
      .matches(EMAIL_REGEX, t('validation.email_invalid')),
  })

  // Call API with input data
  const onSubmit = async (data: AuthForgotPassword) => {
    try {
      dispatch(SET_LOADING(true))
      await apiUserForgotPassword({ email: data.email })
      setSent(true)
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  })

  // Resend email
  const handleReSend = () => {
    setSent(!sent)
    reset()
  }

  return (
    <Box className="forgot-container">
      <Grid container>
        <Grid item xs={6} className="left-wrapper" />
        <Grid item xs={6} className="right-wrapper">
          {!sent ? (
            <Box className="form-forgot">
              <Typography variant="h4" className="title">
                {t('forgot_password.title')}
              </Typography>
              <Typography pt={2}>
                {' '}
                <Trans parent="span" i18nKey="forgot_password.description" />
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                sx={{ mt: 1 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          margin="normal"
                          fullWidth
                          id="email"
                          label={t('forgot_password.email_address_placeholder')}
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t('forgot_password.btn_submit')}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box className="form-forgot">
              <Typography variant="h4" className="title">
                {t('forgot_password.email_has_been_sent')}
              </Typography>
              <Typography pt={2}>
                <Trans
                  parent="span"
                  i18nKey="forgot_password.description_success"
                />
              </Typography>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleReSend}
              >
                {t('forgot_password.btn_resend')}
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(ForgotPassword)
