import React, { useEffect } from 'react'
import { getUserToken, removeUserToken, getIsBox } from '@/utils/helper'
import { useAppSelector, useAppDispatch } from '@/redux/hooks'
import { Navigate, useLocation } from 'react-router-dom'
import PageLoading from '@components/common/PageLoading'
import { AUTH_GET_INFO, BOX_GET_INFO } from '@/redux/reducers/users/auth.slice'
import { ROUTER_PATH } from '@/constants'

// Component private route
function PrivateRoute({ children }: { children: React.ReactElement }) {
  const dispatch = useAppDispatch()
  const hasRerender = (prevState: any, state: any) => !state.loading
  const { currentUser, loading } = useAppSelector(
    state => state.auth,
    hasRerender
  )

  // Clear user token
  const clearToken = () => {
    removeUserToken()
  }

  const token = getUserToken()

  const isBox = getIsBox()

  useEffect(() => {
    if (loading && token) {
      if (isBox !== '' && JSON.parse(isBox)) {
        dispatch(BOX_GET_INFO())
      } else {
        dispatch(AUTH_GET_INFO())
      }
    }
  }, [loading])

  if (!token) {
    const { pathname } = useLocation()
    const newPathName = `/sign-in?redirect=${encodeURIComponent(pathname)}`
    return <Navigate to={newPathName} />
  }

  if (loading) {
    return <PageLoading />
  }

  const userHasRequiredRole = currentUser && currentUser.id

  if (userHasRequiredRole) {
    return children
  }

  clearToken()
  return <Navigate to={ROUTER_PATH.AUTH.SIGN_IN} />
}

export default React.memo(PrivateRoute)
