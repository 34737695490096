import '@/assets/styles/layout/header.scss'
import React, { useEffect, useRef } from 'react'
import { ColumnsType } from 'antd/lib/table/interface'
import Table from 'antd/es/table'
import {
  Box,
  Stack,
  Paper,
  TextField,
  IconButton,
  Breadcrumbs,
  Link as ContentLink,
} from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'
import { SearchOutlined } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { DAYJS_FORMAT_NEW, HOUR_FORMAT, ROUTER_PATH } from '@/constants'
import { INotice, INoticeParams } from '@/interfaces'
import { convertTimeToJapan, showTotalPaginationTable } from '@/utils'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { FETCH_NOTICE_LIST } from '@/redux/reducers/contact.slice'

function Contact() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const searchRef = useRef<HTMLInputElement>(null)
  const [queryParams, setQueryParams] = React.useState<INoticeParams>({
    page: 1,
    sort: '',
    search: '',
  })
  const {
    noticeList: {
      data,
      meta: { current_page: current, per_page: perPage, total },
    },
  } = useAppSelector(state => state.contact)
  const dataSources = data.map(item => ({ ...item, key: item.id }))

  const searchValue = (event: any) => {
    if (event.key === 'Enter') {
      setQueryParams({
        ...queryParams,
        search: event.target.value.trim(),
        page: 1,
      })
    }
  }
  const columnsNotices: ColumnsType<INotice> = [
    {
      title: t('contact.created_at'),
      dataIndex: 'regist_date',
      key: 'regist_date',
      sorter: true,
      width: '15%',
      colSpan: 1,
      render: item =>
        convertTimeToJapan(item || '').format(
          `${DAYJS_FORMAT_NEW} ${HOUR_FORMAT}`
        ),
      align: 'center',
    },
    {
      title: t('contact.content'),
      dataIndex: 'content',
      key: 'content',
      width: '85%',
      colSpan: 2,
      render: item => (
        <Stack px={12}>
          <Trans default={item} components={{ a: <ContentLink /> }}>
            {item}
          </Trans>
        </Stack>
      ),
    },
  ]

  const filterData = (pagination: any, _: any, sorter: any) => {
    let queryParamsClone = { ...queryParams }
    queryParamsClone = { ...queryParamsClone, page: pagination.current }
    if (sorter.column) {
      if (sorter.order === 'ascend')
        queryParamsClone = {
          ...queryParamsClone,
          sort: `${sorter.column.key}:ASC`,
          page:
            queryParams.page === pagination.current ? 1 : pagination.current,
        }
      else
        queryParamsClone = {
          ...queryParamsClone,
          sort: `${sorter.column.key}:DESC`,
          page:
            queryParams.page === pagination.current ? 1 : pagination.current,
        }
    } else
      queryParamsClone = {
        ...queryParamsClone,
        sort: '',
        page: queryParams.page === pagination.current ? 1 : pagination.current,
      }
    setQueryParams(queryParamsClone)
  }

  useEffect(() => {
    dispatch(FETCH_NOTICE_LIST(queryParams))
  }, [queryParams])

  return (
    <Box className="mentor-detail-container">
      <Breadcrumbs sx={{ pb: 1 }}>
        <Link
          color="inherit"
          to={ROUTER_PATH.CONTACT}
          className="breadcrumb-text breadcrumb-text-list"
        >
          {t('contact.title')}
        </Link>
        <Box>{t('contact.sub_title')}</Box>
      </Breadcrumbs>
      <Box component={Paper}>
        <Stack p={1} direction="row" justifyContent="end" alignItems="center">
          <TextField
            className="search-input"
            sx={{ maxWidth: 400 }}
            size="small"
            onKeyDown={searchValue}
            variant="outlined"
            placeholder={t('contact.search_placeholder')}
            InputProps={{
              startAdornment: (
                <IconButton>
                  <SearchOutlined />
                </IconButton>
              ),
            }}
            ref={searchRef}
          />
        </Stack>
        <Box>
          <Table
            className="table-head"
            columns={columnsNotices}
            dataSource={dataSources}
            onChange={filterData}
            locale={{
              emptyText: t('monitoring_history.not_found'),
              triggerAsc: t('common.sort_asc'),
              triggerDesc: t('common.sort_desc'),
              cancelSort: t('common.cancel_sort'),
            }}
            pagination={{
              showSizeChanger: false,
              total,
              current,
              showTotal: () =>
                showTotalPaginationTable(
                  perPage || 0,
                  current || 0,
                  dataSources.length || 0,
                  total || 0
                ),
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default React.memo(Contact)
