import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import PageLoading from '@components/common/PageLoading'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { ROUTER_PATH } from '@/constants'
import { useAppSelector } from '@/redux/hooks'
import useNotification from '@/hooks/useNotification'
import { getTabId, setTabId } from '@/utils/helper'

const theme = createTheme()

export default function AuthLayout() {
  useNotification()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { isLoading } = useAppSelector(state => state.app)
  const { currentUser } = useAppSelector(state => state.auth)
  const authChannel = new BroadcastChannel('auth')

  useEffect(() => {
    if (currentUser?.id) {
      authChannel.postMessage({
        action: 'login',
        user: currentUser,
        tabId: getTabId(),
      })
      navigate(ROUTER_PATH.DASHBOARD)
    }
  }, [currentUser])

  useEffect(() => {  
    setTabId()
    authChannel.addEventListener('message', e => {
      if (e.data.action === 'login' && e.data.tabId !== getTabId()) {
        window.location.reload()
      }
    })
  }, [])

  useEffect(() => {
    if (pathname === '/') {
      navigate(ROUTER_PATH.AUTH.SIGN_IN)
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box component="main">
        <CssBaseline />
        <Outlet />
      </Box>
      <PageLoading show={isLoading} />
    </ThemeProvider>
  )
}
