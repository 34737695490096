import { Box, Stack, Typography } from '@mui/material'
import { Pagination } from 'antd'
import React, { useEffect, useState } from 'react'
import '@/assets/styles/pages/monitoring.scss'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  FETCH_LIST_BOXES,
  FETCH_LIST_PREFECTURES,
  FETCH_LIST_GROUP_1,
  FETCH_LIST_MONITORING_GROUP_2,
  SET_PARAMS_BOX_LIST,
} from '@/redux/reducers/monitoring.slice'
import FormFilter from '@/components/Monitoring/ListBoxes/FormFilter'
import ListBoxes from '@/components/Monitoring/ListBoxes/ListBoxes'
import { IMonitoringBoxesParams } from '@/interfaces'
import dayjs from 'dayjs'
import { ROUTER_PATH } from '@/constants'
import { generatePath, Navigate } from 'react-router-dom'

// Monitoring Page
function Monitoring() {
  // Task #234289
  const { currentUser } = useAppSelector(state => state.auth)
  if (currentUser.isBox) {
    return (
      <Navigate
        to={generatePath(ROUTER_PATH.MONITORING_DETAILS, {
          id: `${currentUser.id}`,
        })}
      />
    )
  }

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [isSearch, setIsSearch] = useState<boolean>(false)
  const {
    total,
    current_page: currentPage,
    per_page: perPage,
  } = useAppSelector(state => state.monitoring.metaListBoxes)
  const { paramsBoxList } = useAppSelector(state => state.monitoring)
  const { listBoxes } = useAppSelector(state => state.monitoring)
  const [queryParamsRequest, setQueryParamsRequest] =
    useState<IMonitoringBoxesParams>(paramsBoxList)

  const filterDataRequest = (pagination: any) => {
    let queryParamsClone = { ...queryParamsRequest }
    queryParamsClone = { ...queryParamsClone, page: pagination }
    setQueryParamsRequest(queryParamsClone)
  }

  // Set the params of url API
  const onSubmit = (data: any) => {
    setQueryParamsRequest({
      ...queryParamsRequest,
      ...data,
      page: 1,
      status: data.status,
      start_time: dayjs(data.start_time).isValid() ? data.start_time : '',
      end_time: dayjs(data.end_time).isValid() ? data.end_time : '',
    })
    setIsSearch(true)
  }

  const { handleSubmit, control, watch, setValue, reset } = useForm({
    defaultValues: paramsBoxList,
  })

  const listGroup1 = watch('gb1Ids')

  useEffect(() => {
    dispatch(SET_PARAMS_BOX_LIST(queryParamsRequest))
  }, [queryParamsRequest])

  useEffect(() => {
    dispatch(FETCH_LIST_GROUP_1())
    dispatch(FETCH_LIST_PREFECTURES([]))
  }, [])

  useEffect(() => {
    if (listGroup1 && listGroup1?.length > 0) {
      setValue('gb2Ids', [])
      dispatch(FETCH_LIST_MONITORING_GROUP_2(listGroup1))
    } else {
      dispatch(FETCH_LIST_MONITORING_GROUP_2([]))
      setValue('gb2Ids', [])
    }
  }, [listGroup1?.length])

  useEffect(() => {
    dispatch(FETCH_LIST_BOXES(paramsBoxList))
    reset(paramsBoxList)
    setIsSearch(true)
  }, [paramsBoxList])

  return (
    <Box p={1} sx={{ height: '100%', padding: 0 }}>
      <Typography mb={2} variant="h6" fontWeight="bold">
        {t('monitoring.title')}
      </Typography>
      <Box className="monitoring-container">
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ height: '100%' }}
          spacing={3}
        >
          <Stack direction="column" spacing={3} width="100%">
            <FormFilter
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              control={control}
              statusSelected={queryParamsRequest?.status}
              setSelectedStatus={setQueryParamsRequest}
            />
            <Typography
              variant="h6"
              textAlign="center"
              className="text-result"
              fontWeight="bold"
            >
              {isSearch ? `${total} ${t('monitoring.results')}` : ''}
            </Typography>
          </Stack>
          <Stack
            direction="column"
            spacing={0}
            sx={{ width: '100%' }}
            justifyContent="space-between"
          >
            <Box />
            <ListBoxes />
          </Stack>
          <Stack direction="column" spacing={3} width="100%">
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ marginTop: '12px' }}
              className="boxes-pagination"
            >
              {listBoxes.length > 0 && (
                <Pagination
                  onChange={filterDataRequest}
                  current={currentPage}
                  showSizeChanger={false}
                  total={total}
                  showTotal={() =>
                    t('monitoring.total_pagination', {
                      min_record: perPage * (currentPage - 1) + 1,
                      max_record:
                        perPage * (currentPage - 1) + listBoxes.length,
                      total,
                    })
                  }
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export default React.memo(Monitoring)
