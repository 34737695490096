import { apiGetMirrorList } from '@/api/mirror'
import { SET_LOADING, SET_MESSAGE } from '@/redux/reducers/app.slice'
import {
  FETCH_LIST_MIRROR,
  SET_MIRROR_LIST,
} from '@/redux/reducers/mirror.slice'
import { handleError } from '@/utils/helper'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { all, ForkEffect, put, takeLatest } from 'redux-saga/effects'

function* getMirrorList(action: PayloadAction<any>) {
  yield console.log('debug')

  try {
    yield put(SET_LOADING(true))
    const response: AxiosResponse = yield apiGetMirrorList(action.payload)
    yield put(SET_MIRROR_LIST(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}
export default function* mirrorManagementSage() {
  const filteredSagas: ForkEffect[] = [
    takeLatest(FETCH_LIST_MIRROR, getMirrorList),
  ]
  yield all(filteredSagas)
}
