import { AppState, AppMessage } from '@/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LANGUAGE_STORE_KEY, DEFAULT_LANGUAGE } from '@/constants'

const initialState: AppState = {
  userLoading: true,
  isLoading: false,
  message: {
    // type === '' not show notification
    type: '',
    content: '',
  },
  toggleSidebar: true,
  toggleDialog: false,
  languageMaster: localStorage.getItem(LANGUAGE_STORE_KEY)
    ? localStorage.getItem(LANGUAGE_STORE_KEY)
    : DEFAULT_LANGUAGE,
  apiCallingCount: 0,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    SET_MESSAGE: (state, action: PayloadAction<AppMessage>) => {
      const { payload } = action
      state.message = payload
    },
    RESET_MESSAGE: state => {
      state.message = initialState.message
    },
    SET_LOADING: (state, action: PayloadAction<boolean>) => {
      let { apiCallingCount } = state
      const weight = action.payload ? 1 : -1
      apiCallingCount += weight
      state.apiCallingCount = apiCallingCount
      if (action.payload) {
        state.isLoading = action.payload
        return
      }
      if (apiCallingCount <= 0) {
        state.isLoading = action.payload
        state.apiCallingCount = 0
      }
    },
    TOGGLE_SIDEBAR: state => {
      state.toggleSidebar = !state.toggleSidebar
    },
    TOGGLE_DIALOG: state => {
      state.toggleDialog = !state.toggleDialog
    },
    SET_LANGUAGE: (state, action: PayloadAction<string>) => {
      state.languageMaster = action.payload
    },
  },
})

// Actions
export const {
  SET_MESSAGE,
  RESET_MESSAGE,
  SET_LOADING,
  TOGGLE_SIDEBAR,
  TOGGLE_DIALOG,
  SET_LANGUAGE,
} = appSlice.actions

// Selector
export const message = (state: AppState) => state.message

// Reducer
export default appSlice.reducer
