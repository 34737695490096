// TODO: remove console.log
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react'
import {
  Paper,
  Box,
  Stack,
  Grid,
  Slider,
  Button,
  Typography,
} from '@mui/material'
import { generatePath, Navigate, useNavigate } from 'react-router-dom'
import {
  MicOff,
  Mic,
  VolumeDown,
  VolumeOff,
  VolumeUp,
  ArrowDropUp,
} from '@mui/icons-material'
import '@/assets/styles/pages/global-call.scss'
import { ROOM_TYPE, ROUTER_PATH } from '@/constants'
import { generateKey, getEnv } from '@/utils'
import useSocket from '@/hooks/useSocket'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  LEAVE_CALL_GLOBAL,
  PICKUP_A_CALL_GLOBAL,
} from '@/redux/reducers/callVideo.slice'
import ParticipantStream from '@/components/GlobalCall/ParticipantStream'
import { useTranslation } from 'react-i18next'
import ParticipantStreamMain from '@/components/GlobalCall/ParticipantStreamMain'
import { SET_MESSAGE } from '@/redux/reducers/app.slice'
import useHMSRoom from '@/hooks/useHMSRoom'
import {
  selectIsLocalAudioEnabled,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'
import { CusTomHMSPeer } from '@/interfaces/hms'

const GLOBAL_MAX_CALL = Number(getEnv('GLOBAL_MAX_CALL'))

const showSpeaker = (value: number, toggleVolume: () => void) => {
  if (value === 0) {
    return <VolumeOff onClick={() => toggleVolume()} />
  }
  if (value <= 50) {
    return <VolumeDown onClick={() => toggleVolume()} />
  }
  return <VolumeUp onClick={() => toggleVolume()} />
}

// Global Call Page
function GlobalCall() {
  // hms
  const isAudioEnabled = useHMSStore(selectIsLocalAudioEnabled)
  const hmsActions = useHMSActions()

  // Task #234289
  const { currentUser } = useAppSelector(state => state.auth)
  if (currentUser.isBox) {
    return (
      <Navigate
        to={generatePath(ROUTER_PATH.MONITORING_DETAILS, {
          id: `${currentUser.id}`,
        })}
      />
    )
  }
  const { t } = useTranslation()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const [valueVolume, setValueVolume] = useState<number>(100)

  const [isZoom, setIsZoom] = useState<boolean>(false)

  const { isHaveCall } = useAppSelector(state => state.callVideo)
  console.log('hms public call - isHaveCall: ', isHaveCall)

  const { peers, localPeer, localVideoTrackId } = useHMSRoom(ROOM_TYPE.PUBLIC)

  const [listParticipant, setListParticipant] = useState<CusTomHMSPeer[]>([])

  const videoContainer = useRef(null)

  const { isDisconnected } = useSocket()

  // Mute/unmute volume
  const toggleVolume = () => {
    if (valueVolume === 0) {
      setValueVolume(50)
      return
    }
    setValueVolume(0)
  }

  // Mute/unmute microphone
  const toggleMic = () => {
    hmsActions.setLocalAudioEnabled(!isAudioEnabled)
  }

  // Handle change the value of volume
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValueVolume(newValue as number)
  }

  // Handle leave room, navigate to dashboard page
  const leaveRoomGlobalCall = () => {
    // TODO socket.emit('monitor_hang_customer', {
    //   mirrorCode: 'monitor_global',
    //   staffId: currentUser.id.toString(),
    // })
    // Task id #231767
    const video: any = videoContainer.current
    if (localVideoTrackId) {
      hmsActions.detachVideo(localVideoTrackId, video)
    }
    dispatch(LEAVE_CALL_GLOBAL())
    navigate(ROUTER_PATH.DASHBOARD)
    console.log('hms public call - leave room')
    window.close()
  }

  useEffect(() => {
    if (localPeer && videoContainer.current) {
      if (localVideoTrackId) {
        hmsActions.attachVideo(localVideoTrackId, videoContainer.current)
      }
    }
    //  else {
    //   alert(t('common.message_no_camera'))
    //   leaveRoomGlobalCall()
    // }
  }, [localPeer])

  useEffect(() => {
    dispatch(PICKUP_A_CALL_GLOBAL())
    console.log('hms public call - pickup a call global')
    localStorage.removeItem('isGlobalCall')
    localStorage.setItem('isGlobalCall', 'true')
    window.onbeforeunload = () => localStorage.removeItem('isGlobalCall')
    return () => {
      dispatch(LEAVE_CALL_GLOBAL())
      window.onbeforeunload = () => undefined
      localStorage.removeItem('isGlobalCall')
      localStorage.removeItem('mirrorCode')
    }
  }, [])

  useEffect(() => {
    if (!peers.length || peers.length > GLOBAL_MAX_CALL) return
    const remotePeers = peers
      .filter(peer => !peer.isLocal)
      .map(remotePeer => ({ ...remotePeer, isZoom: false }))
    if (
      remotePeers &&
      remotePeers.length > 0 &&
      remotePeers.every(peer => !peer.isZoom)
    ) {
      remotePeers[0].isZoom = true
    }
    setListParticipant(remotePeers)
  }, [peers])

  // Handle zoom in/ zoom out video
  const handleZoomVideo = (indexZoom: number) => {
    const listParticipantClone = [...listParticipant]
    listParticipantClone.forEach((item, index) => {
      item.isZoom = indexZoom === index
    })
    setListParticipant(listParticipantClone)
    setIsZoom(!isZoom)
  }

  useEffect(() => {
    if (isDisconnected) {
      console.log('hms public call - socket disconnect - call leaveRoomGlobalCall')
      leaveRoomGlobalCall()
      dispatch(
        SET_MESSAGE({
          type: 'error',
          content: t('common.message_disconnect_call'),
        })
      )
    }
  }, [isDisconnected])

  return (
    <Box className="global-call-container">
      <Typography
        mb={2}
        variant="h6"
        fontWeight="bold"
        position="relative"
        zIndex={isHaveCall ? 1300 : 1}
      >
        {t('common.global_call.title')}
      </Typography>
      <Paper
        elevation={0}
        sx={{ mt: 1, position: 'relative', zIndex: isHaveCall ? 1300 : 1 }}
      >
        <Box className="global-call-stream">
          <Grid container spacing={2}>
            <Grid item xs={10}>
              {listParticipant.length > 0 ? (
                listParticipant.map((item, index) => (
                  <ParticipantStreamMain
                    key={generateKey('participant_zoom', index)}
                    participant={item}
                    valueVolume={valueVolume}
                    isZoom={isZoom}
                  />
                ))
              ) : (
                <Box className="global-call-stream-main" />
              )}
            </Grid>
            <Grid item xs={2}>
              <Stack
                direction="column"
                sx={{ maxHeight: 683 }}
                className="stream-participants-wrapper"
              >
                {listParticipant.length > 0 &&
                  listParticipant.map((item, index) => (
                    <ParticipantStream
                      key={generateKey('participant', index)}
                      participant={item}
                      valueVolume={valueVolume}
                      handleZoomVideo={handleZoomVideo}
                      index={index}
                    />
                  ))}
                <Box position="relative">
                  <Box className="global-call-stream-participants">
                    <video ref={videoContainer} autoPlay muted playsInline />
                  </Box>
                  <Stack
                    className="global-call-stream-participants-info"
                    direction="row"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Box visibility="hidden" />
                    <Box>
                      {!isAudioEnabled ? (
                        <MicOff fontSize="small" />
                      ) : (
                        <Mic fontSize="small" />
                      )}
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Stack
            className="global-call-stream-control"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={8}
          >
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              className="controller-volume"
            >
              {showSpeaker(valueVolume, toggleVolume)}
              <Slider
                aria-label="Volume"
                value={valueVolume}
                onChange={handleChange}
                sx={{ mr: '16px !important' }}
              />
            </Stack>
            <Stack
              spacing={0}
              direction="row"
              alignItems="center"
              className="controller-mic"
            >
              {isAudioEnabled ? (
                <Mic onClick={toggleMic} />
              ) : (
                <MicOff onClick={toggleMic} />
              )}
              <ArrowDropUp />
            </Stack>
            <Stack
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ m: '23px !important' }}
            >
              <Button
                variant="contained"
                color="error"
                size="small"
                sx={{ height: 50 }}
                onClick={leaveRoomGlobalCall}
              >
                {t('common.global_call.button')}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Paper>
      {isHaveCall && <Box className="page-loading" />}
    </Box>
  )
}
export default React.memo(GlobalCall)
