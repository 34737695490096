import '@/assets/styles/pages/Monitor/monitor.scss'

import React from 'react'
import { Stack, Dialog, DialogTitle, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ConfirmDialogProps } from '@/interfaces'

// Component confirm dialog
function ConfirmScanDialog({
  dialogTitle,
  dialogContent,
  openDialog,
  setOpenDialog,
  onConfirmDialog,
  okText,
  cancelText,
  lowerGrade,
  lowerGradeText,
  icon,
}: ConfirmDialogProps) {
  const { t } = useTranslation()

  return (
    <Dialog
      className="dialog-container"
      onClose={() => setOpenDialog(!openDialog)}
      open={openDialog}
      maxWidth="sm"
      fullWidth
      sx={{
        padding: 3,
      }}
    >
      <Stack direction="column" spacing={5} alignItems="center" p={3}>
        {dialogTitle && dialogTitle.length > 0 && (
          <Stack direction="row" alignItems="center" spacing={2}>
            {icon}
            <DialogTitle sx={{ padding: 0, fontWeight: 'bold', fontSize: 45 }}>
              {dialogTitle || t('header.button.logout')}
            </DialogTitle>
          </Stack>
        )}
        <Stack direction="column">
          <Typography textAlign="center" fontSize={20}>
            {lowerGradeText}
            {lowerGrade}
          </Typography>
          <Typography textAlign="center" fontSize={20}>
            {dialogContent}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={4} width="60%">
          <Button
            variant="contained"
            color="error"
            sx={{
              fontWeight: 600,
              fontSize: 16,
              height: 36,
              width: 100,
              marginLeft: 5
            }}
            onClick={onConfirmDialog}
          >
            {okText || t('header.button.ok')}
          </Button>
          <Button
            variant="outlined"
            sx={{
              fontWeight: 600,
              fontSize: 16,
              height: 36,
              whiteSpace: 'nowrap',
              minWidth: 120,
            }}
            onClick={() => setOpenDialog(!openDialog)}
          >
            {cancelText || t('header.button.cancel')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default React.memo(ConfirmScanDialog)
