import {
  Grid,
  Stack,
  Box,
  InputBase,
  IconButton,
  Typography,
  Paper,
} from '@mui/material'
import { Send } from '@mui/icons-material'
import ImageComment from '@/assets/images/image_comment.png'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { convertTimeToJapan, handleError } from '@/utils'
import { apiAddCommentBoxDetail } from '@/api/monitoring'
import { useParams } from 'react-router-dom'
import { FETCH_COMMENT_BOX_DETAILS } from '@/redux/reducers/monitoring.slice'

export interface ICommentBoxProps {
  commentContent: string
  setCommentContent: (data: string) => void
}

// Component Comment box
function CommentBox({ commentContent, setCommentContent }: ICommentBoxProps) {
  const { t } = useTranslation()

  const { listCommentBoxDetail, boxDetails } = useAppSelector(
    state => state.monitoring
  )

  const dispatch = useAppDispatch()

  const params = useParams()

  // Call API post a comment depend on monitor or box
  const addComment = async () => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      const formData = new FormData()
      formData.append('content', commentContent)
      formData.append(
        'reservation_id',
        boxDetails.reservation_id ? boxDetails.reservation_id?.toString() : ''
      )
      await apiAddCommentBoxDetail(Number(params.id), formData)
      dispatch(FETCH_COMMENT_BOX_DETAILS(Number(params.id)))
      setCommentContent('')
    } catch (err) {
      dispatch(SET_MESSAGE(handleError(err)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  return (
    <Box
      className="talk-bubble tri-right left-top"
      component={Paper}
      elevation={5}
    >
      <Grid container height="100%" spacing={3}>
        <Grid item xs={9}>
          <Box className="comment-container" sx={{ height: '100%' }}>
            <Stack
              spacing={3}
              sx={{ p: 3, pb: 0 }}
              height="100%"
              justifyContent="space-between"
            >
              {listCommentBoxDetail.length > 0 && (
                <Box className="comment-content" px={3} pb={3}>
                  {listCommentBoxDetail.map(item => (
                    <Stack mt={1} key={item.id}>
                      <Stack direction="row">
                        <Typography fontWeight="bold">
                          {convertTimeToJapan(item.created_at).format(
                            'YYYY/MM/DD HH:mm'
                          )}
                        </Typography>
                        <Typography color="primary" ml={2}>
                          (
                          {item.monitor_id === 0
                            ? boxDetails.box_name
                            : item.monitor_name}
                          )
                        </Typography>
                      </Stack>
                      <Typography className="item-content">
                        {item.content}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              )}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems={
                  listCommentBoxDetail.length === 0 ? 'flex-start' : 'center'
                }
                height={listCommentBoxDetail.length === 0 ? '100%' : 'unset'}
                className="comment-send"
              >
                <InputBase
                  multiline
                  value={commentContent}
                  sx={{ ml: 1 }}
                  placeholder={t('history.comment')}
                  onChange={e => setCommentContent(e.target.value)}
                  fullWidth
                  inputProps={{ maxLength: 500 }}
                />
                <IconButton
                  type="submit"
                  className="icon-send"
                  onClick={addComment}
                  disabled={commentContent.trim() === ''}
                  sx={{
                    opacity: commentContent.trim() === '' ? 0.5 : 1,
                  }}
                >
                  <Send />
                </IconButton>
              </Stack>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={3} display="flex" alignItems="flex-end" pr={3}>
          <Box
            component="img"
            src={ImageComment}
            alt="image comment"
            height="70%"
            width="100%"
            sx={{ objectFit: 'contain' }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(CommentBox)
