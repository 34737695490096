import '@/assets/styles/pages/monitoring-history.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Box, Stack, Button, TextField } from '@mui/material'
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import Table from 'antd/es/table'
import Typography from 'antd/es/typography'
import { ColumnsType } from 'antd/lib/table/interface'
import {
  IMonitoringHistoryComment,
  IMonitoringHistoryParams,
} from '@/interfaces'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { FETCH_HISTORY_COMMENT } from '@/redux/reducers/monitoring.slice'
import { convertTimeToJapan, showTotalPaginationTable } from '@/utils'
import { useParams } from 'react-router-dom'
import {
  DATE_PICKER_FORMAT,
  DATE_PICKER_INPUT_YMD,
  DEFAULT_LANGUAGE,
} from '@/constants'
import { enUS, ja } from 'date-fns/locale'

// Component Tab Comment
function TabComment() {
  const { Paragraph } = Typography

  const dispatch = useAppDispatch()

  const params = useParams()

  const { t } = useTranslation()

  const { listHistoryComment, metaHistoryComment, boxDetails } = useAppSelector(
    state => state.monitoring
  )

  const dataSources = useMemo(
    () =>
      listHistoryComment.map(comment => ({
        ...comment,
        monitor_name: comment.monitor_id
          ? comment.monitor_name
          : boxDetails.box_name,
      })),
    [listHistoryComment]
  )

  const { languageMaster } = useAppSelector(state => state.app)

  const [queryParams, setQueryParams] = useState<IMonitoringHistoryParams>({
    id: Number(params.id),
    date_from: '',
    date_to: '',
    page: 1,
    search_name: '',
    search_content: '',
  })

  // Update params of url API
  const onSubmit = (data: any) => {
    setQueryParams({
      ...queryParams,
      ...data,
      search_name: data.search_name.trim(),
      search_content: data.search_content.trim(),
      date_from: dayjs(data.date_from).isValid()
        ? dayjs(data.date_from).format('YYYY-MM-DD')
        : '',
      date_to: dayjs(data.date_to).isValid()
        ? dayjs(data.date_to).format('YYYY-MM-DD')
        : '',
      page: 1,
    })
  }

  const [startTime, setStartTime] = useState<Date | null>(null)

  const [endTime, setEndTime] = useState<Date | null>(null)

  useEffect(() => {
    dispatch(FETCH_HISTORY_COMMENT(queryParams))
  }, [queryParams])

  const defaultValues = {
    date_from: '',
    date_to: '',
    search_name: '',
    search_content: '',
  }

  const { handleSubmit, control } = useForm({
    defaultValues,
  })

  const columns: ColumnsType<IMonitoringHistoryComment> = [
    {
      title: t('monitoring_history.date_time'),
      key: 'created_at',
      align: 'center',
      render: (_, record) =>
        convertTimeToJapan(record.created_at || '').format('HH:mm YYYY/MM/DD'),
      width: '25%',
      ellipsis: true,
    },
    {
      title: t('monitoring_history.manager'),
      dataIndex: 'monitor_name',
      key: 'monitor_name',
      width: '25%',
      render: (_, record) => (
        <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: '>>' }}>
          {record.monitor_name}
        </Paragraph>
      ),
    },
    {
      title: t('monitoring_history.comment'),
      key: 'content',
      width: '50%',
      render: (_, record) => (
        <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: '>>' }}>
          {record.content}
        </Paragraph>
      ),
    },
  ]

  // Update pagination of table
  const handlePagination = (pagination: any) => {
    setQueryParams({ ...queryParams, page: pagination.current })
  }

  return (
    <Stack direction="column" pl={3} pr={3}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={3} alignItems="center" p={1}>
          <Stack direction="row" spacing={3} width="100%">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
            >
              <Controller
                name="date_from"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={e => {
                      field.onChange(e)
                      setStartTime(e)
                    }}
                    label={t('monitoring_history.from_date')}
                    allowSameDateSelection
                    maxDate={endTime || new Date()}
                    inputFormat={DATE_PICKER_FORMAT}
                    renderInput={paramsInput => (
                      <TextField
                        {...paramsInput}
                        error={false}
                        onKeyDown={(e: any) => e.preventDefault()}
                      />
                    )}
                    mask={DATE_PICKER_INPUT_YMD}
                  />
                )}
              />
            </LocalizationProvider>
            <Box display="flex" alignItems="center">
              ~
            </Box>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
            >
              <Controller
                name="date_to"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={e => {
                      field.onChange(e)
                      setEndTime(e)
                    }}
                    label={t('monitoring_history.to_date')}
                    allowSameDateSelection
                    inputFormat={DATE_PICKER_FORMAT}
                    minDate={startTime}
                    maxDate={new Date()}
                    renderInput={paramsInput => (
                      <TextField
                        {...paramsInput}
                        error={false}
                        onKeyDown={(e: any) => e.preventDefault()}
                      />
                    )}
                    mask={DATE_PICKER_INPUT_YMD}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <Box width="50%">
            <Controller
              name="search_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('monitoring_history.manager')}
                  value={field.value}
                  inputProps={{ maxLength: 25 }}
                />
              )}
            />
          </Box>
          <Box width="50%">
            <Controller
              name="search_content"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('monitoring_history.comment')}
                  value={field.value}
                  inputProps={{ maxLength: 500 }}
                />
              )}
            />
          </Box>
          <Box width="30%">
            <Button
              type="submit"
              variant="contained"
              sx={{ borderRadius: 20 }}
              fullWidth
            >
              {t('monitoring_history.search')}
            </Button>
          </Box>
        </Stack>
      </Box>
      <Table
        columns={columns}
        dataSource={dataSources}
        scroll={{ y: 360 }}
        locale={{
          emptyText: t('monitoring_history.not_found'),
          filterReset: t('common.reset'),
          filterConfirm: t('common.apply'),
          triggerAsc: t('common.sort_asc'),
          triggerDesc: t('common.sort_desc'),
          cancelSort: t('common.cancel_sort'),
        }}
        onChange={handlePagination}
        pagination={{
          current: queryParams.page,
          total: metaHistoryComment.total,
          showSizeChanger: false,
          showTotal: () =>
            showTotalPaginationTable(
              metaHistoryComment.per_page,
              metaHistoryComment.current_page,
              listHistoryComment.length,
              metaHistoryComment.total
            ),
        }}
      />
    </Stack>
  )
}

export default React.memo(TabComment)
