import React, { useEffect, useState, useRef, createRef } from 'react'
import '@/assets/styles/pages/CameraManagement.scss'
import { useAppDispatch } from '@/redux/hooks'
import { Box, Grid, IconButton, Stack, TextField, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ReactHlsPlayer from 'react-hls-player'
import { SET_LOADING, SET_MESSAGE } from '@/redux/reducers/app.slice'
import { handleError } from '@/utils'
import { apiGetCameraList } from '@/api/monitoring'
import { Pagination } from 'antd'
import { SearchOutlined } from '@mui/icons-material'

// Camera List Page
function CameraManagement() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const playerRef = useRef([])
  const searchRef = useRef<HTMLInputElement>(null)
  const pageSize = 6

  const [cameraList, setCameraList] = useState<Array<any>>([])
  const [pagination, setPagination] = useState<any>({})

  const fetchData = async (pageNumber: number, nickname: string) => {
    try {
      dispatch(SET_LOADING(true))
      const getCameraList = async () => {
        const { data } = await apiGetCameraList({pageNumber, nickname})
        const rawData = data?.list.map((item: any) => {
          let rawCameraList = item.cameraList
          rawCameraList = rawCameraList.length < 2 ? [...rawCameraList, { fakeCamera: true }] : rawCameraList.slice(0, 2)
          return {
            shopName: item.shopName,
            cameraList: rawCameraList,
          }
        })
        setCameraList(rawData)
        setPagination({
          page: data?.page ?? 1,
          total: data?.total ?? 0
        })
      }
      await getCameraList()
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  useEffect(() => {
    fetchData(1, '')
  }, [])

  useEffect(() => {
    cameraList.map(box => {
      playerRef.current = box.cameraList.map(
        (_: any) => playerRef.current[_.id] ?? createRef()
      )
      return true
    })
  }, [cameraList])

  const filterDataRequest = (page: any) => {
    fetchData(page, '')
  }

  const onSearchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      fetchData(1, event.target.value.trim())
    }
  }

  let searchValue = ''
  const onSearchChange = (event: any) => {
    searchValue = event.target.value.trim()
  }

  return (
    <Box className="camera-list-container" p={1}>
      <Typography variant="h6" mb={2}>
        {t('camera_management.title')}
      </Typography>
      <Stack direction="row" sx={{ marginBottom: '20px' }}>
        <TextField
          sx={{
            maxWidth: 400,
            marginRight: '10px',
            '.MuiOutlinedInput-root': {
              padding: '3px 0px 0px 7px'
            }
          }}
          size="small"
          onKeyDown={onSearchKeyDown}
          onChange={onSearchChange}
          variant="outlined"
          placeholder={t('camera_management.search_placeholder')}
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            ),
          }}
          ref={searchRef}
        />
        <Button
          variant="contained"
          type="button"
          size="small"
          onClick={() => {
            fetchData(1, searchValue)
          }}
        >
          {t('camera_management.search_button')}
        </Button>
      </Stack>
      <Grid container spacing={2}>
        {cameraList.map((box, index) => (
          <Grid
            item
            xs={6}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <Typography>{box.shopName}</Typography>
            <Grid container spacing={2}>
              {box.cameraList.map((camera: any, j: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item xs={6} key={`${box.shopName}${j}`}>
                  {camera.stream_link ? (
                    <Box className="camera-box">
                      <ReactHlsPlayer
                        src={camera?.stream_link}
                        playerRef={playerRef.current[camera.id]}
                        width="371px"
                        height="209px"
                        autoPlay
                        controls
                        muted
                      />
                    </Box>
                  ) : (
                    <Box className="background-landing camera-box" />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Stack direction="column" spacing={3} width="100%">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ marginTop: '12px' }}
          className="boxes-pagination"
        >
          {cameraList.length > 0 && (
            <Pagination
              onChange={filterDataRequest}
              current={pagination?.page}
              showSizeChanger={false}
              total={pagination?.total}
              pageSize={pageSize}
              showTotal={() =>
                t('monitoring.total_pagination', {
                  min_record: pageSize * ((pagination?.page ?? 1) - 1) + 1,
                  max_record:
                    pageSize * ((pagination?.page ?? 1) - 1) +
                    cameraList.length,
                  total: pagination?.total,
                })
              }
            />
          )}
        </Stack>
      </Stack>
    </Box>
  )
}

export default React.memo(CameraManagement)
