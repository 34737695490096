import { IListMirrorMeta, MirrorManagementState } from '@/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: MirrorManagementState = {
  listMirror: [],
  metaListMirror: {
    pageSize: 10,
    pageNumber: 1,
  },
  metaMirrorManagementState: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: '',
    last_page_url: '',
    next_page_url: '',
    previous_page_url: '',
  },
}

export const mirrorSlice = createSlice({
  name: 'mirror',
  initialState,
  reducers: {
    FETCH_LIST_MIRROR: (_, action: PayloadAction<IListMirrorMeta>) => {},
    SET_MIRROR_LIST: (state, action: PayloadAction<any>) => {
      const { data, meta } = action.payload
      state.listMirror = data.map((item: any) => ({
        ...item,
        key: item.id,
        top_status: 'inactive',
        bottom_status: 'inactive',
      }))
      state.metaMirrorManagementState = meta
    },
    SET_MIRROR_LIST_STATUS: (state, action: PayloadAction<any>) => {
      state.listMirror = action.payload
    },
  },
})
// Actions
export const { SET_MIRROR_LIST, FETCH_LIST_MIRROR, SET_MIRROR_LIST_STATUS } = mirrorSlice.actions
//Reducer
const mirrorReducer = mirrorSlice.reducer
export default mirrorReducer
