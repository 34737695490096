import client from '@/api/axiosClient'
import { IListDeviceSetting, IMonitoringHistoryParams } from '@/interfaces'

export const apiGetListHistoryVideo = (params: IMonitoringHistoryParams) =>
  client.get('/monitors/video-history', {
    params,
  })

export const apiGetListHistoryPlayback = (params: IMonitoringHistoryParams) =>
  client.get('/monitors/video-detail', {
    params,
  })

export const apiGetListHistoryComment = (params: IMonitoringHistoryParams) =>
  client.get('/monitors/comment-history', {
    params,
  })

export const apiGetListVideo = (params: any) =>
  client.get('/monitors/boxes', {
    params,
  })

export const apiGetListGroup1 = () => client.get('/monitors/group-boxes1')
export const apiGetListGroup2 = (gb1s: number[]) =>
  client.get('/monitors/group-boxes2', {
    params: {
      ids: gb1s,
    },
  })

export const apiGetListPrefecture = (gb2s: number[]) =>
  client.get('/monitors/prefectures', {
    params: {
      ids: gb2s,
    },
  })

export const apiGetListCommentBoxDetail = (params: number) =>
  client.get(`/monitors/boxes/${params}/comment-list`)

export const apiAddCommentBoxDetail = (params: number, content: FormData) =>
  client.post(`/monitors/boxes/${params}/add-comment`, content)

export const apiGetListCameraBoxDetail = (params: number) =>
  client.get(`/monitors/boxes/${params}/camera-list`)

export const apiGetListDeviceBoxDetail = (params: number) =>
  client.get(`/monitors/boxes/${params}/shop-equipment-list`)

export const apiSaveSettingDeviceBoxDetail = (
  params: number,
  setting: IListDeviceSetting
) => client.post(`/monitors/boxes/${params}/save-equipment-setting`, setting)

export const apiGetBoxDetail = (params: number) =>
  client.get(`/monitors/boxes/${params}`)

export const apiJoinBox = (params: number) =>
  client.post(`/monitors/boxes/${params}/join-box`)

export const apiLeftBox = (params: number) =>
  client.post(`/monitors/boxes/${params}/left-box`)

export const apiGetBoxIdByMirrorCode = (params: number) =>
  client.get(`/monitors/boxes/get-box-by-mirror-code/${params}`)

export const apiGetMirrorCodeByBoxId = (params: number) =>
  client.get(`/monitors/boxes/${params}/get-mirror-info`)

export const apiGetAirConState = (params: number) =>
  client.get(`/monitors/boxes/${params}/get-aircon-state`)

export const apiGetHeatersState = (equipmentId: number) =>
  client.get(`monitors/shop-equipment/${equipmentId}`)

export const apiChangeAirConSetting = (
  params: number,
  setting: {
    power?: number
    mode?: number
    temp?: number
  }
) => client.post(`/monitors/boxes/${params}/change-aircon-setting`, setting)

export const apiChangeHeatersSetting = (
  equipmentId: number,
  setting: {
    power?: boolean
    temp?: number
  }
) => client.post(`monitors/shop-equipment/${equipmentId}`, setting)

export const apiRecordVideoMonitor = (
  boxId: number,
  data: {
    reservation_id: number
    room_id: string
  }
) => client.post(`/monitors/boxes/${boxId}/video-record`, data)

// 246031: api get room ip, mirror code
export const apiGetRoomDetail = (boxId: number, roomNum: number) =>
  client.get(`/monitors/boxes/${boxId}/room-detail/${roomNum}`)

// 243413: api update shop equipment master link
export const apiUpdateShopEquipmentMasterLink = (
  equipmentId: number,
  data: {
    ip_address: string
  }
) => client.post(`monitors/shop-equipment/update/master-link/${equipmentId}`, data)

// 278160: API get list stream link
export const apiGetCameraList = (
  data: any
) => client.post(`monitors/shop-equipment/camera/list`, data)