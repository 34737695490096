import client from '@/api/axiosClient'
import {
  AuthRegister,
  AuthSignIn,
  AuthForgotPassword,
  AuthResetPassword,
  IAuthBoxLogin,
  IBoxChangePassword,
} from '@/interfaces'

// Sign-in
export const apiUserSignIn = (data: AuthSignIn) =>
  client.post('monitors/login', { email: data.email, password: data.password })

// Box sign-in
export const apiBoxSignIn = (data: IAuthBoxLogin) =>
  client.post('admin/login', data)

// Register
export const apiUserRegister = (data: AuthRegister) =>
  client.post('monitors/register', data)

// Sign-out
export const apiUserSignOut = () => client.post('monitors/logout')

// Box sign-out
export const apiBoxSignOut = () => client.post('admin/logout')

// Forgot-password
export const apiUserForgotPassword = (data: AuthForgotPassword) =>
  client.post('monitors/forgot-password', data)

// Reset-password-monitor
export const apiUserResetPassword = (data: AuthResetPassword) =>
  client.post('monitors/reset-password', data)

// Reset-password-box
export const apiBoxChangePassword = (data: IBoxChangePassword) =>
  client.put('admin/change-password', data)
