import client from '@/api/axiosClient'
import { IEditProfile, IResetPassword } from '@/interfaces'

// get profile detail
export const apiGetProfileDetail = () => client.get('/monitors/profile/detail')

// edit profile detail
export const apiEditProfileDetail = (data: IEditProfile) =>
  client.put('/monitors/profile/edit', data)

// edit password profile detail
export const apiEditPassWordProfileDetail = (data: IResetPassword) =>
  client.put('/monitors/profile/edit-password', data)
