import axios from 'axios'
import { AppMessage } from '@/interfaces'
import { setCookie, getCookie } from '@/utils/cookies'
import {
  AUTH_USER_TOKEN,
  AUTH_REMEMBER_EXPIRES,
  TAB_ID_TOKEN,
  AUTH_IS_BOX,
} from '@/constants/common'
import { FormType } from '@/interfaces/uploadImage'
import i18n from 'i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)

dayjs.extend(timezone)

export const setUserToken = (token: string, isRemember: boolean = false) => {
  const expires = isRemember
    ? dayjs().add(AUTH_REMEMBER_EXPIRES, 'day').toString()
    : ''
  setCookie(AUTH_USER_TOKEN, token, expires)
}

export const setBoxToken = (
  token: string,
  isRemember: boolean = false,
  isBox: boolean = false
) => {
  const expires = isRemember
    ? dayjs().add(AUTH_REMEMBER_EXPIRES, 'day').toString()
    : ''
  setCookie(AUTH_USER_TOKEN, token, expires)
  setCookie(AUTH_IS_BOX, JSON.stringify(isBox), expires)
}

export const getUserToken = () => getCookie(AUTH_USER_TOKEN)

export const getIsBox = () => getCookie(AUTH_IS_BOX)

export const removeUserToken = () => {
  setCookie(AUTH_USER_TOKEN, '', dayjs().subtract(1).toString())
  setCookie(AUTH_IS_BOX, '', dayjs().subtract(1).toString())
}

export const handleMessageError = (err: any) => {
  let errMessage = ''
  if (axios.isAxiosError(err)) {
    if (err.response?.data.errors && err.response?.data.errors.length > 0) {
      errMessage = err.response?.data.errors[0].message
    } else {
      errMessage = err.response?.data.message
        ? err.response?.data.message
        : i18n.t('error.http_error')
    }
  }

  if (errMessage.substring(0, 2) === 'E_') {
    return i18n.t(`error.${errMessage}`)
  }

  return errMessage
}

export const handleError = (error: any): AppMessage => {
  const errorType =
    axios.isAxiosError(error) && error.response?.status === 401 ? '' : 'error'

  return {
    type: errorType,
    content: handleMessageError(error),
  }
}

export const getValueMasterData = (array: Array<any>) =>
  array.reduce(
    (obj: any, item: any) => ({
      ...obj,
      [Number(item.value)]: item,
    }),
    null
  )

export const getValueMasterDataString = (array: Array<any>) =>
  array.reduce(
    (obj: any, item: any) => ({
      ...obj,
      [item.value]: item,
    }),
    null
  )

export const generateKey = (prefix: string, index: number) =>
  `${prefix}_${index}`

export const getEnv = (key: string) => process.env[`REACT_APP_${key}`] ?? ''

export const showTotalPaginationTable = (
  perPage: number,
  currentPage: number,
  arrayLength: number,
  total: number
): string =>
  `${perPage * (currentPage - 1) + 1}-${
    perPage * (currentPage - 1) + arrayLength
  } / ${total}`

export const renderLabelFormType = (
  label: string,
  formType: FormType
): string => `${label} ${formType !== 'view' ? '' : ''}`

export const renderLabel = (label: string): string => `${label}`

export const setTabId = () => {
  const timeStamp = new Date().getTime()
  sessionStorage.setItem(TAB_ID_TOKEN, String(timeStamp))
}

export const getTabId = () => sessionStorage.getItem(TAB_ID_TOKEN)

export const checkNumberOnly = (input: any) => !/[^\d]/.test(input)

export const generateIdShareScreen = () => {
  let result = ''
  const characters = 'abcdefghijklmnopqrstuvwxyz01234567890123456789'
  const charactersLength = characters.length
  for (let i = 0; i < 9; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const convertTimeToJapan = (dateTime: string | null) => {
  if (dateTime === null || dateTime === '') {
    return dayjs()
  }
  if (dateTime.charAt(dateTime.length - 1).toLowerCase() === 'z') {
    return dayjs(dateTime).tz('Asia/Tokyo')
  }
  if (dateTime.includes('+')) {
    return dayjs(dateTime).tz('Asia/Tokyo')
  }
  return dayjs(dateTime)
}

export const removeHTMLTag = (content: string) =>
  content.replace(/(<([^>]+)>)/gi, '')
