import { apiUpdateShopEquipmentMasterLink } from '@/api/monitoring'
import { IListCamera, IListCameraRes } from '@/interfaces'
import { NoPhotography } from '@mui/icons-material'
import { Box, Grid, Paper, Switch } from '@mui/material'
import React, { useRef, useEffect, useState } from 'react'
import ReactHlsPlayer from 'react-hls-player'

interface ICameraComponent {
  camera: Array<IListCamera>
  setCamera: (camera: Array<IListCamera>) => void
  setIsOpenComment: () => void
  listCamera: IListCameraRes[]
  isHaveCall: boolean
  publicIp: string // 243413: publicIP from mirror
  roomCount: number
}

// Component camera
function CameraComponent({
  camera,
  setCamera,
  setIsOpenComment,
  listCamera,
  isHaveCall,
  publicIp, // 243413: publicIP from mirror
  roomCount,
}: ICameraComponent) {
  const cameraWebcam1 = useRef<any>(null)
  const cameraWebcamZoom1 = useRef<any>(null)
  const cameraWebcam2 = useRef<any>(null)
  const cameraWebcamZoom2 = useRef<any>(null)

  const [streamLink1, setStreamLink1] = useState<any>('')
  const [streamLink2, setStreamLink2] = useState<any>('')

  // handle zoom in camera
  const handleZoomInCamera = (indexCamera: number) => {
    // Task #233627
    if (isHaveCall) {
      const cameraClone = [...camera]
      cameraClone.forEach((item, index) => {
        item.statusCamera.isZoom = indexCamera === index
      })
      setIsOpenComment()
      setCamera(cameraClone)
    }
  }

  // handle zoom out camera
  const handleZoomOutCamera = (indexCamera: number) => {
    // Task #233627
    if (isHaveCall) {
      const cameraClone = [...camera]
      cameraClone[indexCamera].statusCamera.isZoom = false
      setIsOpenComment()
      setCamera(cameraClone)
    }
  }

  // handle turn on camera
  const toggleCameraOn = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone[indexCamera].statusCamera.status = true
    setIsOpenComment()
    setCamera(cameraClone)
  }

  // handle turn off camera
  const toggleCameraOff = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone[indexCamera].statusCamera.status = false
    setIsOpenComment()
    setCamera(cameraClone)
  }

  useEffect(() => {
    if (listCamera.length) {
      listCamera.forEach(async (item, index) => {
        const { id, stream_link: streamLink } = item
        if (index === 0) {
          // 255353: show camera tuong ung voi tung room neu box co 2 room
          setStreamLink1(streamLink)
        }
        // neu box co 1 room thi se show 2 camera ip
        if (index === 1 && roomCount <= 1) {
          setStreamLink2(streamLink)
        }

        // 243413: update master_link
        if (publicIp !== '') {
          await apiUpdateShopEquipmentMasterLink(Number(id), {
            ip_address: publicIp,
          })
        }
      })
    }
  }, [listCamera])

  // 243413: renderCamera if publicIp change
  return (
    <Grid container spacing={2}>
      {camera.map((item, index) => (
        <Grid
          item
          xs={4}
          sx={item.statusCamera.isZoom ? { display: 'none !important' } : {}}
          key={item.camera_id}
        >
          <Paper elevation={0} className="box-service-wrapper">
          {item.statusCamera.status ? (
            <Box
              className="box-service"
              onClick={() => handleZoomInCamera(index)}
            >
              <ReactHlsPlayer
                src={index === 0 ? streamLink1 : streamLink2}
                playerRef={index === 0 ? cameraWebcam1 : cameraWebcam2}
                width="100%"
                autoPlay
                height="auto"
                muted
              />
              <Box className="btn-switch">
                <Switch
                  disabled={!isHaveCall}
                  className="box-service-switch"
                  checked={item.statusCamera.status}
                  onChange={() => toggleCameraOff(index)}
                />
              </Box>
            </Box> ) : (
            <Box
              className="box-service-no-camera"
              sx={
                item.statusCamera.status ? { display: 'none !important' } : {}
              }
            >
              <Box
                className="icon-no-camera"
                onClick={() => handleZoomInCamera(index)}
              >
                <NoPhotography />
              </Box>
              <Switch
                disabled={!isHaveCall}
                className="box-service-switch"
                checked={item.statusCamera.status}
                onChange={() => toggleCameraOn(index)}
              />
            </Box>
            )}
          </Paper>
        </Grid>
      ))}
      {camera.map((item, index) => (
        <Grid
          item
          xs={6}
          key={item.camera_id}
          sx={!item.statusCamera.isZoom ? { display: 'none !important' } : {}}
        >
          <Paper elevation={0} className="box-service-wrapper">
            {item.statusCamera.status ? (
              <Box
                className="box-service"
                onClick={() => handleZoomInCamera(index)}
              >
                <ReactHlsPlayer
                  src={index === 0 ? streamLink1 : streamLink2}
                  playerRef={index === 0 ? cameraWebcamZoom1 : cameraWebcamZoom2}
                  width="100%"
                  autoPlay
                  height="auto"
                  muted
                />
                <Box className="btn-switch">
                  <Switch
                    disabled={!isHaveCall}
                    className="box-service-switch"
                    checked={item.statusCamera.status}
                    onChange={() => toggleCameraOff(index)}
                  />
                </Box>
              </Box>
            ) : (
              <Box className="box-service-no-camera">
                <Box
                  className="icon-no-camera"
                  onClick={() => handleZoomOutCamera(index)}
                >
                  <NoPhotography />
                </Box>
                <Switch
                  disabled={!isHaveCall}
                  className="box-service-switch"
                  checked={item.statusCamera.status}
                  onChange={() => toggleCameraOn(index)}
                />
              </Box>
            )}
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export default React.memo(CameraComponent)
