import en from '@/assets/images/flag_en.png'
import jp from '@/assets/images/flag_jp.png'

export const AUTH_USER_TOKEN = 'auth_user_token'

export const AUTH_IS_BOX = 'auth_is_box'

export const AUTH_REMEMBER_EXPIRES = 30 // DAYS

export const TAB_ID_TOKEN = 'tab_id_token'

export const DEFAULT_LANGUAGE = 'ja'

export const LANGUAGES = [
  {
    lang: 'en',
    title: 'English',
  },
  {
    lang: 'ja',
    title: 'Japanese',
  },
]

export const LANGS = [
  {
    label: 'Japanese',
    value: 'ja',
    icon: jp,
  },
  {
    label: 'English',
    value: 'en',
    icon: en,
  },
]

export const LANGUAGE_STORE_KEY = 'by_current_lang'

export const DUMMY_VALUE = {
  ALL: 'all',
}

export const WS_PREFIX = 'monitor'

export const TYPE_MESSAGE = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export const VALIDATION_PASSWORD_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,32}$/

export const DAYJS_FORMAT = 'YYYY/MM/DD'

export const DAYJS_FORMAT_NEW = 'YYYY-MM-DD'

export const DATE_PICKER_FORMAT = 'yyyy/MM/dd'

export const DATE_PICKER_INPUT_YM = '____/__'

export const DATE_PICKER_INPUT_YMD = '____/__/__'

export const HOUR_FORMAT = 'HH:mm'

export const PHONE_REGEX = /^[0-9]*$/

export const EMAIL_REGEX =
  /^\w+([\\.-]?\w+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/

export const URL_WEBSITE = window.location.origin
