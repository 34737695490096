import client from '@/api/axiosClient'

export const apiGetMonitorDetail = (id: any) => client.get(`/monitors/${id}`)

export const apiGetLowerRole = () => client.get('/monitors/lower-roles')

export const apiGetManager = (data: any) =>
  client.get('/monitors/manager-list', {
    params: {
      role: data.role,
      edit_monitor_id: data.edit_monitor_id,
    },
  })

export const apiGetGroup1 = (managerId: string | number) =>
  client.get(`monitors/${managerId}/group-boxes1`)

export const apiGetGroup2 = ({ managerId, boxes1 }: any) =>
  client.get(`/monitors/${managerId}/group-boxes2`, {
    params: {
      ids: boxes1,
    },
  })

export const apiCreateMonitor = (data: any) => client.post('/monitors', data)

export const apiGetMonitorList = (meta: any) =>
  client.get(`/monitors`, {
    params: {
      search: meta.all,
      group_box1: meta.group_1,
      group_box2: meta.group_2,
      role: meta.role,
      page: meta.page,
      results_per_page: meta.results_per_page,
      sort: meta.sort,
      parent_id: meta.parent_id,
    },
  })

export const apiQueryGetGroup1 = () => client.get('/monitors/group-boxes1')

export const apiQueryGetGroup2 = (group2: any) =>
  client.get('/monitors/group-boxes2', {
    params: {
      ids: group2,
    },
  })
export const apiEditMonitor = (data: any) =>
  client.put(`/monitors/${data.id}`, data.formData)

export const apiDeleteMonitor = (id: any) => client.delete(`/monitors/${id}`)

export const apiGetDirectManager = () => client.get('/monitors/direct-managers')

export const apiGetLowerGrade = (id: string | undefined) =>
  client.get('/monitors', {
    params: {
      parent_id: id,
    },
  })
