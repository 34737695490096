import '@/assets/styles/pages/Monitor/GroupSelect.scss'
import React, { useEffect, useState } from 'react'
import { IGroupSelectProps } from '@/interfaces'
import { useAppSelector } from '@/redux/hooks'
import {
  Checkbox,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
  Typography,
} from '@mui/material'
import { DUMMY_VALUE } from '@/constants'
import { useTranslation } from 'react-i18next'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

// Component multiple select
function GroupSelect({
  idInput,
  selectLabel,
  onChange,
  isDisable,
  onStateChange,
  errMessage,
  error,
  controlAction,
}: IGroupSelectProps) {
  const { t } = useTranslation()
  const { groupBox1, groupBox2 } = useAppSelector(
    state => state.monitor.monitorManagementInfo
  )

  const [selected, setSelected] = useState<any[]>([])
  const [group2Selected, setGroup2Selected] = useState<any[]>([])

  const isAllSelected =
    groupBox1.length > 0 && selected.length === groupBox1.length
  const isAllGroup2Selected =
    groupBox2.length > 0 && group2Selected.length === groupBox2.length

  // Disable group 1 or group 2 when the length === 0
  const isDisableAll = () => {
    if (idInput === 'group_boxes1') {
      return groupBox1.length === 0 || isDisable
    }
    return groupBox2.length === 0 || isDisable
  }

  // Catch value, upward the value to form
  const handleChange = (event: any) => {
    const { value } = event.target
    if (idInput === 'group_boxes1') {
      if (value[value.length - 1] === DUMMY_VALUE.ALL) {
        setSelected(
          selected.length === groupBox1.length
            ? []
            : groupBox1.map((item: any) => item.id)
        )
        if (onChange) {
          onChange(
            selected.length === groupBox1.length
              ? []
              : groupBox1.map((item: any) => item.id)
          )
        }
        if (onStateChange) {
          onStateChange((prev: any) => ({
            ...prev,
            group_1:
              selected.length === groupBox1.length
                ? []
                : groupBox1.map((item: any) => item.id),
          }))
        }
        return
      }
      setSelected(value)
      if (onChange) {
        onChange(value)
      }
      if (onStateChange) {
        onStateChange((prev: any) => ({ ...prev, group_1: value }))
      }
    } else {
      if (value[value.length - 1] === DUMMY_VALUE.ALL) {
        setGroup2Selected(
          group2Selected.length === groupBox2.length
            ? []
            : groupBox2.map((option: any) => option.id)
        )
        if (onStateChange) {
          onStateChange((prev: any) => ({
            ...prev,
            group_2:
              group2Selected.length === groupBox2.length
                ? []
                : groupBox2.map((option: any) => option.id),
          }))
        }
        return
      }
      setGroup2Selected(value)
      if (onStateChange) {
        onStateChange((prev: any) => ({
          ...prev,
          group_2: value,
        }))
      }
    }
  }

  // Render group 1 selected items
  const renderSelected = () => {
    if (controlAction.manager) {
      const newSelected = groupBox1.filter((item: any) =>
        selected.includes(item.id)
      )
      return newSelected.map((item: any) => item.name).join(', ')
    }
    return ''
  }

  // Render group 2 selected items
  const renderGroup2Selected = () => {
    if (controlAction?.group_1.length !== 0) {
      const newGroup2Selected = groupBox2.filter((item: any) =>
        group2Selected.includes(item.id)
      )
      return newGroup2Selected.map((item: any) => item.name).join(', ')
    }
    return ''
  }

  useEffect(() => {
    if (controlAction?.group_1.length === 0) {
      setSelected([])
      setGroup2Selected([])
      return
    }
    setSelected(controlAction?.group_1)
    setGroup2Selected(controlAction?.group_2)
  }, [controlAction])

  return (
    <FormControl
      className="form-control"
      error={idInput === 'group_boxes1' ? error?.group1Err : error?.group2Err}
    >
      <InputLabel id={idInput}>{selectLabel}</InputLabel>
      <Select
        labelId={idInput}
        multiple
        disabled={isDisableAll()}
        value={idInput === 'group_boxes1' ? selected : group2Selected}
        onChange={handleChange}
        renderValue={
          idInput === 'group_boxes1' ? renderSelected : renderGroup2Selected
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              width: 250,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          variant: 'menu',
        }}
      >
        <MenuItem
          value={DUMMY_VALUE.ALL}
          classes={{
            root: isAllSelected ? 'selected-all' : '',
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: '#3399bd' }}
              checked={
                idInput === 'group_boxes1' ? isAllSelected : isAllGroup2Selected
              }
              indeterminate={
                idInput === 'group_boxes1'
                  ? selected.length > 0 && selected.length < groupBox1.length
                  : group2Selected.length > 0 &&
                    group2Selected.length < groupBox2.length
              }
            />
          </ListItemIcon>
          <ListItemText primary={t('monitoring.monitor_detail.all')} />
        </MenuItem>
        {idInput === 'group_boxes1'
          ? groupBox1?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      selected.findIndex(item => item === option.id) !== -1
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={option.name} />
              </MenuItem>
            ))
          : groupBox2.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      group2Selected.findIndex(item => item === option.id) !==
                      -1
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
      </Select>
      {errMessage?.length !== 0 && error?.group2Err ? (
        <Typography color="error">{errMessage}</Typography>
      ) : (
        ''
      )}
    </FormControl>
  )
}

export default React.memo(GroupSelect)
