/* eslint-disable jsx-a11y/media-has-caption */
import '@/assets/styles/pages/historyDetail.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { Box, Grid, Stack, Paper, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import posterVideo from '@/assets/images/background-box-service.png'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { IMonitoringHistoryPlaybackVideo } from '@/interfaces'
import {
  generatePath,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { ROUTER_PATH } from '@/constants'
import {
  FETCH_HISTORY_PLAYBACK,
  SET_HISTORY_PLAYBACK,
} from '@/redux/reducers/monitoring.slice'
import { convertTimeToJapan, getIsBox } from '@/utils'

// History Detail Page
function HistoryDetail() {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const location = useLocation()

  const dispatch = useAppDispatch()

  const params = useParams()

  // Task #234289
  const { currentUser } = useAppSelector(state => state.auth)

  const isBox = getIsBox()

  const reservationId = useMemo(
    () => Number(location.hash.replace('#', '')),
    [location.hash]
  )

  const { listHistoryPlayback } = useAppSelector(state => state.monitoring)

  const [listVideo, setListVideo] = useState<IMonitoringHistoryPlaybackVideo[]>(
    []
  )

  const [fullScreen, setFullScreen] = useState({
    isFullScreen: false,
    srcVideo: '',
  })

  // Activated full screen
  const onClickFullScreen = (
    data: IMonitoringHistoryPlaybackVideo,
    id: string
  ) => {
    const vidZoom: HTMLVideoElement = document.getElementById(
      'video-zoom'
    ) as HTMLVideoElement
    setFullScreen({
      isFullScreen: true,
      srcVideo: data.url,
    })
    setListVideo(prev => {
      prev.forEach(state => {
        state.isZoom = state.id === data.id
      })
      return prev
    })
    const vid: HTMLVideoElement = document.getElementById(
      id
    ) as HTMLVideoElement
    vidZoom.setAttribute('src', data.url)
    vidZoom.currentTime = vid.currentTime
  }

  // TODO Deactivated full screen
  // const onClickExitFullScreen = () => {
  //   setFullScreen({
  //     isFullScreen: false,
  //     srcVideo: '',
  //   })
  //   const indexVideoZoom = listVideo.findIndex(video => video.isZoom)
  //   const vid: HTMLVideoElement = document.getElementById(
  //     `video-${indexVideoZoom}`
  //   ) as HTMLVideoElement
  //   vid.currentTime = vidZoom.currentTime
  //   setListVideo(prev => {
  //     prev.forEach(state => {
  //       state.isZoom = false
  //     })
  //     return prev
  //   })
  // }

  const renderVideoZoom = useMemo(
    () => (
      <Grid item xs={8} py={1} display="flex" flexDirection="column" gap={2}>
        <Box className="box-service-wrapper box-service-wrapper-zoom">
          <Box
            className="box-service box-service-zoom"
            sx={!fullScreen.isFullScreen ? { display: 'none' } : {}}
          >
            <video
              width="100%"
              height="100%"
              controls
              disablePictureInPicture
              id="video-zoom"
              controlsList="nodownload nofullscreen noplaybackrate"
              src={fullScreen.srcVideo}
            />
          </Box>
        </Box>

        {listHistoryPlayback.comments.length > 0 && (
          <Box component={Paper} className="comment-container" width="100%">
            <Box
              sx={{
                p: 1.5,
                maxHeight: 120,
              }}
            >
              <Box id="history-comment" className="comment-content" px={3}>
                {listHistoryPlayback.comments.map(item => (
                  <Stack key={item.id}>
                    <Stack direction="row">
                      <Typography fontWeight="bold">
                        {convertTimeToJapan(item.created_at || '').format(
                          'YYYY/MM/DD HH:mm'
                        )}
                      </Typography>
                      <Typography color="primary" ml={2}>
                        {item.nickname}
                      </Typography>
                    </Stack>
                    <Typography sx={{ wordBreak: 'break-word' }}>
                      {item.content}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </Grid>
    ),
    [listVideo, fullScreen]
  )

  useEffect(() => {
    if (listHistoryPlayback.videos.length > 0) {
      setListVideo(
        listHistoryPlayback.videos.map((item, idx) => ({
          ...item,
          isZoom: idx === 0,
        }))
      )
      setFullScreen({
        isFullScreen: true,
        srcVideo: listHistoryPlayback.videos[0]?.url,
      })
    }
  }, [listHistoryPlayback])

  useEffect(() => {
    if (
      (isBox !== '' &&
        JSON.parse(isBox) &&
        Number(params.id) === currentUser.id) ||
      isBox === ''
    ) {
      dispatch(
        FETCH_HISTORY_PLAYBACK({
          id: Number(params.id),
          start_time: '',
          end_time: '',
          reservation_id: reservationId,
        })
      )
    }

    return () => {
      dispatch(
        SET_HISTORY_PLAYBACK({
          videos: [],
          comments: [],
          boxName: {
            nickname: '',
          },
          start_time: '',
          end_time: '',
        })
      )
    }
  }, [params.id])

  // Task #234289
  if (currentUser.isBox && currentUser.id !== Number(params.id)) {
    return (
      <Navigate
        to={generatePath(ROUTER_PATH.MONITORING_HISTORY, {
          id: `${currentUser.id}`,
        })}
      />
    )
  }

  return (
    <Box p={1} sx={{ height: '100%' }}>
      <Typography mb={2} variant="h6" fontWeight="bold">
        {t('history.monitoring_history')}
      </Typography>
      <Box className="history-details-container">
        <Stack direction="column" justifyContent="space-between" height="100%">
          <Stack direction="column" spacing={3} overflow="hidden">
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography className="box-details-title">
                {listHistoryPlayback.boxName.nickname}
              </Typography>
              <Typography className="box-details-using" whiteSpace="nowrap">
                {`${convertTimeToJapan(
                  listHistoryPlayback?.start_time || ''
                ).format('HH:mm')}~${convertTimeToJapan(
                  listHistoryPlayback?.end_time || ''
                ).format('HH:mm')} ${convertTimeToJapan(
                  listHistoryPlayback?.start_time || ''
                ).format('YYYY/MM/DD')}`}
              </Typography>
              <Typography
                className="box-details-using"
                maxWidth="28vw"
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {`${t('monitoring_history.customer_user')}${
                  listHistoryPlayback.videos[0]?.nickname || ''
                }`}
              </Typography>
            </Stack>
            <Grid container px={2} justifyContent="center">
              <Grid
                item
                xs={listVideo.length > 1 ? 2 : 0}
                display={listVideo.length > 1 ? 'flex' : 'none'}
                flexDirection="column"
                alignItems="center"
                gap={2}
                mr={6}
                py={0.3}
                sx={{
                  maxHeight: '62vh',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                }}
              >
                {listVideo.map((item, index) => (
                  <Paper
                    elevation={0}
                    className="box-service-wrapper"
                    sx={
                      item.isZoom
                        ? {
                            display: 'none',
                          }
                        : {
                            maxHeight: 180,
                            maxWidth: 180,
                          }
                    }
                    key={item.id}
                  >
                    <Box
                      className="box-service"
                      onDoubleClick={() =>
                        onClickFullScreen(item, `video-${index}`)
                      }
                    >
                      <video
                        width="100%"
                        height="100%"
                        controls={false}
                        poster={posterVideo}
                        disablePictureInPicture
                        id={`video-${index}`}
                        controlsList="nodownload nofullscreen noplaybackrate"
                      >
                        <source src={item.url} type="video/mp4" />
                      </video>
                    </Box>
                  </Paper>
                ))}
              </Grid>
              {listVideo.length > 0 && renderVideoZoom}
            </Grid>
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Button
              variant="outlined"
              sx={{ borderRadius: 25, width: '8%' }}
              className="button-back"
              onClick={() =>
                navigate(
                  generatePath(ROUTER_PATH.MONITORING_HISTORY, {
                    id: params.id,
                  })
                )
              }
            >
              {t('monitoring.box_details.button.return')}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}
export default React.memo(HistoryDetail)
