import '@/assets/styles/pages/monitoring-history.scss'
import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Paper,
  Container,
  Typography,
  Tabs,
  Tab,
  Button,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import TabVideo from '@components/Monitoring/History/TabVideo'
import TabComment from '@components/Monitoring/History/TabComment'
import TabPanel from '@components/common/TabPanel'
import {
  generatePath,
  Navigate,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { ROUTER_PATH } from '@/constants'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  CLEAR_BOX_DETAIL_INFO,
  SET_BOX_DETAILS,
} from '@/redux/reducers/monitoring.slice'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { apiGetBoxDetail } from '@/api/monitoring'
import axios from 'axios'
import { getIsBox, handleError } from '@/utils'

// Monitoring Page
function Monitoring() {
  const params = useParams()

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const [tab, setTab] = useState(0)

  const isBox = getIsBox()

  const { boxDetails } = useAppSelector(state => state.monitoring)

  const { t } = useTranslation()

  // Task #234289
  const { currentUser } = useAppSelector(state => state.auth)

  if (Number(boxDetails.status) === 3) {
    navigate(ROUTER_PATH.MONITORING)
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  // Call API to get box detail
  const fetchBoxDetail = async () => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      const detailRes = await apiGetBoxDetail(Number(params.id))
      dispatch(SET_BOX_DETAILS(detailRes.data))
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          navigate(ROUTER_PATH.MONITORING)
        }
      }
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  useEffect(() => {
    if (
      (isBox !== '' &&
        JSON.parse(isBox) &&
        Number(params.id) === currentUser.id) ||
      isBox === ''
    ) {
      fetchBoxDetail()
    }
    return () => {
      dispatch(CLEAR_BOX_DETAIL_INFO())
    }
  }, [params.id])

  // Task #234289
  if (currentUser.isBox && currentUser.id !== Number(params.id)) {
    return (
      <Navigate
        to={generatePath(ROUTER_PATH.MONITORING_DETAILS, {
          id: `${currentUser.id}`,
        })}
      />
    )
  }

  return (
    <Box sx={{ height: '100%' }}>
      <Typography mb={2} variant="h6" fontWeight="bold">
        {t('monitoring_history.title')}
      </Typography>
      <Box className="monitoring-history-container">
        <Stack direction="column" justifyContent="space-between" height="100%">
          <Box>
            <Stack direction="row">
              <Typography className="box-details-title">
                {boxDetails.box_name}
              </Typography>
            </Stack>
            <Container
              fixed
              component={Paper}
              sx={{ p: 0, pl: '0 !important', pr: '0 !important' }}
            >
              <Tabs value={tab} onChange={handleTabChange}>
                <Tab label={t('monitoring_history.videoTab')} />
                <Tab label={t('monitoring_history.commentTab')} />
              </Tabs>
              <TabPanel value={tab} index={0}>
                <TabVideo />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <TabComment />
              </TabPanel>
            </Container>
          </Box>
          <Stack direction="row" justifyContent="center">
            <Button
              variant="outlined"
              sx={{ borderRadius: 25, width: '8%' }}
              className="button-back"
              onClick={() =>
                navigate(
                  generatePath(ROUTER_PATH.MONITORING_DETAILS, {
                    id: params.id,
                  })
                )
              }
            >
              {t('monitoring.box_details.button.return')}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}

export default React.memo(Monitoring)
