import { IMonitor } from '@/interfaces'
import dayjs from 'dayjs'
import { DAYJS_FORMAT } from '@/constants/common'

export const DUMMY_MONITOR: IMonitor = {
  id: 0,
  email: '',
  tel: '',
  name: '',
  sex: '',
  birthday: '',
  address: '',
  memo: '',
  status: 0,
  role: null,
  parent_id: null,
  parent_name: '',
  login_failed: 0,
  deleted_at: '',
  created_at: '',
  updated_at: '',
  avatar: '',
  upload_id: null,
  group_boxes1: [],
  group_boxes: [],
}

export const DUMMY_PROFILE = {
  id: undefined,
  email: '',
  tel: '',
  name: '',
  sex: '',
  birthday: dayjs().format(DAYJS_FORMAT),
  address: '',
  memo: '',
  status: '',
  role: '',
  parent_id: '',
  login_failed: 0,
  deleted_at: '',
  created_at: '',
  updated_at: '',
  avatar: '',
  upload_id: 0,
  parent_name: '',
  group_box1: [],
}
