import { apiChangeAirConSetting, apiGetAirConState } from '@/api/monitoring'
import { DEFAULT_LANGUAGE } from '@/constants'
import useMasterData from '@/hooks/useMasterData'
import { IListDevice } from '@/interfaces'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { getValueMasterDataString, handleError } from '@/utils'
import { Add, Remove } from '@mui/icons-material'
import {
  Divider,
  IconButton,
  Stack,
  Box,
  Switch,
  Typography,
} from '@mui/material'
import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IAirConditionalComponent {
  isUpdateStatus: boolean
  isUpdateStatusDevice: boolean
  setIsUpdateStatusDevice: (status: boolean) => void
  airConditionalProps: IListDevice
  isInterval: boolean
  id: string
}

const AIR_CON = 'AirCon'

// Component AirConditionalComponent
function AirConditionalComponent({
  isUpdateStatus,
  isUpdateStatusDevice,
  setIsUpdateStatusDevice,
  airConditionalProps,
  isInterval,
  id,
}: IAirConditionalComponent) {
  const { t } = useTranslation()

  const { languageMaster } = useAppSelector(state => state.app)

  const dispatch = useAppDispatch()

  const equipmentList = getValueMasterDataString(
    useMasterData('box_equipment_name')
  )
  // Ticket Id #232909
  const AIR_CONDITIONAL_DEFAULT_TEMP = Number(
    airConditionalProps.config?.airConditionDefaultTemp
  )

  const AIR_CONDITIONAL_MAX_TEMP = Number(
    airConditionalProps.config?.airConditionMaxTemp
  )

  const AIR_CONDITIONAL_MIN_TEMP = Number(
    airConditionalProps.config?.airConditionMinTemp
  )

  const [airConditional, setAirConditional] = useState<{
    temperature: number
    isTurnOn: boolean
    isHeat: boolean
  }>({
    temperature: AIR_CONDITIONAL_DEFAULT_TEMP,
    isTurnOn: false,
    isHeat: false,
  })

  // Call API get the current setting of air conditional
  const onGetSettingAirConditional = async (defaultTemp: number) => {
    setIsUpdateStatusDevice(true)
    try {
      if (!isUpdateStatus) {
        dispatch(SET_LOADING(true))
        dispatch(RESET_MESSAGE())
      }
      const res: AxiosResponse = await apiGetAirConState(Number(id))
      setAirConditional({
        temperature:
          Number(res.data.power) === 0 ? defaultTemp : Number(res.data.temp),
        isTurnOn: Number(res.data.power) === 1,
        isHeat:
          Number(res.data.power) === 0 ? false : Number(res.data.mode) === 1,
      })
    } catch (err) {
      if (!isUpdateStatus) {
        dispatch(SET_MESSAGE(handleError(err)))
      }
    } finally {
      dispatch(SET_LOADING(false))
      setIsUpdateStatusDevice(false)
    }
  }

  // Call API save the setting of air conditional
  const onSaveSettingAirConditional = async (setting: {
    power?: number
    mode?: number
    temp?: number
  }) => {
    try {
      dispatch(RESET_MESSAGE())
      setIsUpdateStatusDevice(true)
      await apiChangeAirConSetting(Number(id), setting)
    } catch (err) {
      dispatch(SET_MESSAGE(handleError(err)))
    } finally {
      onGetSettingAirConditional(AIR_CONDITIONAL_DEFAULT_TEMP)
    }
  }

  // Get the correct name of device depend on language
  const getNameEquipment = (flag: string | undefined = '') => {
    if (!equipmentList) return ''
    return languageMaster === DEFAULT_LANGUAGE
      ? equipmentList[flag]?.title_jp
      : equipmentList[flag]?.title_en
  }

  const getPowerAirConditional = (isTurnOn: boolean) => {
    const data = { power: isTurnOn ? 1 : 0 }
    onSaveSettingAirConditional(data)
  }

  const getModeAirConditional = (isHeat: boolean) => {
    const data = { power: 1, mode: isHeat ? 1 : 0 }
    onSaveSettingAirConditional(data)
  }

  const getTemperatureAirConditional = (
    isHeat: boolean,
    temperature: number
  ) => {
    const data = { power: 1, mode: isHeat ? 1 : 0, temp: temperature }
    onSaveSettingAirConditional(data)
  }

  const isDisableTempDecrease =
    !airConditional.isTurnOn ||
    isUpdateStatusDevice ||
    airConditional.temperature === AIR_CONDITIONAL_MIN_TEMP

  const isDisableTempIncrease =
    !airConditional.isTurnOn ||
    isUpdateStatusDevice ||
    airConditional.temperature === AIR_CONDITIONAL_MAX_TEMP

  useEffect(() => {
    if (!isInterval) {
      // Ticket Id #232909
      onGetSettingAirConditional(Number(AIR_CONDITIONAL_DEFAULT_TEMP))
    }
  }, [airConditionalProps])

  return (
    <>
      <Divider />
      <Stack direction="column" spacing={2} p={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Box className="device-icon device-icon-AirCon" />
            <Typography>{getNameEquipment(AIR_CON)}</Typography>
          </Stack>
          <Switch
            onChange={() => {
              setAirConditional({
                temperature: AIR_CONDITIONAL_DEFAULT_TEMP,
                isHeat: false,
                isTurnOn: !airConditional.isTurnOn,
              })
              getPowerAirConditional(!airConditional.isTurnOn)
            }}
            checked={airConditional.isTurnOn}
            id="light"
            className="control-panel-switch"
            disabled={isUpdateStatusDevice}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Box className="device-icon device-icon-HeatCool" />
            <Typography>
              {t(
                'monitoring.box_details.facility_setting.air_conditioner.heat_cool'
              )}
            </Typography>
          </Stack>
          <Switch
            onChange={() => {
              setAirConditional({
                ...airConditional,
                isHeat: !airConditional.isHeat,
                temperature: AIR_CONDITIONAL_DEFAULT_TEMP,
              })
              getModeAirConditional(!airConditional.isHeat)
            }}
            checked={airConditional.isHeat}
            id="light"
            className="control-panel-switch"
            disabled={!airConditional.isTurnOn || isUpdateStatusDevice}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Box className="device-icon device-icon-Temperature" />
            <Typography>
              {t(
                'monitoring.box_details.facility_setting.air_conditioner.temperature'
              )}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <IconButton
              disabled={isDisableTempDecrease}
              onClick={() => {
                setAirConditional({
                  ...airConditional,
                  temperature:
                    airConditional.temperature > AIR_CONDITIONAL_MIN_TEMP
                      ? airConditional.temperature - 1
                      : AIR_CONDITIONAL_MIN_TEMP,
                })
                getTemperatureAirConditional(
                  airConditional.isHeat,
                  airConditional.temperature > AIR_CONDITIONAL_MIN_TEMP
                    ? airConditional.temperature - 1
                    : AIR_CONDITIONAL_MIN_TEMP
                )
              }}
            >
              <Remove />
            </IconButton>
            <Typography>{airConditional.temperature}</Typography>
            <IconButton
              disabled={isDisableTempIncrease}
              onClick={() => {
                setAirConditional({
                  ...airConditional,
                  temperature:
                    airConditional.temperature < AIR_CONDITIONAL_MAX_TEMP
                      ? airConditional.temperature + 1
                      : AIR_CONDITIONAL_MAX_TEMP,
                })
                getTemperatureAirConditional(
                  airConditional.isHeat,
                  airConditional.temperature < AIR_CONDITIONAL_MAX_TEMP
                    ? airConditional.temperature + 1
                    : AIR_CONDITIONAL_MAX_TEMP
                )
              }}
            >
              <Add />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default React.memo(AirConditionalComponent)
