import '@/assets/styles/pages/reset.scss'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from '@/redux/hooks'
import { useNavigate, useLocation } from 'react-router-dom'
import { AuthResetPassword } from '@/interfaces'
import { Grid, Button, Box, TextField, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { ROUTER_PATH, VALIDATION_PASSWORD_REGEX } from '@/constants'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { apiUserResetPassword } from '@/api/auth'
import { handleError } from '@/utils'

enum ResetPasswordPage {
  NORMAL,
  SUCCESS,
  EXPIRED,
}

// Reset Password Page
function ResetPassword() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [currentPage, setCurrentPage] = useState(ResetPasswordPage.NORMAL)
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const token = query.get('token')
  const navigate = useNavigate()
  const defaultValues = {
    password: '',
    confirm_password: '',
  }
  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('validation.password_required'))
      .matches(VALIDATION_PASSWORD_REGEX, t('validation.password_invalid')),
    confirm_password: Yup.string()
      .required(t('validation.password_required'))
      .matches(VALIDATION_PASSWORD_REGEX, t('validation.password_invalid'))
      .oneOf([Yup.ref('password')], t('validation.password_not_match')),
  })

  // Call API to submit form
  const onSubmit = async (data: AuthResetPassword) => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      await apiUserResetPassword({
        password: data.password,
        confirm_password: data.confirm_password,
        token,
      })
      setCurrentPage(ResetPasswordPage.SUCCESS)
    } catch (error: any) {
      if (error.response?.status === 410) {
        setCurrentPage(ResetPasswordPage.EXPIRED)
        return
      }
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues,
  })

  // Navigate to sign in page
  const handleLogin = () => {
    navigate(ROUTER_PATH.AUTH.SIGN_IN)
  }

  // Navigate to forgot password page
  const handleForgotPassword = () => {
    navigate(ROUTER_PATH.AUTH.FORGOT_PASSWORD)
  }

  return (
    <Box className="reset-container">
      <Grid container>
        <Grid item xs={6} p={0} className="left-wrapper" />
        <Grid item xs={6} className="right-wrapper">
          {currentPage === ResetPasswordPage.NORMAL && (
            <Box className="form-reset">
              <Typography variant="h4" className="title">
                {t('reset_password.title')}
              </Typography>
              <Typography pt={2}>
                <Trans parent="span" i18nKey="reset_password.description" />
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                sx={{ mt: 5 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Box>
                          <Typography sx={{ fontSize: 16 }}>
                            {t('reset_password.label_newPassword')}
                          </Typography>
                          <TextField
                            {...field}
                            type="password"
                            margin="normal"
                            fullWidth
                            id="password"
                            placeholder={t(
                              'reset_password.placeholder_newPassword'
                            )}
                            error={!!error}
                            helperText={error?.message}
                          />
                        </Box>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} sm={12}>
                    <Controller
                      name="confirm_password"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <Box>
                          <Typography sx={{ fontSize: 16 }}>
                            {t('reset_password.label_confirm_new_password')}
                          </Typography>
                          <TextField
                            {...field}
                            type="password"
                            margin="normal"
                            fullWidth
                            id="confirm_password"
                            placeholder={t(
                              'reset_password.placeholder_confirm_new_password'
                            )}
                            error={!!error}
                            helperText={error?.message}
                          />
                        </Box>
                      )}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  {t('reset_password.title')}
                </Button>
              </Box>
            </Box>
          )}

          {currentPage === ResetPasswordPage.SUCCESS && (
            <Box className="form-reset">
              <Typography variant="h4" className="title">
                {t('reset_password.password_changed')}
              </Typography>
              <Typography pt={2}>
                <Trans
                  parent="span"
                  i18nKey="reset_password.description_success"
                />
              </Typography>

              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleLogin}
              >
                {t('reset_password.btn_login')}
              </Button>
            </Box>
          )}
          {currentPage === ResetPasswordPage.EXPIRED && (
            <Box className="form-reset">
              <Typography variant="h4" className="title">
                {t('reset_password.error')}
              </Typography>
              <Typography pt={2}>
                <Trans
                  parent="span"
                  i18nKey="reset_password.description_error"
                />
              </Typography>

              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleForgotPassword}
              >
                {t('reset_password.btn_forgot_password')}
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={handleLogin}
              >
                {t('reset_password.btn_login')}
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(ResetPassword)
