import { all, takeLatest, put, call, ForkEffect } from 'redux-saga/effects'
import { apiGetNotice, apiGetNoticeList, apiUpdateRead } from '@/api/contact'
import { AxiosResponse } from 'axios'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { handleError } from '@/utils'
import {
  FETCH_NOTICE,
  SET_NOTICE,
  FETCH_NOTICE_LIST,
  SET_NOTICE_LIST,
  SET_IS_READ,
} from '@/redux/reducers/contact.slice'
import { PayloadAction } from '@reduxjs/toolkit'
import { INoticeParams } from '@/interfaces'
import { removeHTMLTag } from '@/utils/helper'

function* getNotice() {
  try {
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield call(apiGetNotice)
    const { data } = response
    yield put(
      SET_NOTICE(
        data.map((item: any) => ({
          ...item,
          content: removeHTMLTag(item.content),
        }))
      )
    )
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  }
}

function* getNoticeList(action: PayloadAction<INoticeParams>) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield call(apiGetNoticeList, action.payload)
    const newestRes: AxiosResponse = yield call(apiGetNotice)
    const { data, meta } = response.data
    yield put(
      SET_NOTICE_LIST({
        meta,
        data,
      })
    )
    yield put(
      SET_NOTICE(
        newestRes.data.map((item: any) => ({
          ...item,
          content: removeHTMLTag(item.content),
        }))
      )
    )
    if (data.length > 0) {
      yield call(
        apiUpdateRead,
        data.map((item: any) => item.id)
      )
    }
    yield put(SET_IS_READ())
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

export default function* contactSaga() {
  const filteredSagas: ForkEffect[] = [
    takeLatest(FETCH_NOTICE, getNotice),
    takeLatest(FETCH_NOTICE_LIST, getNoticeList),
  ]
  yield all(filteredSagas)
}
