import '@/assets/styles/pages/dashboard.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { useAppSelector } from '@/redux/hooks'
import { generatePath, Navigate } from 'react-router-dom'
import { ROUTER_PATH } from '@/constants'

// Dashboard Page
function Dashboard() {
  // Task #234289
  const { currentUser } = useAppSelector(state => state.auth)
  if (currentUser.isBox) {
    return (
      <Navigate
        to={generatePath(ROUTER_PATH.MONITORING_DETAILS, {
          id: `${currentUser.id}`,
        })}
      />
    )
  }

  const { t } = useTranslation()
  return (
    <Box className="dashboard-container">
      <Typography variant="h6">{t('dashboard.index.title')}</Typography>
      <Box className="background-landing" />
    </Box>
  )
}

export default React.memo(Dashboard)
