import React, { useState, useEffect, useRef } from 'react'
import { Box, Avatar, Stack, IconButton, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'
import { UploadImageProps } from '@/interfaces'
import { apiUpload } from '@/api/uploadImage'
import { useTranslation } from 'react-i18next'
import { handleMessageError } from '@/utils/helper'
import { useAppDispatch } from '@/redux/hooks'
import { SET_LOADING } from '@/redux/reducers/app.slice'
import ConfirmDeleteModal from '@components/common/ConfirmDeleteModal'

// Component Upload image
function UploadImage({
  type,
  size,
  imageURL,
  formType,
  children,
  onChange,
  idUpload,
  isDelete,
  errMessage,
  maxSize,
}: UploadImageProps) {
  const imageRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [toggleModal, setToggleModal] = useState<boolean>(false)
  const [imageUpload, setImageUpload] = useState({
    isLoading: false,
    imageURL,
    err: false,
    uploadError: '',
  })

  // Call API to upload the chosen file
  const handleUpload = async (file: any) => {
    if (file) {
      setImageUpload({ ...imageUpload, isLoading: true, err: false })
      if (maxSize && file.size >= maxSize * 1024 ** 2) {
        setImageUpload({
          isLoading: false,
          imageURL: '',
          err: true,
          uploadError: t('validation.avatar_invalid'),
        })
        return
      }
      const iconForm = new FormData()
      iconForm.append('type', type)
      iconForm.append('file', file)

      try {
        dispatch(SET_LOADING(true))
        const response = await apiUpload(iconForm)
        const { id } = response.data
        onChange(id)
        setImageUpload({
          ...imageUpload,
          imageURL: response.data.file_url,
          isLoading: false,
          err: false,
        })
      } catch (error) {
        setImageUpload({
          imageURL: '',
          isLoading: false,
          err: true,
          uploadError: handleMessageError(error),
        })
      } finally {
        dispatch(SET_LOADING(false))
      }
    }
  }

  // Clear the chosen file
  const handleClearValue = () => {
    if (imageRef.current) {
      imageRef.current.value = ''
    }
  }

  // Clear the chosen file when click the confirm button
  const handleConfirmDelete = () => {
    onChange(undefined)
    setImageUpload({ ...imageUpload, imageURL: '' })
    handleClearValue()
    setToggleModal(false)
  }

  useEffect(() => {
    setImageUpload({ ...imageUpload, imageURL })
  }, [imageURL, formType])

  return (
    <Stack direction="column" spacing={1} sx={{ wordBreak: 'break-word' }}>
      <label
        htmlFor={idUpload}
        style={{ width: size.width, height: size.height }}
      >
        <Stack direction="row" spacing={3} alignItems="center">
          <Avatar
            src={imageUpload.imageURL}
            alt="Avatar"
            variant="square"
            sx={{
              width: size.width,
              height: size.height,
              borderRadius: 2,
              cursor: formType === 'view' ? 'default' : 'pointer',
              objectFit: 'cover',
            }}
          >
            {children}
          </Avatar>
          <input
            ref={imageRef}
            disabled={formType === 'view'}
            style={{ display: 'none' }}
            id={idUpload}
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            onChange={e => {
              if (e.target.files) {
                handleUpload(e.target.files[0])
              }
            }}
          />
          {isDelete && (
            <Box>
              <IconButton
                disabled={type === 'view'}
                onClick={() => setToggleModal(true)}
              >
                <Delete />
              </IconButton>
            </Box>
          )}
        </Stack>
      </label>
      {imageUpload.err ? (
        <Typography variant="body2" color="error">
          {errMessage || imageUpload.uploadError}
        </Typography>
      ) : (
        <> </>
      )}
      <ConfirmDeleteModal
        openDialog={toggleModal}
        setOpenDialog={setToggleModal}
        onConfirmDialog={handleConfirmDelete}
      />
    </Stack>
  )
}

export default React.memo(UploadImage)
