import {
  ProfileState,
  IProfile,
  IEditProfile,
  IResetPassword,
} from '@/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DUMMY_PROFILE } from '@/constants'

const initialState: ProfileState = {
  profile: DUMMY_PROFILE,
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    FETCH_PROFILE_DETAIL: () => {},
    SET_PROFILE_DETAIL: (state, action: PayloadAction<IProfile>) => {
      state.profile = action.payload
    },
    EDIT_PROFILE: (state, action: PayloadAction<IEditProfile>) => {},
    EDIT_RESET_PASSWORD_PROFILE: (
      state,
      action: PayloadAction<IResetPassword>
    ) => {},
  },
})

// Actions
export const {
  FETCH_PROFILE_DETAIL,
  SET_PROFILE_DETAIL,
  EDIT_PROFILE,
  EDIT_RESET_PASSWORD_PROFILE,
} = profileSlice.actions

// Reducer
export default profileSlice.reducer
