import '@/assets/styles/pages/signin.scss'
import * as Yup from 'yup'
import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomLink from '@components/common/Link'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  AUTH_BOX_LOGIN,
  AUTH_USER_LOGIN,
} from '@/redux/reducers/users/auth.slice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AuthSignIn } from '@/interfaces'
import {
  Grid,
  Button,
  Box,
  TextField,
  Typography,
  Checkbox,
  InputLabel,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EMAIL_REGEX, ROUTER_PATH } from '@/constants'

// Sign In Page
export default function SignIn() {
  const { t } = useTranslation()
  const [queryParam] = useSearchParams()
  const [isSubmit, setIsSubmit] = useState(false)
  const dispatch = useAppDispatch()
  const { currentUser } = useAppSelector(state => state.auth)
  const navigate = useNavigate()
  const defaultValues = {
    email: '',
    password: '',
    isRemember: false,
  }
  const SignInSchema = Yup.object().shape({
    email: Yup.string(),
    password: Yup.string(),
  })

  // Call API to submit form
  // Task #234289
  const onSubmit = (data: AuthSignIn) => {
    if (EMAIL_REGEX.test(data.email)) {
      dispatch(AUTH_USER_LOGIN(data))
    } else {
      const { email, password, isRemember } = data
      dispatch(
        AUTH_BOX_LOGIN({ username: email, password, isRemember, is_box: true })
      )
    }
    setIsSubmit(true)
  }
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(SignInSchema),
    defaultValues,
    mode: 'all',
  })
  React.useEffect(() => {
    if (currentUser.id && isSubmit) {
      const pathname = queryParam.get('redirect') || ROUTER_PATH.DASHBOARD
      navigate(pathname, { replace: true })
    }
  }, [currentUser])

  return (
    <Box className="signin-container">
      <Grid container>
        <Grid item xs={6} p={0} className="left-wrapper" />
        <Grid item xs={6} className="right-wrapper">
          <Grid container justifyContent="center">
            <Grid item xs={7}>
              <Box className="form-login">
                <Typography variant="h4" className="title">
                  {t('sign_in.title')}
                </Typography>
                <Typography pt={2}>{t('sign_in.description')}</Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        fullWidth
                        id="email"
                        label={t('sign_in.email_placeholder')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        type="password"
                        margin="normal"
                        fullWidth
                        id="password"
                        label={t('sign_in.password_placeholder')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Box display="flex">
                    <Controller
                      name="isRemember"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                    <InputLabel shrink sx={{ mt: 2 }}>
                      {t('sign_in.stay_logged_in')}
                    </InputLabel>
                  </Box>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {t('sign_in.sign_in')}
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <CustomLink
                        to={ROUTER_PATH.AUTH.FORGOT_PASSWORD}
                        activeClass="login-link-active"
                      >
                        {t('sign_in.forgot_password')}
                      </CustomLink>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
