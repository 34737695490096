import {
  Box,
  Grid,
  Stack,
  MenuItem,
  Button,
  Paper,
  TextField,
  Select,
  ListItemText,
  Checkbox,
  InputLabel,
  FormControl,
  ListItemIcon,
} from '@mui/material'
import React, { useState } from 'react'
import '@/assets/styles/pages/monitoring.scss'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { useAppSelector } from '@/redux/hooks'

import dayjs from 'dayjs'
import { enUS, ja } from 'date-fns/locale'
import {
  DATE_PICKER_FORMAT,
  DATE_PICKER_INPUT_YMD,
  DEFAULT_LANGUAGE,
  DUMMY_VALUE,
} from '@/constants'
import { getValueMasterData } from '@/utils'
import useMasterData from '@/hooks/useMasterData'

// Component box list form filter
function FormFilter({ handleSubmit, onSubmit, control }: any) {
  const { t } = useTranslation()

  const [startTime, setStartTime] = useState<Date | null>(null)

  const [endTime, setEndTime] = useState<Date | null>(null)

  const { listGroup1, listGroup2, listPrefectures } = useAppSelector(
    state => state.monitoring
  )

  const { languageMaster } = useAppSelector(state => state.app)

  const listData: Array<any> = Object.values(
    getValueMasterData(useMasterData('monitor_box_status')) || {}
  )

  return (
    <Paper elevation={3} className="filter-wrapper">
      <Box onSubmit={handleSubmit(onSubmit)} component="form">
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Controller
                  name="gb1Ids"
                  control={control}
                  render={({ field }) => (
                    <FormControl size="small">
                      <InputLabel id="label_group_1">
                        {t('monitoring.filter.group_1')}
                      </InputLabel>
                      <Select
                        labelId="label_group_1"
                        multiple
                        value={field.value}
                        renderValue={(selected: number[]) =>
                          listGroup1
                            .filter(item => selected.includes(item.id))
                            .map(item => item.name)
                            .join(', ')
                        }
                        onChange={(e: any) => {
                          field.onChange(e.target.value)
                        }}
                      >
                        {listGroup1.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            <Checkbox
                              checked={field.value?.includes(item.id)}
                            />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="area"
                  control={control}
                  render={({ field }) => (
                    <FormControl size="small">
                      <InputLabel id="label_prefectures">
                        {t('monitoring.filter.prefectures')}
                      </InputLabel>
                      <Select
                        labelId="label_prefectures"
                        multiple
                        {...field}
                        renderValue={(selected: any) =>
                          listPrefectures
                            .filter(item => selected.includes(item))
                            .join(', ')
                        }
                      >
                        {listPrefectures.map(item => (
                          <MenuItem key={item} value={item}>
                            <Checkbox checked={field.value.includes(item)} />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="box_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      {...field}
                      label={t('monitoring.filter.box_name')}
                      inputProps={{ maxLength: 55 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="gb2Ids"
                  control={control}
                  render={({ field }) => (
                    <FormControl size="small">
                      <InputLabel id="label_group_2">
                        {t('monitoring.filter.group_2')}
                      </InputLabel>
                      <Select
                        labelId="label_group_2"
                        multiple
                        {...field}
                        renderValue={selected =>
                          listGroup2
                            .filter(item => selected.includes(item.id))
                            .map(item => item.name)
                            .join(', ')
                        }
                        onChange={e => {
                          field.onChange(e.target.value)
                        }}
                      >
                        {listGroup2.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            <Checkbox checked={field.value.includes(item.id)} />
                            <ListItemText primary={item.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <FormControl size="small">
                      <InputLabel id="status">
                        {t('monitoring.filter.status')}
                      </InputLabel>
                      <Select
                        labelId="status"
                        multiple
                        value={field.value}
                        renderValue={(selected: string[]) =>
                          listData
                            ?.filter(item => selected.includes(item.value))
                            .map(item => {
                              if (languageMaster === DEFAULT_LANGUAGE) {
                                return item.title_jp
                              }
                              return item.title_en
                            })
                            .join(', ')
                        }
                        onChange={(e: any) => {
                          const { value } = e.target
                          if (value[value.length - 1] === DUMMY_VALUE.ALL) {
                            field.onChange(
                              value.length === listData.length + 1
                                ? []
                                : listData.map(item => item.value)
                            )
                            return
                          }

                          field.onChange(value)
                        }}
                      >
                        <MenuItem value={DUMMY_VALUE.ALL}>
                          <ListItemIcon>
                            <Checkbox
                              classes={{ indeterminate: '#3399bd' }}
                              checked={field.value.length === listData?.length}
                              indeterminate={
                                field.value.length > 0 &&
                                field.value.length < listData?.length
                              }
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('monitoring.monitor_detail.all')}
                          />
                        </MenuItem>
                        {listData?.map(item => (
                          <MenuItem key={item.id} value={item.value}>
                            <Checkbox
                              checked={field.value?.includes(item.value)}
                            />
                            <ListItemText
                              primary={
                                languageMaster === DEFAULT_LANGUAGE
                                  ? item.title_jp
                                  : item.title_en
                              }
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row" spacing={3} width="100%">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
                  >
                    <Controller
                      name="start_time"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          value={field.value}
                          views={['year', 'month', 'day']}
                          onChange={fromDate => {
                            field.onChange(dayjs(fromDate).format('YYYY/MM/DD'))
                            setStartTime(fromDate)
                          }}
                          label={t('monitoring_history.from_date')}
                          allowSameDateSelection
                          inputFormat={DATE_PICKER_FORMAT}
                          maxDate={endTime !== null && endTime}
                          renderInput={params => (
                            <TextField
                              size="small"
                              {...params}
                              error={false}
                              onKeyDown={(e: any) => e.preventDefault()}
                            />
                          )}
                          mask={DATE_PICKER_INPUT_YMD}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <Box display="flex" alignItems="center">
                    ~
                  </Box>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
                  >
                    <Controller
                      name="end_time"
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          value={field.value}
                          views={['year', 'month', 'day']}
                          onChange={endDate => {
                            field.onChange(dayjs(endDate).format('YYYY/MM/DD'))
                            setEndTime(endDate)
                          }}
                          label={t('monitoring_history.to_date')}
                          allowSameDateSelection
                          inputFormat={DATE_PICKER_FORMAT}
                          minDate={startTime}
                          renderInput={params => (
                            <TextField
                              size="small"
                              {...params}
                              error={false}
                              onKeyDown={(e: any) => e.preventDefault()}
                            />
                          )}
                          mask={DATE_PICKER_INPUT_YMD}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              variant="contained"
              className="btn-search"
              type="submit"
              size="small"
            >
              {t('monitoring.button.search')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default React.memo(FormFilter)
