import '@/assets/styles/pages/changePassword.scss'
import React, { useMemo, useState } from 'react'
import * as Yup from 'yup'
import { useAppDispatch } from '@/redux/hooks'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'
import {
  Grid,
  Button,
  Box,
  TextField,
  Typography,
  Modal,
  IconButton,
  Stack,
  InputAdornment,
  DialogContent,
} from '@mui/material'
import { Close, Visibility, VisibilityOff } from '@mui/icons-material'
import { IResetPassword } from '@/interfaces'
import { AUTH_LOGOUT } from '@/redux/reducers/users/auth.slice'
import { ROUTER_PATH, VALIDATION_PASSWORD_REGEX } from '@/constants'
import { Trans, useTranslation } from 'react-i18next'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { getIsBox, handleError } from '@/utils'
import { apiEditPassWordProfileDetail } from '@/api/profile'
import { apiBoxChangePassword } from '@/api/auth'

interface ModalProps {
  isOpen: boolean
  toggleModal(open: any): void
}

// Component Change Password Modal
function ChangePasswordModal({ isOpen, toggleModal }: ModalProps) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isBox = getIsBox()
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  })

  // Show/hide current password
  const handleShowCurrentPassword = () =>
    setShowPassword({
      ...showPassword,
      currentPassword: !showPassword.currentPassword,
    })

  // Show/hide new password
  const handleShowNewPassword = () =>
    setShowPassword({
      ...showPassword,
      newPassword: !showPassword.newPassword,
    })

  // Show/hide confirm password
  const handleShowConfirmNewPassword = () =>
    setShowPassword({
      ...showPassword,
      confirmNewPassword: !showPassword.confirmNewPassword,
    })

  const defaultValues = {
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  }

  const dynamicSchema = useMemo(() => {
    if (isBox !== '' && JSON.parse(isBox)) {
      return Yup.object().shape({
        current_password: Yup.string().required(
          t('validation.password_required')
        ),
        new_password: Yup.string()
          .required(t('profile.newPassword_required'))
          .notOneOf(
            [Yup.ref('current_password')],
            t('profile.newPassword_difference')
          ),
        confirm_new_password: Yup.string()
          .required(t('profile.confirmNewPassword_required'))
          .oneOf(
            [Yup.ref('new_password')],
            t('profile.confirmNewPassword_not_match')
          ),
      })
    }
    return Yup.object().shape({
      current_password: Yup.string().required(
        t('validation.password_required')
      ),
      new_password: Yup.string()
        .required(t('profile.newPassword_required'))
        .matches(VALIDATION_PASSWORD_REGEX, t('profile.password_regex_failed'))
        .min(8, t('profile.password_regex_failed'))
        .max(32, t('profile.password_regex_failed'))
        .notOneOf(
          [Yup.ref('current_password')],
          t('profile.newPassword_difference')
        ),
      confirm_new_password: Yup.string()
        .required(t('profile.confirmNewPassword_required'))
        .oneOf(
          [Yup.ref('new_password')],
          t('profile.confirmNewPassword_not_match')
        ),
    })
  }, [isBox])

  // Call API submit the form's data
  const handleSubmitData = async (data: any) => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      // Task #253054
      if (isBox !== '' && JSON.parse(isBox)) {
        await apiBoxChangePassword(data)
        dispatch(AUTH_LOGOUT())
        navigate(ROUTER_PATH.AUTH.SIGN_IN)
      } else {
        await apiEditPassWordProfileDetail(data)
        dispatch(AUTH_LOGOUT())
        navigate(ROUTER_PATH.AUTH.RESET_PASSWORD)
      }
      toggleModal(!isOpen)
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  const onSubmit = (data: IResetPassword) => {
    handleSubmitData(data)
  }

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(dynamicSchema),
    defaultValues,
    mode: 'all',
  })

  // Reset fields, close modal
  const handleToggleModal = () => {
    reset(defaultValues)
    toggleModal(false)
  }

  return (
    <Modal open={isOpen} onClose={handleToggleModal}>
      <DialogContent>
        <Box className="modal-content modal-style" sx={{ minWidth: '600px' }}>
          <IconButton onClick={handleToggleModal} className="close-button">
            <Close />
          </IconButton>
          <Grid container textAlign="center">
            <Grid item xs>
              <Typography className="title" textAlign="center" m={2}>
                {t('profile.changePassword')}
              </Typography>
              <Typography variant="body2" textAlign="center">
                <Trans parent="span" i18nKey="profile.changePassword_advise" />
              </Typography>
            </Grid>
          </Grid>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <Stack direction="column" spacing={5}>
              <Controller
                name="current_password"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    type={showPassword.currentPassword ? 'text' : 'password'}
                    label={t('profile.currentPassword')}
                    placeholder={t('profile.field_placeholder')}
                    InputLabelProps={{ shrink: true }}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowCurrentPassword}>
                            {showPassword.currentPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="new_password"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    type={showPassword.newPassword ? 'text' : 'password'}
                    label={t('profile.newPassword')}
                    placeholder={t('profile.field_placeholder')}
                    InputLabelProps={{ shrink: true }}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowNewPassword}>
                            {showPassword.newPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="confirm_new_password"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    type={showPassword.confirmNewPassword ? 'text' : 'password'}
                    label={t('profile.confirmNewPassword')}
                    placeholder={t('profile.field_placeholder')}
                    InputLabelProps={{ shrink: true }}
                    error={!!error}
                    helperText={error?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowConfirmNewPassword}>
                            {showPassword.confirmNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Button type="submit" fullWidth variant="contained">
                {t('profile.changePassword')}
              </Button>
            </Stack>
          </Box>
        </Box>
      </DialogContent>
    </Modal>
  )
}

export default React.memo(ChangePasswordModal)
