// TODO: remove console.log
/* eslint-disable no-console */
import {
  apiGetBoxDetail,
  apiGetListCameraBoxDetail,
  apiGetListDeviceBoxDetail,
  apiGetMirrorCodeByBoxId,
  apiGetRoomDetail,
  apiJoinBox,
  apiLeftBox,
} from '@/api/monitoring'
import '@/assets/styles/pages/boxDetails.scss'
import Calling from '@/components/Monitoring/BoxDetails/Calling'
import Camera from '@/components/Monitoring/BoxDetails/Camera'
import CameraCalling from '@/components/Monitoring/BoxDetails/CameraCalling'
import CommentBox from '@/components/Monitoring/BoxDetails/CommentBox'
import ControlPanel from '@/components/Monitoring/BoxDetails/ControlPanel'
import { DEFAULT_LANGUAGE, HOUR_FORMAT, ROUTER_PATH } from '@/constants'
import useMasterData from '@/hooks/useMasterData'
import useSocket from '@/hooks/useSocket'
import {
  BOX_STATUS,
  IListCamera,
  IListCameraRes,
  IListDevice,
} from '@/interfaces'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import {
  HANDLE_CALL,
  LEAVE_CALL,
  PICKUP_A_CALL,
} from '@/redux/reducers/callVideo.slice'
import {
  CLEAR_BOX_DETAIL_INFO,
  FETCH_COMMENT_BOX_DETAILS,
  SET_BOX_DETAILS,
} from '@/redux/reducers/monitoring.slice'
import {
  convertTimeToJapan,
  getIsBox,
  getValueMasterData,
  handleError,
} from '@/utils'
import { useHMSActions } from '@100mslive/react-sdk'
import { ChatBubble, Phone } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import axios, { AxiosResponse } from 'axios'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import {
  generatePath,
  Navigate,
  useNavigate,
  useParams,
} from 'react-router-dom'

// Box Details Page
function BoxDetails() {
  const params = useParams()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  // hms
  const hmsActions = useHMSActions()

  // Task #234289
  const { currentUser } = useAppSelector(state => state.auth)

  const isBox = getIsBox()

  const { t } = useTranslation()

  const { isHaveCallPrivate, isCalling } = useAppSelector(
    state => state.callVideo
  )
  console.log('hms private call - detail - state: ', {
    isHaveCallPrivate,
    isCalling,
  })

  const { boxDetails } = useAppSelector(state => state.monitoring)

  const { languageMaster } = useAppSelector(state => state.app)

  // Task #233627
  const [isHaveCall, setIsHaveCall] = React.useState<boolean>(false)
  console.log(
    'hms private call - detail - local state - isHaveCall: ',
    isHaveCall
  )

  const [isOpenComment, setIsOpenComment] = React.useState<boolean>(false)

  const [isInterval, setIsInterval] = React.useState<boolean>(false)

  const [isShow, setIsShow] = React.useState<boolean>(false)

  const [isGoBack, setIsGoBack] = React.useState<boolean>(false)

  const [isCall, setIsCall] = React.useState<boolean>(false)
  console.log('hms private call - detail - local state - isCall: ', isCall)

  const [selectedRoom, setSelectedRoom] = React.useState<string>('')

  const [commentContent, setCommentContent] = React.useState<string>('')

  const [localVideoEl, setLocalVideoEl] = React.useState<any>(null)

  const [localVideoTrackId, setLocalVideoTrackId] = React.useState<any>(null)

  const [isCallWait, setIsCallWait] = React.useState<boolean>(false)
  console.log(
    'hms private call - detail - local state - isCallWait: ',
    isCallWait
  )

  const [isUpdateStatus, setIsUpdateStatus] = React.useState<boolean>(false)

  const [isMicrophoneOn, setIsMicrophoneOn] = React.useState<boolean>(true)

  const [isUpdateStatusDevice, setIsUpdateStatusDevice] =
    React.useState<boolean>(false)

  const [isVideoOn, setIsVideoOn] = React.useState<boolean>(true)

  const [mirrorCode, setMirrorCode] = React.useState<string>('')

  const { socket, isConnected, isDisconnected } = useSocket()

  const [camera, setCamera] = React.useState<Array<IListCamera>>([
    {
      camera_id: 1,
      statusCamera: {
        status: false,
        isZoom: false,
      },
    },
    {
      camera_id: 2,
      statusCamera: {
        status: false,
        isZoom: false,
      },
    },
  ])

  const [listCamera, setListCamera] = React.useState<IListCameraRes[]>([])

  const [listDevice, setListDevice] = React.useState<IListDevice[]>([])

  const statusBox = getValueMasterData(useMasterData('monitor_box_status'))

  const [publicIp, setPublicIp] = React.useState<string>('') // 243413: publicIP from mirror

  if (Number(boxDetails.status) === BOX_STATUS.REPAIRING) {
    navigate(ROUTER_PATH.MONITORING)
  }

  // Get room name #246031
  const roomNames = React.useMemo(() => {
    if (boxDetails.room_list && boxDetails.room_list?.length > 0) {
      return boxDetails.room_list.map(item => ({
        roomName: `${t('monitoring.box_details.room_name')} ${item}`,
        room_num: Number(item),
      }))
    }
    return []
  }, [boxDetails, languageMaster])

  // #246031 count room dang trong thoi gian su dung
  const roomCount = React.useMemo(() => {
    if (boxDetails.room_num && boxDetails.room_num > 1) {
      setCamera([
        {
          camera_id: 1,
          statusCamera: {
            status: false,
            isZoom: false,
          },
        },
      ])
    }
    return boxDetails.room_num || 1
  }, [boxDetails])

  // Get the correct status of box
  const getStatusBox = React.useMemo(() => {
    if (statusBox) {
      const boxStatus = Number(boxDetails.status)
      const title =
        languageMaster === DEFAULT_LANGUAGE
          ? statusBox[boxStatus]?.title_jp
          : statusBox[boxStatus]?.title_en
      if (boxStatus === BOX_STATUS.USING) {
        return `${boxDetails.username} ${title} (${convertTimeToJapan(
          boxDetails.start_time || ''
        ).format(HOUR_FORMAT)} ～ ${convertTimeToJapan(
          boxDetails.end_time || ''
        ).format(HOUR_FORMAT)})`
      }
      return title
    }
    return ''
  }, [statusBox])
  // Task id #231767
  const leaveCall = () => {
    console.log('hms private call - detail - leaveCall')
    setIsCall(false)
    dispatch(LEAVE_CALL())
    // Task #233627
    const cameraClone = [...camera]
    cameraClone.forEach(item => {
      item.statusCamera.status = false
      item.statusCamera.isZoom = false
    })
    if (localVideoTrackId) {
      hmsActions.detachVideo(localVideoTrackId, localVideoEl)
    }
    localStorage.removeItem('mirrorCode')
    if (socket) {
      socket.emit('monitor_hang_customer', {
        mirrorCode,
        staffId: currentUser.id.toString(),
      })
    }
  }

  // Call API get mirror code depend on box id
  const getMirrorCodeByBoxId = async () => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      const res: AxiosResponse = await apiGetMirrorCodeByBoxId(
        Number(boxDetails.box_id)
      )
      setMirrorCode(res.data.equipment_cd.toString())
    } catch (err) {
      console.log(
        'hms private call - detail - getMirrorCodeByBoxId - error: ',
        err
      )
      dispatch(SET_MESSAGE(handleError(err)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // Emit event to socket server
  const handleCall = () => {
    const cameraClone = [...camera]
    cameraClone.forEach(item => {
      item.statusCamera.isZoom = false
    })
    console.log(
      'hms private call - detail - socket emit - monitor_call_to_mirror: '
    )
    socket.emit('monitor_call_to_mirror', {
      mirrorCode,
      // currentUserId: currentUser.id,
      passCode: currentUser.private_room.passcode,
      roomId: currentUser.private_room.room_id,
      monitorId: currentUser.id,
    })

    if (mirrorCode !== '') {
      // 243413: Call socket to get current IP
      socket.emit('get_ip_change', {
        mirrorCode,
      })
      setIsOpenComment(false)
      setIsCallWait(true)
      dispatch(HANDLE_CALL(true))
    }
  }

  // Open comment modal
  const handleComment = () => {
    const cameraClone = [...camera]
    cameraClone.forEach(item => {
      item.statusCamera.isZoom = false
    })
    setCamera(cameraClone)
    setIsOpenComment(!isOpenComment)
  }

  // #246031 get room detail when change room selected
  const handleGetRoomDetail = async (roomNum: string) => {
    try {
      dispatch(RESET_MESSAGE())
      const roomRes = await apiGetRoomDetail(Number(params.id), Number(roomNum))
      const { mirror_code: curMirrorCode } = roomRes.data
      setMirrorCode(curMirrorCode.toString())
    } catch (error) {
      console.log(
        'hms private call - detail - handleGetRoomDetail - error: ',
        error
      )
      dispatch(SET_MESSAGE(handleError(error)))
    }
  }

  // Call API get cameras
  const getListCamera = async () => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      const res: AxiosResponse = await apiGetListCameraBoxDetail(
        Number(params.id)
      )
      setListCamera(res.data.filter((item: any) => item.stream_link))
    } catch (err) {
      console.log('hms private call - detail - getListCamera - error: ', err)
      const cameraClone = [...camera]
      cameraClone.forEach(item => {
        item.statusCamera.status = false
        item.statusCamera.isZoom = false
      })
      setCamera(cameraClone)
      dispatch(SET_MESSAGE(handleError(err)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // Call API to join box
  const joinBox = async () => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      await apiJoinBox(Number(params.id))
    } catch (err) {
      dispatch(SET_MESSAGE(handleError(err)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // Call API to left box
  const leftBox = async () => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      await apiLeftBox(Number(params.id))
    } catch (err) {
      dispatch(SET_MESSAGE(handleError(err)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // Call API to get list devices when mounted
  const getListDevice = async () => {
    try {
      dispatch(SET_LOADING(true))
      const res: AxiosResponse = await apiGetListDeviceBoxDetail(
        Number(params.id)
      )
      setListDevice(
        res.data.flatMap((item: any) => {
          if (item.shopEquipmentDetails.length > 0) {
            return item.shopEquipmentDetails.map((device: any) => ({
              shop_equipment_id: item.id,
              shop_equipment_detail_id: device.id,
              state: device.state,
              icon: device.icon,
            }))
          }
          // Ticket Id #232909
          if (item.icon === 'AirCon' || item.icon === 'FloorTemp') {
            return {
              shop_equipment_id: item.id,
              state: item.status,
              icon: item.icon || '',
              config: item.config,
            }
          }
          return {
            shop_equipment_id: item.id,
            state: item.status,
            icon: item.icon || '',
          }
        })
      )
    } catch (err) {
      dispatch(SET_MESSAGE(handleError(err)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // Call API to get list devices every 1 minute
  const getListDeviceNoLoading = async () => {
    setIsUpdateStatusDevice(true)
    const res: AxiosResponse = await apiGetListDeviceBoxDetail(
      Number(params.id)
    )
    if (res && res.status === 200) {
      setListDevice(
        res.data.flatMap((item: any) => {
          if (item.shopEquipmentDetails.length > 0) {
            return item.shopEquipmentDetails.map((device: any) => ({
              shop_equipment_id: item.id,
              shop_equipment_detail_id: device.id,
              state: device.state,
              icon: device.icon,
            }))
          }
          if (item.icon === 'AirCon' || item.icon === 'FloorTemp') {
            return {
              shop_equipment_id: item.id,
              state: item.status,
              icon: item.icon || '',
              config: item.config,
            }
          }
          return {
            shop_equipment_id: item.id,
            state: item.status,
            icon: item.icon || '',
          }
        })
      )
    }
    setIsUpdateStatusDevice(false)
  }

  // Call API to get box detail
  const fetchBoxDetail = async () => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      const detailRes = await apiGetBoxDetail(Number(params.id))
      dispatch(SET_BOX_DETAILS(detailRes.data))
      // Task #251316
      if (detailRes.data.room_num > 1 && detailRes.data.room_list.length) {
        setSelectedRoom(`${detailRes.data.room_list[0]}`)
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 403) {
          navigate(ROUTER_PATH.MONITORING, {
            replace: true,
          })
        }
      }
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  React.useEffect(() => {
    if (
      (isBox !== '' &&
        JSON.parse(isBox) &&
        Number(params.id) === currentUser.id) ||
      isBox === ''
    ) {
      dispatch(FETCH_COMMENT_BOX_DETAILS(Number(params.id)))
      fetchBoxDetail()
      getListCamera()
      getListDevice()
      joinBox()
      setIsUpdateStatus(true)
      // Task #233627
      window.addEventListener('storage', () => {
        const isHaveCallStorage = JSON.parse(
          localStorage.getItem('isGlobalCall') ?? 'false'
        )
        console.log(
          'hms private call - detail - isHaveCallStorage: ',
          isHaveCallStorage
        )
        setIsHaveCall(isHaveCallStorage)
      })
    }
    const getStatusVacuum = setInterval(() => {
      setIsInterval(true)
      getListDeviceNoLoading()
    }, 60000)

    return () => {
      setIsUpdateStatus(false)
      clearInterval(getStatusVacuum)
      if (isHaveCallPrivate) {
        setIsCall(false)
        dispatch(LEAVE_CALL())
        if (socket) {
          socket.emit('monitor_hang_customer', {
            mirrorCode,
            staffId: currentUser.id.toString(),
          })
        }
      }
      // Task #233627
      window.removeEventListener('storage', () => undefined)
      dispatch(CLEAR_BOX_DETAIL_INFO())
    }
  }, [params.id])

  React.useEffect(() => {
    if (boxDetails.box_id !== 0) {
      getMirrorCodeByBoxId()
    }
  }, [boxDetails])

  // Task id #231767
  React.useEffect(() => {
    if (isConnected && mirrorCode !== '' && isCallWait) {
      socket
        .on('customize_course', (data: any) => {
          let paramsSocket: any = data
          if (typeof data === 'string') {
            paramsSocket = JSON.parse(data)
          }
          const eventInformation = paramsSocket.eventInformation[0]
          if (Number(paramsSocket.mirrorCode) !== Number(mirrorCode)) return
          // 256724: check eventPageId condition
          if (
            eventInformation.eventButtonId === 'answer' &&
            eventInformation.eventPageId === 'invited-by-staff'
          ) {
            dispatch(HANDLE_CALL(false))
            dispatch(PICKUP_A_CALL(`monitor_${paramsSocket.mirrorCode}`))
            localStorage.setItem('mirrorCode', paramsSocket.mirrorCode)
          }
          // 256724: check eventPageId condition
          if (
            eventInformation.eventButtonId === 'hangup' &&
            eventInformation.eventPageId === 'invited-by-staff'
          ) {
            dispatch(HANDLE_CALL(false))
          }
        })
        .on('refuse_staff', () => {
          dispatch(HANDLE_CALL(false))
        })
    }
    return () => {
      if (socket) {
        socket.off('customize_course').off('refuse_staff')
      }
    }
  }, [isConnected, mirrorCode, isCallWait])
  React.useEffect(() => {
    if (isHaveCallPrivate) {
      setIsCall(true)
      setIsOpenComment(false)
    } else {
      setIsCall(false)
      leaveCall()
    }
  }, [isHaveCallPrivate])

  React.useEffect(() => {
    if (!isCalling) {
      setIsCallWait(false)
    }
  }, [isCalling])

  React.useEffect(() => {
    // Task #233627
    if (boxDetails.status === BOX_STATUS.USING && (isHaveCall || isCall)) {
      const cameraClone = [...camera]
      cameraClone.forEach(item => {
        item.statusCamera.status = true
        item.statusCamera.isZoom = false
      })
      setCamera(cameraClone)
      return
    }
    // Task #233627
    if (boxDetails.status !== BOX_STATUS.USING) {
      setIsHaveCall(false)
      return
    }
    if (!isHaveCall) {
      const cameraClone = [...camera]
      cameraClone.forEach(item => {
        item.statusCamera.status = false
        item.statusCamera.isZoom = false
      })
      setCamera(cameraClone)
    }
  }, [boxDetails, isHaveCall, isCall])

  // 243413: get publicIp from Mirror
  React.useEffect(() => {
    if (isConnected && mirrorCode) {
      // 243413: Receive event get_ip_change from socket
      socket.on('get_ip_change', (data: any) => {
        let paramsSocket: any = data
        if (typeof data === 'string') {
          paramsSocket = JSON.parse(data)
        }
        if (Number(paramsSocket.mirror_code) !== Number(mirrorCode)) return
        setPublicIp(paramsSocket.public_ip)
      })
    }
    return () => {
      if (socket) {
        socket.off('get_ip_change')
      }
    }
  }, [isConnected, mirrorCode])

  // 246031 handle change room selected
  React.useEffect(() => {
    if (selectedRoom !== '') {
      handleGetRoomDetail(selectedRoom)
    }
  }, [selectedRoom])

  if (currentUser.isBox && currentUser.id !== Number(params.id)) {
    return (
      <Navigate
        to={generatePath(ROUTER_PATH.MONITORING_DETAILS, {
          id: `${currentUser.id}`,
        })}
      />
    )
  }

  return (
    <Box p={1} sx={{ height: '100%' }}>
      <Typography
        mb={2}
        variant="h6"
        fontWeight="bold"
        position="relative"
        zIndex={isHaveCallPrivate ? 1300 : 1}
      >
        {t('monitoring.box_details.title')}
      </Typography>
      <Box
        className="box-details-container"
        zIndex={isHaveCallPrivate ? 1300 : 1}
      >
        <Stack direction="column" justifyContent="space-between" height="100%">
          <Box>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography className="box-details-title">
                {boxDetails.box_name}
              </Typography>
              <Typography className="box-details-using">
                {getStatusBox}
              </Typography>
              {isCall && (
                <Typography className="box-details-calling">
                  {t('monitoring.box_details.in_a_call_with_the_user_id')}
                </Typography>
              )}
              <Button
                className="btn-history"
                onClick={() => {
                  if (isHaveCallPrivate) {
                    setIsGoBack(false)
                    setIsShow(true)
                    return
                  }
                  navigate(
                    generatePath(ROUTER_PATH.MONITORING_HISTORY, {
                      id: params.id,
                    })
                  )
                }}
              >
                {t('monitoring.box_details.button.history')}
              </Button>
            </Stack>
            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={9}>
                {isCall ? (
                  // Have Calling
                  <CameraCalling
                    camera={camera}
                    setCamera={setCamera}
                    listCamera={listCamera}
                    isMicrophoneOn={isMicrophoneOn}
                    setIsMicrophoneOn={setIsMicrophoneOn}
                    isVideoOn={isVideoOn}
                    setIsVideoOn={setIsVideoOn}
                    isDisconnected={isDisconnected}
                    // Task id #231767
                    setLocalVideoEl={setLocalVideoEl}
                    setLocalVideoTrackId={setLocalVideoTrackId}
                    leaveCall={leaveCall}
                    publicIp={publicIp} // 243413: publicIP from mirror
                    roomCount={roomCount} // 246031: count room in using time
                  />
                ) : (
                  // No calling
                  <Camera
                    camera={camera}
                    listCamera={listCamera}
                    setCamera={setCamera}
                    setIsOpenComment={() => setIsOpenComment(false)}
                    isHaveCall={isHaveCall}
                    publicIp={publicIp} // 243413: publicIP from mirror
                    roomCount={roomCount} // 246031: count room in using time
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <Stack direction="column" alignItems="center">
                  {/* 246031: create dropdown list to select room */}
                  {roomCount > 1 && (
                    <Box component={Paper} width="76%" pt={1} mb={1}>
                      <TextField
                        select
                        label={t('monitoring.box_details.room_label')}
                        sx={{
                          width: '100%',
                          backgroundColor: '#fff',
                          borderRadius: 1,
                        }}
                        size="medium"
                        onChange={e => setSelectedRoom(e.target.value)}
                        disabled={isCall}
                        value={selectedRoom}
                      >
                        {roomNames.map(item => (
                          <MenuItem
                            className="room-name"
                            key={item.room_num}
                            value={item.room_num}
                          >
                            {item.roomName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  )}
                  <ControlPanel
                    isInterval={isInterval}
                    setIsOpenComment={() => setIsOpenComment(false)}
                    listDevice={listDevice}
                    getListDevice={getListDeviceNoLoading}
                    isUpdateStatus={isUpdateStatus}
                    isUpdateStatusDevice={isUpdateStatusDevice}
                    setIsUpdateStatusDevice={status =>
                      setIsUpdateStatusDevice(status)
                    }
                  />
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Stack direction="column" spacing={3}>
            {!isCall && (
              <Stack
                direction="row"
                justifyContent="center"
                width="50%"
                spacing={3}
              >
                <IconButton
                  className="button-call"
                  onClick={handleCall}
                  disabled={
                    boxDetails.status !== BOX_STATUS.USING ||
                    (roomCount > 1 && selectedRoom === '')
                  }
                >
                  <Phone />
                </IconButton>
                <ChatBubble
                  className="button-message"
                  onClick={handleComment}
                />
              </Stack>
            )}
            <Stack direction="row" justifyContent="center">
              {/* Task #234289 */}
              {!currentUser.isBox && (
                <Button
                  variant="outlined"
                  sx={{ borderRadius: 25, width: '8%' }}
                  className="button-back"
                  onClick={() => {
                    if (isHaveCallPrivate) {
                      setIsGoBack(true)
                      setIsShow(true)
                      return
                    }
                    leftBox()
                    navigate(ROUTER_PATH.MONITORING)
                  }}
                >
                  {t('monitoring.box_details.button.return')}
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
        {isOpenComment && (
          <CommentBox
            commentContent={commentContent}
            setCommentContent={setCommentContent}
          />
        )}
        {isCallWait && (
          <Calling
            cancelCalling={() => {
              setIsCallWait(false)
              dispatch(HANDLE_CALL(false))
              socket.emit('monitor_hang_customer', {
                mirrorCode,
                staffId: currentUser.id,
              })
            }}
            isMicrophoneOn={isMicrophoneOn}
            setIsMicrophoneOn={setIsMicrophoneOn}
            isVideoOn={isVideoOn}
            setIsVideoOn={setIsVideoOn}
            username={boxDetails.username}
            isDisconnected={isDisconnected}
          />
        )}
      </Box>
      <Dialog
        className="dialog-container"
        onClose={() => setIsShow(false)}
        open={isShow}
        maxWidth="md"
        fullWidth
        sx={{
          padding: 3,
        }}
      >
        <Stack direction="column" spacing={5} alignItems="center" p={3}>
          <Stack direction="column">
            <Typography textAlign="center" fontSize={20}>
              {t('monitoring.box_details.alert_leave_page')}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={4}>
            <Button
              fullWidth
              variant="contained"
              color="error"
              sx={{
                fontWeight: 600,
                fontSize: 16,
                width: 150,
              }}
              onClick={() => {
                leaveCall()
                if (isGoBack) {
                  leftBox()
                  navigate(ROUTER_PATH.MONITORING)
                  return
                }
                navigate(
                  generatePath(ROUTER_PATH.MONITORING_HISTORY, {
                    id: params.id,
                  })
                )
              }}
            >
              Y
            </Button>
            <Button
              variant="outlined"
              sx={{
                fontWeight: 600,
                fontSize: 16,
                width: 150,
              }}
              onClick={() => setIsShow(false)}
            >
              N
            </Button>
          </Stack>
        </Stack>
      </Dialog>
      {isHaveCallPrivate && <Box className="page-loading" />}
    </Box>
  )
}
export default React.memo(BoxDetails)
