import { IMonitorState, IMonitor, IRole, IGroup } from '@/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DUMMY_MONITOR } from '@/constants/monitor'

const initialState: IMonitorState = {
  monitorDetail: DUMMY_MONITOR,

  isLoading: {
    getDetail: false,
    editDetail: false,
    createMonitor: false,
  },

  managementInfo: {
    lowerRole: [],
    managers: [],
    group1: [] as any[],
    group2: [] as IGroup[],
  },

  monitorManagementInfo: {
    lowerRole: [] as any[],
    manager: {} as any,
    managers: [] as any[],
    groupBox1: [] as any[],
    groupBox2: [] as any[],
  },

  monitorList: {
    availableInfo: {
      group1: [],
      group2: [],
      role: [],
      directManager: [],
    },

    meta: {
      total: 0,
      per_page: 0,
      current_page: 0,
      last_page: 0,
      first_page: '',
      first_page_url: '',
      last_page_url: '',
      next_page_url: '',
      previous_page_url: '',
    },
    data: [] as any[],

    created: false,
  },
}

export const monitorSlice = createSlice({
  name: 'monitor',
  initialState,
  reducers: {
    FETCH_MONITOR_DETAIL: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.isLoading.getDetail = true
    },
    SET_MONITOR_DETAIL: (state, action: PayloadAction<IMonitor>) => {
      const data = {
        ...action.payload,
        group_boxes: action.payload.group_boxes1.flatMap(
          item => item.group_box2
        ),
      }
      state.monitorDetail = data
      state.managementInfo.group1 = data.group_boxes1
      state.managementInfo.group2 = data.group_boxes
      state.isLoading.getDetail = false
    },

    FETCH_MANAGEMENT_INFO: () => {},
    SET_MANAGEMENT_INFO: (state, action: PayloadAction<any>) => {
      state.managementInfo = action.payload
    },

    FETCH_LOWER_ROLES: () => {},
    SET_LOWER_ROLES: (state, action: PayloadAction<IRole[]>) => {
      state.managementInfo.lowerRole = action.payload
    },

    CLEAR_GROUP: state => {
      state.monitorManagementInfo.groupBox1 = []
      state.monitorManagementInfo.groupBox2 = []
    },

    CLEAR_GROUP_2: state => {
      state.monitorManagementInfo.groupBox2 = []
    },

    FETCH_MANAGERS: (state, action: PayloadAction<any>) => {},
    SET_MANAGERS: (state, action: PayloadAction<any>) => {
      state.monitorManagementInfo.managers = action.payload
    },

    FETCH_GROUP_1: (state, action: PayloadAction<string | number>) => {},
    SET_GROUP_1: (state, action: PayloadAction<any>) => {
      state.monitorManagementInfo.groupBox1 = action.payload
    },

    FETCH_GROUP_2: (
      state,
      action: PayloadAction<{
        managerId: number | null
        boxes1: number[]
      }>
    ) => {},
    SET_GROUP_2: (state, action: PayloadAction<IGroup[]>) => {
      state.monitorManagementInfo.groupBox2 = action.payload
    },

    FETCH_AVAILABLE_INFO: () => {},

    SET_AVAILABLE_INFO: (state, action: PayloadAction<any>) => {
      const { group1, directManager } = action.payload
      state.monitorList.availableInfo.group1 = group1
      state.monitorList.availableInfo.directManager = directManager
    },

    FETCH_AVAILABLE_GROUP_2: (state, action: PayloadAction<any[]>) => {},

    SET_AVAILABLE_GROUP_2: (state, action: PayloadAction<any[]>) => {
      state.monitorList.availableInfo.group2 = action.payload
    },

    FETCH_MONITOR_LIST: (state, action: PayloadAction<any>) => {},

    SET_MONITOR_LIST: (state, action: PayloadAction<any>) => {
      state.monitorList = { ...state.monitorList, ...action.payload }
      state.monitorList.created = false
    },

    FETCH_MONITOR_MANAGEMENT_INFO: (_, action: PayloadAction<any>) => {},
    SET_MONITOR_MANAGEMENT_INFO: (state, action: PayloadAction<any>) => {
      state.monitorManagementInfo = action.payload
    },

    SET_CREATED: state => {
      state.monitorList.created = true
    },
  },
})

// Actions
export const {
  FETCH_MONITOR_DETAIL,
  SET_MONITOR_DETAIL,
  FETCH_LOWER_ROLES,
  SET_LOWER_ROLES,
  FETCH_MANAGERS,
  SET_MANAGERS,
  FETCH_GROUP_1,
  SET_GROUP_1,
  FETCH_GROUP_2,
  SET_GROUP_2,
  FETCH_AVAILABLE_INFO,
  SET_AVAILABLE_INFO,
  FETCH_MONITOR_LIST,
  SET_MONITOR_LIST,
  FETCH_MANAGEMENT_INFO,
  FETCH_AVAILABLE_GROUP_2,
  SET_AVAILABLE_GROUP_2,
  SET_MANAGEMENT_INFO,
  CLEAR_GROUP,
  CLEAR_GROUP_2,
  FETCH_MONITOR_MANAGEMENT_INFO,
  SET_MONITOR_MANAGEMENT_INFO,
  SET_CREATED,
} = monitorSlice.actions

// Reducer
export default monitorSlice.reducer
