import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IMonitoringState,
  IMonitoringHistoryVideoResponse,
  IMonitoringHistoryParams,
  IMonitoringHistoryCommentResponse,
  IMonitoringBoxesParams,
  IMonitoringHistoryPlaybackResponse,
  IMonitoringCommentBoxDetail,
  IMonitoringBoxDetails,
} from '@/interfaces'

const initialState: IMonitoringState = {
  listHistoryVideo: [],
  metaHistoryVideo: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: '',
    last_page_url: '',
    next_page_url: '',
    previous_page_url: '',
  },
  listHistoryComment: [],
  metaHistoryComment: {
    total: 0,
    per_page: 0,
    current_page: 0,
    last_page: 0,
    first_page: 0,
    first_page_url: '',
    last_page_url: '',
    next_page_url: '',
    previous_page_url: '',
  },
  listBoxes: [],
  metaListBoxes: {
    current_page: 0,
    first_page: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
  },
  listGroup1: [],
  listGroup2: [],
  listPrefectures: [],
  listHistoryPlayback: {
    videos: [],
    comments: [],
    boxName: {
      nickname: '',
    },
    start_time: '',
    end_time: '',
  },
  listCommentBoxDetail: [],
  boxDetails: {
    box_id: 0,
    box_name: '',
    status: 0,
    start_time: '',
    end_time: '',
    username: '',
    reservation_id: 0,
    service_box_id: null,
  },
  paramsBoxList: {
    gb1Ids: [],
    gb2Ids: [],
    status: [],
    start_time: '',
    end_time: '',
    page: 1,
    results_per_page: 10,
    area: [],
    box_name: '',
  },
}

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    FETCH_HISTORY_VIDEO: (
      _,
      action: PayloadAction<IMonitoringHistoryParams>
    ) => {},
    SET_HISTORY_VIDEO: (
      state,
      action: PayloadAction<IMonitoringHistoryVideoResponse>
    ) => {
      state.listHistoryVideo = action.payload.data.map(item => ({
        ...item,
        key: item.id,
      }))
      state.metaHistoryVideo = action.payload.meta
    },
    FETCH_HISTORY_COMMENT: (
      _,
      action: PayloadAction<IMonitoringHistoryParams>
    ) => {},
    SET_HISTORY_COMMENT: (
      state,
      action: PayloadAction<IMonitoringHistoryCommentResponse>
    ) => {
      state.listHistoryComment = action.payload.data.map(item => ({
        ...item,
        key: item.id,
      }))
      state.metaHistoryComment = action.payload.meta
    },
    FETCH_LIST_BOXES: (_, action: PayloadAction<IMonitoringBoxesParams>) => {},
    SET_LIST_BOXES: (state, action: PayloadAction<any>) => {
      const { meta, boxes_list } = action.payload
      state.listBoxes = boxes_list
      state.metaListBoxes = meta
    },
    FETCH_LIST_GROUP_1: () => {},
    SET_LIST_GROUP_1: (state, action: PayloadAction<any>) => {
      state.listGroup1 = action.payload
    },
    FETCH_LIST_MONITORING_GROUP_2: (_, action: PayloadAction<any>) => {},
    SET_LIST_GROUP_2: (state, action: PayloadAction<any>) => {
      state.listGroup2 = action.payload
    },
    FETCH_LIST_PREFECTURES: (_, action: PayloadAction<number[]>) => {},
    SET_LIST_PREFECTURES: (state, action: PayloadAction<any>) => {
      state.listPrefectures = action.payload
    },
    FETCH_HISTORY_PLAYBACK: (
      _,
      action: PayloadAction<IMonitoringHistoryParams>
    ) => {},
    SET_HISTORY_PLAYBACK: (
      state,
      action: PayloadAction<IMonitoringHistoryPlaybackResponse>
    ) => {
      const { videos, comments, boxName, start_time, end_time } = action.payload
      state.listHistoryPlayback.videos = videos
      state.listHistoryPlayback.comments = comments
      state.listHistoryPlayback.boxName = boxName
      state.listHistoryPlayback.start_time = start_time
      state.listHistoryPlayback.end_time = end_time
    },
    FETCH_COMMENT_BOX_DETAILS: (_, action: PayloadAction<number>) => {},
    SET_COMMENT_BOX_DETAILS: (
      state,
      action: PayloadAction<IMonitoringCommentBoxDetail[]>
    ) => {
      state.listCommentBoxDetail = action.payload
    },
    CLEAR_BOX_DETAIL_INFO: state => {
      state.boxDetails = {
        box_id: 0,
        box_name: '',
        status: 0,
        start_time: '',
        end_time: '',
        username: '',
        reservation_id: 0,
        service_box_id: null,
      }
    },
    FETCH_BOX_DETAILS: (_, action: PayloadAction<number>) => {},
    SET_BOX_DETAILS: (state, action: PayloadAction<IMonitoringBoxDetails>) => {
      state.boxDetails = action.payload
    },

    SET_PARAMS_BOX_LIST: (
      state,
      action: PayloadAction<IMonitoringBoxesParams>
    ) => {
      state.paramsBoxList = action.payload
    },
  },
})

// Actions
export const {
  FETCH_HISTORY_VIDEO,
  SET_HISTORY_VIDEO,
  FETCH_HISTORY_COMMENT,
  SET_HISTORY_COMMENT,
  FETCH_LIST_BOXES,
  SET_LIST_BOXES,
  FETCH_LIST_GROUP_1,
  SET_LIST_GROUP_1,
  FETCH_LIST_MONITORING_GROUP_2,
  SET_LIST_GROUP_2,
  FETCH_LIST_PREFECTURES,
  SET_LIST_PREFECTURES,
  CLEAR_BOX_DETAIL_INFO,
  FETCH_HISTORY_PLAYBACK,
  SET_HISTORY_PLAYBACK,
  FETCH_COMMENT_BOX_DETAILS,
  SET_COMMENT_BOX_DETAILS,
  FETCH_BOX_DETAILS,
  SET_BOX_DETAILS,
  SET_PARAMS_BOX_LIST,
} = monitoringSlice.actions

// Reducer
const monitoringReducer = monitoringSlice.reducer

export default monitoringReducer
