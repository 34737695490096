import '@/assets/styles/pages/monitoring-history.scss'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Box, Stack, Button, TextField } from '@mui/material'
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { useTranslation } from 'react-i18next'
import { IMonitoringHistoryParams, IMonitoringHistoryVideo } from '@/interfaces'
import { ColumnsType } from 'antd/lib/table/interface'
import Table from 'antd/es/table'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { FETCH_HISTORY_VIDEO } from '@/redux/reducers/monitoring.slice'
import dayjs from 'dayjs'
import { convertTimeToJapan, showTotalPaginationTable } from '@/utils'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import {
  DATE_PICKER_FORMAT,
  DATE_PICKER_INPUT_YMD,
  DEFAULT_LANGUAGE,
  ROUTER_PATH,
} from '@/constants'
import { enUS, ja } from 'date-fns/locale'

// Component Tab Video
function TabVideo() {
  const dispatch = useAppDispatch()

  const params = useParams()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const [startTime, setStartTime] = useState<Date | null>(null)

  const [endTime, setEndTime] = useState<Date | null>(null)

  const { languageMaster } = useAppSelector(state => state.app)

  const [queryParams, setQueryParams] = useState<IMonitoringHistoryParams>({
    id: Number(params.id),
    date_from: '',
    date_to: '',
    page: 1,
    search: '',
  })

  const { listHistoryVideo, metaHistoryVideo } = useAppSelector(
    state => state.monitoring
  )

  // Update the params of url API
  const onSubmit = (data: any) => {
    setQueryParams({
      ...queryParams,
      ...data,
      search: data.search.trim(),
      date_from: dayjs(data.date_from).isValid()
        ? dayjs(data.date_from).format('YYYY-MM-DD')
        : '',
      date_to: dayjs(data.date_to).isValid()
        ? dayjs(data.date_to).format('YYYY-MM-DD')
        : '',
      page: 1,
    })
  }

  const defaultValues = {
    date_from: '',
    date_to: '',
    search: '',
  }

  const { handleSubmit, control } = useForm({
    defaultValues,
  })

  useEffect(() => {
    dispatch(FETCH_HISTORY_VIDEO(queryParams))
  }, [queryParams])

  const columns: ColumnsType<IMonitoringHistoryVideo> = [
    {
      title: t('monitoring_history.date_time'),
      key: 'date',
      align: 'center',
      render: (_, record) =>
        `${convertTimeToJapan(record.start_time || '').format(
          'HH:mm'
        )}~${convertTimeToJapan(record.end_time || '').format(
          'HH:mm'
        )} ${convertTimeToJapan(record.start_time || '').format('YYYY/MM/DD')}`,
      width: '25%',
      ellipsis: true,
    },
    {
      title: t('monitoring_history.video'),
      key: 'video',
      render: (_, record) => (
        <Stack justifyContent="center" alignItems="center">
          <video width={75} height={75} style={{ objectFit: 'cover' }}>
            <source src={record.url} type="video/mp4" />
            <track kind="captions" />
          </video>
        </Stack>
      ),
      align: 'center',
      width: '25%',
      ellipsis: true,
    },
    {
      title: t('monitoring_history.customer'),
      dataIndex: 'nickname',
      key: 'nickname',
      align: 'center',
      width: '50%',
      ellipsis: true,
    },
  ]

  const handlePagination = (pagination: any) => {
    setQueryParams({ ...queryParams, page: pagination.current })
  }

  // Call API get the recorded video, navigate to video history page
  const onClickRecordVideo = (record: IMonitoringHistoryVideo) => {
    navigate(
      generatePath(
        `${ROUTER_PATH.MONITORING_HISTORY_PLAYBACK}#${record.reservation_id}`,
        {
          id: record.box_id.toString(),
        }
      )
    )
  }

  return (
    <Stack direction="column" spacing={3} pl={3} pr={3}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={3} alignItems="center" p={1}>
          <Stack direction="row" spacing={3} width="100%">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
            >
              <Controller
                name="date_from"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={e => {
                      field.onChange(e)
                      setStartTime(e)
                    }}
                    label={t('monitoring_history.from_date')}
                    inputFormat={DATE_PICKER_FORMAT}
                    allowSameDateSelection
                    maxDate={endTime || new Date()}
                    renderInput={paramsInput => (
                      <TextField
                        {...paramsInput}
                        error={false}
                        onKeyDown={(e: any) => e.preventDefault()}
                      />
                    )}
                    mask={DATE_PICKER_INPUT_YMD}
                  />
                )}
              />
            </LocalizationProvider>
            <Box display="flex" alignItems="center">
              ~
            </Box>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
            >
              <Controller
                name="date_to"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    value={field.value}
                    onChange={e => {
                      field.onChange(e)
                      setEndTime(e)
                    }}
                    label={t('monitoring_history.to_date')}
                    allowSameDateSelection
                    inputFormat={DATE_PICKER_FORMAT}
                    minDate={startTime}
                    maxDate={new Date()}
                    renderInput={paramsInput => (
                      <TextField
                        {...paramsInput}
                        error={false}
                        onKeyDown={(e: any) => e.preventDefault()}
                      />
                    )}
                    mask={DATE_PICKER_INPUT_YMD}
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <Controller
            name="search"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label={t('monitoring_history.customer')}
                value={field.value}
                inputProps={{ maxLength: 25 }}
              />
            )}
          />
          <Box width="30%">
            <Button
              type="submit"
              variant="contained"
              sx={{ borderRadius: 20 }}
              fullWidth
            >
              {t('monitoring_history.search')}
            </Button>
          </Box>
        </Stack>
      </Box>
      <Table
        columns={columns}
        dataSource={listHistoryVideo}
        scroll={{ y: 360 }}
        onChange={handlePagination}
        locale={{
          emptyText: t('monitoring_history.not_found'),
          filterReset: t('common.reset'),
          filterConfirm: t('common.apply'),
          triggerAsc: t('common.sort_asc'),
          triggerDesc: t('common.sort_desc'),
          cancelSort: t('common.cancel_sort'),
        }}
        onRow={record => ({
          onClick: () => onClickRecordVideo(record),
        })}
        pagination={{
          current: queryParams.page,
          total: metaHistoryVideo.total,
          showSizeChanger: false,
          showTotal: () =>
            showTotalPaginationTable(
              metaHistoryVideo.per_page,
              metaHistoryVideo.current_page,
              listHistoryVideo.length,
              metaHistoryVideo.total
            ),
        }}
      />
    </Stack>
  )
}

export default React.memo(TabVideo)
