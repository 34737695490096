import { MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hooks'
import { IGeneralCommon } from '@/interfaces'
import { DEFAULT_LANGUAGE } from '@/constants'
import useMasterData from '@/hooks/useMasterData'
import { generateKey } from '@/utils'

// Component select
function SelectCommon({
  api,
  label,
  handleChange,
  defaultValueMaster,
  errMessage,
  isRequire,
  isDisable,
  isError,
}: IGeneralCommon) {
  const [valueSelected, setValueSelected] = useState<string>('')

  const [listSelect, setListSelect] = useState<Array<any>>([])

  const [language, setLanguage] = useState<string | null>('')

  const { languageMaster } = useAppSelector(state => state.app)

  const listData: Array<any> = useMasterData(api)

  useEffect(() => {
    setListSelect(listData)
  }, [listData])

  useEffect(() => {
    if (typeof defaultValueMaster === 'string') {
      setValueSelected(defaultValueMaster)
    }
  }, [defaultValueMaster])

  useEffect(() => {
    setLanguage(languageMaster)
  }, [languageMaster])

  const handleChangeSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement
    handleChange(value)
    setValueSelected(value as string)
  }

  return (
    <TextField
      value={valueSelected}
      onChange={handleChangeSelect}
      className={isRequire ? 'text-field-require' : ''}
      label={label}
      select
      disabled={isDisable}
      error={isRequire && valueSelected === '' && isError}
      helperText={
        isRequire && isError && valueSelected === '' ? errMessage : ''
      }
    >
      {listSelect.map((item, index) => (
        <MenuItem value={item.value} key={generateKey('listSelect', index)}>
          {language === DEFAULT_LANGUAGE ? item.title_jp : item.title_en}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default React.memo(SelectCommon)
