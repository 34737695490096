import { all, fork } from 'redux-saga/effects'
import authSaga from '@/redux/sagas/auth'
import profileSaga from '@/redux/sagas/profile'
import monitorSaga from '@/redux/sagas/monitor'
import monitoringSaga from '@/redux/sagas/monitoring'
import contactSaga from '@/redux/sagas/contact'
import mirrorManagementSage from '@/redux/sagas/mirror'

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(monitorSaga),
    fork(profileSaga),
    fork(monitoringSaga),
    fork(contactSaga),
    fork(mirrorManagementSage),
  ])
}
