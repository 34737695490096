import '@/assets/styles/app.scss'
import React from 'react'
import Routes from '@/routes'
import { store } from '@/redux/store'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import { HMSRoomProvider } from '@100mslive/react-sdk'
import { hmsActions, hmsNotifications, hmsStore } from './hms'

function App() {
  return (
    <Provider store={store}>
      <HMSRoomProvider
        isHMSStatsOn
        actions={hmsActions}
        store={hmsStore}
        notifications={hmsNotifications}
      >
        <SnackbarProvider
          maxSnack={1}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Routes />
        </SnackbarProvider>
      </HMSRoomProvider>
    </Provider>
  )
}

export default App
