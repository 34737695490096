import { IconButton, Paper, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { CallRounded, CallEndRounded } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

// Calling Tag
function CallingTag({
  callDetail,
  acceptIncomingCall,
  rejectIncomingCall,
  setIncomingCalls,
  currentUser,
  socket,
}: any) {
  useEffect(() => {
    // If no one accept call, auto reject call after 60s
    const autoRejectCall = setTimeout(() => {
      setIncomingCalls((prev: any) =>
        prev.filter((item: any) => item.mirrorCode !== callDetail.mirrorCode)
      )
      if (socket) {
        socket.emit('monitor_hang_customer', {
          mirrorCode: callDetail.mirrorCode,
          staffId: currentUser.id,
        })
      }
    }, 60000)
    return () => {
      clearTimeout(autoRejectCall)
    }
  }, [])

  const { t } = useTranslation()

  return (
    <Paper
      sx={{
        borderRadius: 2,
        px: 2,
        my: 1,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={6}
      >
        <Stack direction="row" spacing={1}>
          <Typography>{callDetail.userId || ''}</Typography>
          <Typography>{callDetail.companyCode}</Typography>
          <Typography>{t('video_call.incoming_call')}</Typography>
        </Stack>
        <Stack direction="row">
          <IconButton color="primary" onClick={() => acceptIncomingCall()}>
            <CallRounded />
          </IconButton>
          <IconButton color="secondary" onClick={() => rejectIncomingCall()}>
            <CallEndRounded />
          </IconButton>
        </Stack>
      </Stack>
    </Paper>
  )
}

export default React.memo(CallingTag)
