import { all, takeLatest, put, ForkEffect, call } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import {
  FETCH_BOX_DETAILS,
  FETCH_COMMENT_BOX_DETAILS,
  FETCH_HISTORY_COMMENT,
  FETCH_HISTORY_PLAYBACK,
  FETCH_HISTORY_VIDEO,
  FETCH_LIST_BOXES,
  FETCH_LIST_GROUP_1,
  FETCH_LIST_PREFECTURES,
  SET_BOX_DETAILS,
  SET_COMMENT_BOX_DETAILS,
  SET_HISTORY_COMMENT,
  SET_HISTORY_PLAYBACK,
  SET_HISTORY_VIDEO,
  SET_LIST_BOXES,
  SET_LIST_GROUP_1,
  SET_LIST_GROUP_2,
  SET_LIST_PREFECTURES,
  FETCH_LIST_MONITORING_GROUP_2,
} from '@/redux/reducers/monitoring.slice'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { handleError } from '@/utils'
import { IMonitoringBoxesParams, IMonitoringHistoryParams } from '@/interfaces'
import {
  apiGetListHistoryComment,
  apiGetListHistoryVideo,
  apiGetListVideo,
  apiGetListGroup1,
  apiGetListGroup2,
  apiGetListPrefecture,
  apiGetListHistoryPlayback,
  apiGetListCommentBoxDetail,
  apiGetBoxDetail,
} from '@/api/monitoring'

function* getListMonitoringHistoryVideo(
  action: PayloadAction<IMonitoringHistoryParams>
) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const { payload } = action
    const response: AxiosResponse = yield apiGetListHistoryVideo(payload)
    yield put(SET_HISTORY_VIDEO(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringHistoryComment(
  action: PayloadAction<IMonitoringHistoryParams>
) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const { payload } = action
    const response: AxiosResponse = yield apiGetListHistoryComment(payload)
    yield put(SET_HISTORY_COMMENT(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringBoxes(
  action: PayloadAction<IMonitoringBoxesParams>
) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())

    const response: AxiosResponse = yield call(apiGetListVideo, action.payload)
    yield put(SET_LIST_BOXES(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringHistoryPlayback(
  action: PayloadAction<IMonitoringHistoryParams>
) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const { payload } = action
    const response: AxiosResponse = yield call(
      apiGetListHistoryPlayback,
      payload
    )
    yield put(SET_HISTORY_PLAYBACK(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringCommentBoxDetails(action: PayloadAction<number>) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const { payload } = action
    const response: AxiosResponse = yield apiGetListCommentBoxDetail(payload)
    yield put(SET_COMMENT_BOX_DETAILS(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringGroup1() {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield call(apiGetListGroup1)
    yield put(SET_LIST_GROUP_1(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringGroup2(action: PayloadAction<number[]>) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield call(apiGetListGroup2, action.payload)
    yield put(SET_LIST_GROUP_2(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getListMonitoringPrefectures(action: PayloadAction<number[]>) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield call(
      apiGetListPrefecture,
      action.payload
    )
    yield put(SET_LIST_PREFECTURES(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

function* getBoxDetails(action: PayloadAction<number>) {
  try {
    yield put(SET_LOADING(true))
    yield put(RESET_MESSAGE())
    const response: AxiosResponse = yield call(apiGetBoxDetail, action.payload)
    yield put(SET_BOX_DETAILS(response.data))
  } catch (err) {
    yield put(SET_MESSAGE(handleError(err)))
  } finally {
    yield put(SET_LOADING(false))
  }
}

export default function* monitoringSaga() {
  const filteredSagas: ForkEffect[] = [
    takeLatest(FETCH_HISTORY_VIDEO, getListMonitoringHistoryVideo),
    takeLatest(FETCH_HISTORY_COMMENT, getListMonitoringHistoryComment),
    takeLatest(FETCH_LIST_BOXES, getListMonitoringBoxes),
    takeLatest(FETCH_LIST_GROUP_1, getListMonitoringGroup1),
    takeLatest(FETCH_LIST_MONITORING_GROUP_2, getListMonitoringGroup2),
    takeLatest(FETCH_LIST_PREFECTURES, getListMonitoringPrefectures),
    takeLatest(FETCH_HISTORY_PLAYBACK, getListMonitoringHistoryPlayback),
    takeLatest(FETCH_COMMENT_BOX_DETAILS, getListMonitoringCommentBoxDetails),
    takeLatest(FETCH_BOX_DETAILS, getBoxDetails),
  ]

  yield all(filteredSagas)
}
