// TODO: remove console.log
/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react'
import { Box, Stack } from '@mui/material'
import { CusTomHMSPeer } from '@/interfaces/hms'
import {
  selectAudioTrackByPeerID,
  selectIsPeerAudioEnabled,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'
import { MicOff, Mic } from '@mui/icons-material'

interface Props {
  participant: CusTomHMSPeer
  valueVolume: number
  index: number
  handleZoomVideo: (indexZoom: number) => void
}

// Component user's stream
function ParticipantStream({
  participant,
  valueVolume,
  index,
  handleZoomVideo,
}: Props) {
  const videoParticipant = useRef(null)
  const track = useHMSStore(selectAudioTrackByPeerID(participant.id))
  const isAudioEnabled = useHMSStore(selectIsPeerAudioEnabled(participant.id))
  const hmsActions = useHMSActions()

  // Create stream, play stream, listen event playing, stop, fail
  useEffect(() => {
    const video: any = videoParticipant.current
    if (video) {
      let child = video.lastElementChild
      while (child) {
        video.removeChild(child)
        child = video.lastElementChild
      }
    }
    if (participant && participant.videoTrack && video) {
      console.log('hms public call - stream - attachVideo')
      hmsActions.attachVideo(participant.videoTrack, video)
    }
  }, [participant])

  useEffect(() => {
    if (track) {
      hmsActions.setVolume(valueVolume, track.id)
    }
  }, [valueVolume])

  return (
    <Box
      position="relative"
      onClick={() => handleZoomVideo(index)}
      sx={participant.isZoom ? { display: 'none' } : {}}
    >
      <Box className="global-call-stream-participants">
        <video ref={videoParticipant} autoPlay muted playsInline />
      </Box>
      <Stack
        className="global-call-stream-participants-info"
        direction="row"
        width="100%"
        justifyContent="space-between"
      >
        <Box visibility="hidden">{participant.name}</Box>
        <Box>
          {!isAudioEnabled ? (
            <MicOff fontSize="small" />
          ) : (
            <Mic fontSize="small" />
          )}
        </Box>
      </Stack>
    </Box>
  )
}
export default React.memo(ParticipantStream)
