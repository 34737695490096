import '@/assets/styles/pages/Monitor/monitor.scss'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Table from 'antd/es/table'
import { ColumnsType } from 'antd/lib/table/interface'

import { useTranslation, Trans } from 'react-i18next'
import {
  Box,
  Grid,
  Stack,
  Typography,
  Paper,
  TextField,
  Button,
  IconButton,
  Avatar,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
} from '@mui/material'
import { Edit, Delete, WarningRounded } from '@mui/icons-material'
import { useNavigate, generatePath, Navigate } from 'react-router-dom'
import { ROUTER_PATH } from '@/constants'
import { IMonitorRecord } from '@/interfaces'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  FETCH_MONITOR_LIST,
  FETCH_AVAILABLE_INFO,
  FETCH_LOWER_ROLES,
  FETCH_AVAILABLE_GROUP_2,
} from '@/redux/reducers/monitor.slice'
import {
  SET_LOADING,
  RESET_MESSAGE,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { DEFAULT_LANGUAGE } from '@/constants/common'
import {
  getValueMasterData,
  showTotalPaginationTable,
  handleError,
  convertTimeToJapan,
} from '@/utils/helper'
import useMasterData from '@/hooks/useMasterData'
import ConfirmDialog from '@components/common/ConfirmDialog'
import { AxiosResponse } from 'axios'
import { apiDeleteMonitor, apiGetLowerGrade } from '@/api/monitor'

// Monitor List Page
function MonitorManagement() {
  const { currentUser } = useAppSelector(state => state.auth)
  if (currentUser.role === 4) {
    return <Navigate to={ROUTER_PATH.DASHBOARD} />
  }
  // Task #234289
  if (currentUser.isBox) {
    return (
      <Navigate
        to={generatePath(ROUTER_PATH.MONITORING_DETAILS, {
          id: `${currentUser.id}`,
        })}
      />
    )
  }
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { lowerRole } = useAppSelector(state => state.monitor.managementInfo)
  const { monitorList } = useAppSelector(state => state.monitor)
  const { languageMaster, isLoading } = useAppSelector(state => state.app)

  const roleList = getValueMasterData(useMasterData('monitor_role'))
  const [queryParamsRequest, setQueryParamsRequest] = useState<{
    all: string
    role: string
    group_1: any[]
    group_2: any[]
    sort: string
    page: string
    parent_id: string
  }>({
    all: '',
    role: '',
    group_1: [],
    group_2: [],
    sort: monitorList?.created ? 'created_at:DESC' : '',
    page: '1',
    parent_id: '',
  })

  const dataSource = monitorList.data?.map(item => ({
    ...item,
    key: item.id,
    parent_name: item.parent?.name,
  }))

  const [toggleDialog, setToggleDialog] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string | undefined>('')
  const [lowerGrades, setLowerGrades] = useState<number>(0)

  const handleToggleDialog = () => setToggleDialog(!toggleDialog)

  // Call API to delete monitor
  const handleDelete = async () => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      await apiDeleteMonitor(deleteId?.toString())
      setToggleDialog(!toggleDialog)
      dispatch(FETCH_MONITOR_LIST({}))
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // Call API to get the lower grade of current user
  const fetchLowerGrade = async (id: string | undefined) => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      const lowerGradeRes: AxiosResponse = await apiGetLowerGrade(id)
      setLowerGrades(lowerGradeRes.data.meta.total)
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // Render the correct role depend on language
  const renderRoleList = (record: number) => {
    if (roleList) {
      return (
        <span>
          {roleList && languageMaster === DEFAULT_LANGUAGE
            ? roleList[record]?.title_jp
            : roleList[record]?.title_en}
        </span>
      )
    }
    return ''
  }

  const columns: ColumnsType<IMonitorRecord> = [
    {
      key: 'avatar',
      dataIndex: 'avatar',
      title: '',
      align: 'center',
      render: (_, record) => (
        <Avatar
          src={record.avatar || ''}
          sx={{ height: 60, width: 60, margin: 1 }}
        />
      ),
      width: '6%',
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: t('monitor_management.name'),
      width: '10%',
      ellipsis: true,
    },
    {
      key: 'address',
      dataIndex: 'address',
      title: t('monitor_management.address'),
      width: '20%',
      ellipsis: true,
    },
    {
      key: 'tel',
      dataIndex: 'tel',
      title: t('monitor_management.phoneNumber'),
      width: '10%',
      ellipsis: true,
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: t('monitor_management.email'),
      width: '20%',
      ellipsis: true,
    },
    {
      key: 'role',
      dataIndex: 'role',
      title: t('monitor_management.role'),
      render: record => renderRoleList(record),
      width: '8%',
      ellipsis: true,
    },
    {
      key: 'parent_name',
      dataIndex: 'parent_name',
      title: t('monitor_management.direct_manager'),
      width: '8%',
      ellipsis: true,
    },
    {
      key: 'created_at',
      dataIndex: 'created_at',
      title: t('monitor_management.registration_date'),
      sorter: true,
      render: record => (
        <span>{convertTimeToJapan(record).format('YYYY/MM/DD')}</span>
      ),
      width: '10%',
      ellipsis: true,
    },
    {
      title: t('monitor_management.actions'),
      width: '8%',
      render: record => (
        <Stack direction="row" p={0}>
          <IconButton
            onClick={() => {
              handleToggleDialog()
              setDeleteId(record.id)
              fetchLowerGrade(record.id)
            }}
          >
            <Delete className="icon" />
          </IconButton>
          <IconButton
            onClick={() => {
              navigate(
                generatePath(ROUTER_PATH.MONITOR_ACTION, {
                  id: record.id,
                  action: 'edit',
                })
              )
            }}
          >
            <Edit className="icon" />
          </IconButton>
        </Stack>
      ),
    },
  ]

  const defaultValues = {
    all: '',
    role: '',
    group1: [] as any[],
    group2: [] as any[],
    parent_id: '',
  }
  const { handleSubmit, control, watch, setValue } = useForm({
    defaultValues,
  })

  const availableGroup1 = watch('group1')

  const [availableGroup, setAvailableGroup] = React.useState<{
    group_1: any[]
    group_2: any[]
  }>({
    group_1: [],
    group_2: [],
  })

  // Handle change params of url API
  const filterDataRequest = (pagination: any, _: any, sorter: any) => {
    let queryParamsClone = { ...queryParamsRequest }
    queryParamsClone = { ...queryParamsClone, page: pagination.current }
    if (sorter.column) {
      if (sorter.order === 'ascend')
        queryParamsClone = {
          ...queryParamsClone,
          sort: `${sorter.column.key}:ASC`,
          page:
            queryParamsRequest.page === pagination.current
              ? '1'
              : pagination.current,
        }
      else
        queryParamsClone = {
          ...queryParamsClone,
          sort: `${sorter.column.key}:DESC`,
          page:
            queryParamsRequest.page === pagination.current
              ? '1'
              : pagination.current,
        }
    } else
      queryParamsClone = {
        ...queryParamsClone,
        sort: '',
        page:
          queryParamsRequest.page === pagination.current
            ? '1'
            : pagination.current,
      }
    setQueryParamsRequest(queryParamsClone)
  }

  // Save the params on submit event
  const onSubmit = (data: any) => {
    const { group1, group2, role, all, parent_id: parentId } = data
    setQueryParamsRequest({
      ...queryParamsRequest,
      all,
      role,
      group_1: group1,
      group_2: group2,
      parent_id: parentId,
      page: '1',
    })
  }

  useEffect(() => {
    setAvailableGroup({
      group_1: monitorList.availableInfo?.group1,
      group_2: monitorList.availableInfo?.group2,
    })
  }, [monitorList])

  useEffect(() => {
    dispatch(FETCH_MONITOR_LIST(queryParamsRequest))
  }, [queryParamsRequest])

  useEffect(() => {
    dispatch(FETCH_LOWER_ROLES())
    dispatch(FETCH_AVAILABLE_INFO())
  }, [])

  useEffect(() => {
    if (availableGroup1.length > 0) {
      dispatch(FETCH_AVAILABLE_GROUP_2(availableGroup1))
    } else {
      setValue('group2', [])
      dispatch(FETCH_AVAILABLE_GROUP_2([]))
    }
  }, [availableGroup1.length])

  return (
    <Box className="monitor-list-container" p={1}>
      <Typography variant="h6" mb={2}>
        {t('monitor_management.title')}
      </Typography>
      <Grid container direction="column" spacing={3}>
        <Grid container item spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Paper elevation={2} sx={{ borderRadius: '20px', width: '100%' }}>
              <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="row" spacing={4} p={2} alignItems="center">
                  <Controller
                    name="group1"
                    control={control}
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel id="group1">
                          {t('monitor_management.group_1')}
                        </InputLabel>
                        <Select
                          labelId="group1"
                          multiple
                          {...field}
                          renderValue={selected =>
                            availableGroup.group_1
                              ?.filter((group: any) =>
                                selected.includes(group.id)
                              )
                              ?.map((item: any) => item.name)
                              .join(', ')
                          }
                          MenuProps={{
                            PaperProps: {
                              style: {
                                width: 240,
                              },
                            },
                            variant: 'menu',
                          }}
                        >
                          {availableGroup.group_1?.map((group: any) => (
                            <MenuItem key={group.name} value={group.id}>
                              <Checkbox
                                checked={field.value.includes(group.id)}
                              />
                              <ListItemText primary={group.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="group2"
                    control={control}
                    render={({ field }) => (
                      <FormControl>
                        <InputLabel id="group2">
                          {t('monitor_management.group_2')}
                        </InputLabel>
                        <Select
                          labelId="group2"
                          multiple
                          {...field}
                          renderValue={selected =>
                            availableGroup.group_2
                              ?.filter((group: any) =>
                                selected.includes(group.id)
                              )
                              ?.map((item: any) => item.name)
                              .join(', ')
                          }
                          MenuProps={{
                            PaperProps: {
                              style: {
                                width: 240,
                              },
                            },
                            variant: 'menu',
                          }}
                        >
                          {availableGroup.group_2?.map((group: any) => (
                            <MenuItem key={group.name} value={group.id}>
                              <Checkbox
                                checked={field.value.includes(group.id)}
                              />
                              <ListItemText primary={group.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label={t('monitor_management.role')}
                      >
                        {lowerRole.map(role => (
                          <MenuItem key={role.id} value={role.value}>
                            {languageMaster === DEFAULT_LANGUAGE
                              ? role.title_jp
                              : role.title_en}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    name="parent_id"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        select
                        label={t('monitor_management.direct_manager')}
                      >
                        <MenuItem value={0}>
                          {t('monitor_management.none')}
                        </MenuItem>
                        {monitorList.availableInfo.directManager.map(
                          manager => (
                            <MenuItem key={manager.id} value={manager.id}>
                              {manager.name}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    )}
                  />
                  <Controller
                    name="all"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={t('monitor_management.all')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Button variant="contained" type="submit" size="small">
                    {t('monitor_management.search_button')}
                  </Button>
                </Stack>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => navigate(ROUTER_PATH.MONITOR_CREATE)}>
              <Avatar
                sx={{
                  height: '100px',
                  width: '100px',
                  p: 1,
                  backgroundColor: '#3399BD',
                }}
              >
                <Typography variant="body1" fontWeight="bold" color="white">
                  {t('monitor_management.add_monitor')}
                </Typography>
              </Avatar>
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          <Table
            loading={isLoading}
            onChange={filterDataRequest}
            columns={columns}
            dataSource={dataSource}
            onRow={record => ({
              onDoubleClick: () => {
                navigate(
                  generatePath(ROUTER_PATH.MONITOR, {
                    id: record.id?.toString(),
                  })
                )
              },
            })}
            locale={{
              emptyText: t('monitoring_history.not_found'),
              filterReset: t('common.reset'),
              filterConfirm: t('common.apply'),
              triggerAsc: t('common.sort_asc'),
              triggerDesc: t('common.sort_desc'),
              cancelSort: t('common.cancel_sort'),
            }}
            pagination={{
              total: monitorList.meta.total,
              current: monitorList.meta ? monitorList.meta?.current_page : 1,
              showSizeChanger: false,
              showTotal: () =>
                showTotalPaginationTable(
                  monitorList.meta.per_page,
                  monitorList.meta.current_page,
                  monitorList.data.length,
                  monitorList.meta.total
                ),
            }}
          />
        </Grid>
        <ConfirmDialog
          dialogTitle={t('monitoring.monitor_detail.delete_monitor')}
          dialogContent={
            <Trans
              parent="span"
              i18nKey="monitoring.monitor_detail.delete_description"
            />
          }
          openDialog={toggleDialog}
          setOpenDialog={handleToggleDialog}
          onConfirmDialog={handleDelete}
          okText={t('monitoring.monitor_detail.btn_delete_monitor')}
          cancelText={t('confirm.cancel')}
          lowerGradeText={
            <Trans
              parent="span"
              i18nKey="monitoring.monitor_detail.pre_delete_description"
            />
          }
          lowerGrade={lowerGrades}
          icon={<WarningRounded className="warning-icon" />}
        />
      </Grid>
    </Box>
  )
}

export default React.memo(MonitorManagement)
