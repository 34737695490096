import { apiSaveSettingDeviceBoxDetail } from '@/api/monitoring'
import { DEFAULT_LANGUAGE } from '@/constants'
import useMasterData from '@/hooks/useMasterData'
import { IListDevice } from '@/interfaces'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { RESET_MESSAGE, SET_MESSAGE } from '@/redux/reducers/app.slice'
import { generateKey, getValueMasterDataString, handleError } from '@/utils'
import { Paper, Switch, Stack, Typography, Box } from '@mui/material'
import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import AirConditional from '../Equipment/AirConditional'
import Heaters from '../Equipment/Heaters'

interface IControlPanelProps {
  isInterval: boolean
  setIsOpenComment: () => void
  listDevice: IListDevice[]
  getListDevice: () => void
  isUpdateStatus: boolean
  isUpdateStatusDevice: boolean
  setIsUpdateStatusDevice: (status: boolean) => void
}

const FLOOR_TEMP = 'FloorTemp'

const AIR_CON = 'AirCon'

// Component Control devices
function ControlPanel({
  isInterval,
  setIsOpenComment, // set open/close comment
  listDevice,
  getListDevice,
  isUpdateStatus,
  isUpdateStatusDevice,
  setIsUpdateStatusDevice,
}: IControlPanelProps) {
  const { t } = useTranslation()

  const [listDeviceProps, setListDeviceProps] = useState<IListDevice[]>([])

  const { languageMaster } = useAppSelector(state => state.app)

  const equipmentList = getValueMasterDataString(
    useMasterData('box_equipment_name')
  )

  const params = useParams()

  const dispatch = useAppDispatch()

  // Get the correct name of device depend on language
  const getNameEquipment = (flag: string | undefined = '') => {
    if (!equipmentList) return ''
    return languageMaster === DEFAULT_LANGUAGE
      ? equipmentList[flag]?.title_jp
      : equipmentList[flag]?.title_en
  }

  // Handle notice error of list devices
  const notificationDeviceError = (deviceError: any[]) => {
    const nameDeviceError = listDevice
      .filter(item =>
        deviceError.some((device: any) => {
          if (
            device.shop_equipment_detail_id &&
            item.shop_equipment_detail_id === device.shop_equipment_detail_id
          ) {
            return true
          }
          if (
            !device.shop_equipment_detail_id &&
            item.shop_equipment_id === device.shop_equipment_id
          ) {
            return true
          }
          return false
        })
      )
      .map((item: any) => getNameEquipment(item.icon))
      .join(', ')
    dispatch(
      SET_MESSAGE({
        type: 'error',
        content: t('monitoring.box_details.update_device_fail', {
          deviceName: nameDeviceError,
        }),
      })
    )
  }

  // Call API to save setting of list devices
  const onSaveSetting = async (data: IListDevice) => {
    try {
      const dataSubmit = JSON.parse(JSON.stringify(data))
      delete dataSubmit.icon
      setIsUpdateStatusDevice(true)
      dispatch(RESET_MESSAGE())
      const res: AxiosResponse = await apiSaveSettingDeviceBoxDetail(
        Number(params.id),
        {
          equipments: [dataSubmit],
        }
      )
      const deviceError = res.data.filter((item: any) => item.error)
      if (deviceError.length > 0) {
        notificationDeviceError(deviceError)
      }
    } catch (err) {
      dispatch(SET_MESSAGE(handleError(err)))
    } finally {
      getListDevice()
    }
  }

  // Handle change when click icon, save updated setting
  const handleChange = (icon: string) => {
    const listDeviceClone = [...listDeviceProps]
    const index = listDeviceClone.findIndex(item => item.icon === icon)
    if (index > -1) {
      if (listDeviceClone[index].state === 1) listDeviceClone[index].state = 0
      else listDeviceClone[index].state = 1
      setListDeviceProps(listDeviceClone)
      setIsOpenComment()
      onSaveSetting(listDeviceClone[index])
    } else {
      notificationDeviceError(getNameEquipment(icon))
    }
  }

  useEffect(() => {
    if (listDevice.length > 0) {
      setListDeviceProps(listDevice)
    }
  }, [listDevice])

  return (
    <Box display="flex" justifyContent="center" className="control-panel">
      <Paper
        elevation={3}
        sx={{ width: '100%', height: 'fit-content' }}
        component="form"
      >
        <Typography className="control-panel-title">
          {t('monitoring.box_details.facility_setting.title')}
        </Typography>
        {/* Task id #233389 */}
        <Box maxHeight={500} sx={{ overflowY: 'auto' }}>
          <Stack direction="column" spacing={2} p={3}>
            {listDeviceProps
              .filter(item => item.icon !== AIR_CON && item.icon !== FLOOR_TEMP)
              .map((item, index) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  key={generateKey('device', index)}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box className={`device-icon device-icon-${item.icon}`} />
                    <Typography>{getNameEquipment(item.icon)}</Typography>
                  </Stack>
                  <Switch
                    onChange={() => handleChange(item.icon ?? '')}
                    disabled={isUpdateStatusDevice}
                    checked={item.state === 1}
                    id="light"
                    className="control-panel-switch"
                  />
                </Stack>
              ))}
          </Stack>
          {listDeviceProps
            .filter(item => item.icon === AIR_CON)
            .map(item => (
              <AirConditional
                key={item.shop_equipment_id}
                isUpdateStatus={isUpdateStatus}
                setIsUpdateStatusDevice={setIsUpdateStatusDevice}
                isUpdateStatusDevice={isUpdateStatusDevice}
                airConditionalProps={item}
                isInterval={isInterval}
                id={params.id ?? ''}
              />
            ))}
          {listDeviceProps
            .filter(item => item.icon === FLOOR_TEMP)
            .map(item => (
              <Heaters
                key={item.shop_equipment_id}
                isUpdateStatus={isUpdateStatus}
                setIsUpdateStatusDevice={setIsUpdateStatusDevice}
                isUpdateStatusDevice={isUpdateStatusDevice}
                heater={item}
                isInterval={isInterval}
              />
            ))}
        </Box>
      </Paper>
    </Box>
  )
}

export default React.memo(ControlPanel)
