import React from 'react'

export interface FirebasePagination {
  _page: number
  _limit: number
  _total: number
}
export interface PaginationTableProps {
  data: any[]
}
export interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void
}
export interface TabPanelProps {
  index: number
  value: number
  children: React.ReactNode
}

export interface IGeneralCommon {
  api: string
  label: string
  handleChange: (data: any) => void
  isVertical?: boolean
  defaultValueMaster?: string | Array<any>
  isDisabled?: boolean
  errMessage?: string
  isRequire?: boolean
  isDisable?: boolean
  isError?: boolean
}

export interface ConfirmDialogProps {
  dialogTitle?: string | undefined
  dialogContent: any
  openDialog: boolean
  setOpenDialog(state: boolean): void
  onConfirmDialog(value: any): void
  okText?: string
  cancelText?: string
  lowerGradeText?: any
  lowerGrade?: number
  icon?: React.ReactElement
}

export interface ConfirmDeleteDialogProps {
  openDialog: boolean
  setOpenDialog(state: boolean): void
  onConfirmDialog?(): void
  onAsyncConfirm?(): Promise<void>
}

export enum BOX_STATUS {
  USING = 1,
  OPEN,
  REPAIRING,
}

export enum ROLES {
  BY_ADMIN = 1,
  ADMIN,
  MANAGER,
  MONITOR,
}
