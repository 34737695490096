import { apiChangeHeatersSetting, apiGetHeatersState } from '@/api/monitoring'
import { DEFAULT_LANGUAGE } from '@/constants'
import useMasterData from '@/hooks/useMasterData'
import { IListDevice } from '@/interfaces'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { getValueMasterDataString, handleError } from '@/utils'
import { Add, Remove } from '@mui/icons-material'
import {
  Divider,
  IconButton,
  Stack,
  Box,
  Switch,
  Typography,
} from '@mui/material'
import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const CONVERT_TO_CELSIUS = 10

interface IHeaters {
  isUpdateStatus: boolean
  isUpdateStatusDevice: boolean
  setIsUpdateStatusDevice: (status: boolean) => void
  heater: IListDevice
  isInterval: boolean
}

const FLOOR_HEAT = 'FloorHeat'

// Component Heaters
function Heaters({
  isUpdateStatus,
  isUpdateStatusDevice,
  setIsUpdateStatusDevice,
  heater,
  isInterval,
}: IHeaters) {
  const { t } = useTranslation()

  const { languageMaster } = useAppSelector(state => state.app)

  const dispatch = useAppDispatch()

  const equipmentList = getValueMasterDataString(
    useMasterData('box_equipment_name')
  )
  // Ticket Id #232909
  const FLOOR_HEATING_DEFAULT_TEMP = Number(
    heater.config?.floorHeatingDefaultTemp
  )

  const FLOOR_HEATING_MAX_TEMP = Number(heater.config?.floorHeatingMaxTemp)

  const FLOOR_HEATING_MIN_TEMP = Number(heater.config?.floorHeatingMinTemp)

  const [heaters, setHeaters] = useState<{
    temperature: number
    isTurnOn: boolean
  }>({
    temperature: FLOOR_HEATING_DEFAULT_TEMP,
    isTurnOn: false,
  })

  // Call API get the current setting of heaters
  const onGetSettingHeaters = async () => {
    try {
      if (!isUpdateStatus) {
        dispatch(SET_LOADING(true))
        dispatch(RESET_MESSAGE())
      }
      const res: AxiosResponse = await apiGetHeatersState(
        heater.shop_equipment_id
      )
      setHeaters({
        temperature: res.data.device_data.switch
          ? res.data.device_data.temp_set / CONVERT_TO_CELSIUS
          : FLOOR_HEATING_DEFAULT_TEMP,
        isTurnOn: res.data.device_data.switch,
      })
    } catch (err) {
      if (!isUpdateStatus) {
        dispatch(SET_MESSAGE(handleError(err)))
      }
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // Get the correct name of device depend on language
  const getNameEquipment = (flag: string | undefined = '') => {
    if (!equipmentList) return ''
    return languageMaster === DEFAULT_LANGUAGE
      ? equipmentList[flag]?.title_jp
      : equipmentList[flag]?.title_en
  }

  // Call API to save
  const onSaveSettingHeaters = async (
    power: boolean,
    temp: number = FLOOR_HEATING_DEFAULT_TEMP
  ) => {
    const data: any = { power }
    if (power) {
      data.temp = temp
    }
    setIsUpdateStatusDevice(true)
    try {
      dispatch(RESET_MESSAGE())
      const res: AxiosResponse = await apiChangeHeatersSetting(
        heater.shop_equipment_id,
        data
      )
      if (res.data.is_failed) {
        dispatch(
          SET_MESSAGE({
            type: 'error',
            content: t('monitoring.box_details.update_device_fail', {
              deviceName: getNameEquipment(FLOOR_HEAT),
            }),
          })
        )
      } else {
        setHeaters({
          temperature: data.power ? data.temp : FLOOR_HEATING_DEFAULT_TEMP,
          isTurnOn: data.power,
        })
      }
    } catch (err) {
      dispatch(SET_MESSAGE(handleError(err)))
    } finally {
      setIsUpdateStatusDevice(false)
    }
  }

  const isDisableTempDecrease =
    !heaters.isTurnOn ||
    isUpdateStatusDevice ||
    heaters.temperature === FLOOR_HEATING_MIN_TEMP

  const isDisableTempIncrease =
    !heaters.isTurnOn ||
    isUpdateStatusDevice ||
    heaters.temperature === FLOOR_HEATING_MAX_TEMP

  useEffect(() => {
    if (!isInterval) {
      onGetSettingHeaters()
    }
  }, [heater])

  return (
    <>
      <Divider />
      <Stack direction="column" spacing={2} p={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Box className="device-icon device-icon-Heaters" />
            <Typography>{getNameEquipment(FLOOR_HEAT)}</Typography>
          </Stack>
          <Switch
            onChange={() => onSaveSettingHeaters(!heaters.isTurnOn)}
            checked={heaters.isTurnOn}
            id="light"
            className="control-panel-switch"
            disabled={isUpdateStatusDevice}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Box className="device-icon device-icon-Temperature" />
            <Typography>
              {t(
                'monitoring.box_details.facility_setting.air_conditioner.temperature'
              )}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <IconButton
              disabled={isDisableTempDecrease}
              onClick={() =>
                onSaveSettingHeaters(
                  true,
                  heaters.temperature > FLOOR_HEATING_MIN_TEMP
                    ? heaters.temperature - 1
                    : FLOOR_HEATING_MIN_TEMP
                )
              }
            >
              <Remove />
            </IconButton>
            <Typography>{heaters.temperature}</Typography>
            <IconButton
              disabled={isDisableTempIncrease}
              onClick={() =>
                onSaveSettingHeaters(
                  true,
                  heaters.temperature < FLOOR_HEATING_MAX_TEMP
                    ? heaters.temperature + 1
                    : FLOOR_HEATING_MAX_TEMP
                )
              }
            >
              <Add />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default React.memo(Heaters)
