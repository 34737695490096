import { HMSReactiveStore } from '@100mslive/react-sdk'

const hms = new HMSReactiveStore()
hms.triggerOnSubscribe()

const hmsActions = hms.getActions()
const hmsStore = hms.getStore()
const hmsNotifications = hms.getNotifications()
// const hmsStats = hms.getStats()

export { hmsActions, hmsStore, hmsNotifications }
