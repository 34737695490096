import '@/assets/styles/pages/Monitor/GroupSelect.scss'
import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import {
  useParams,
  useNavigate,
  generatePath,
  Navigate,
} from 'react-router-dom'

import { ROUTER_PATH } from '@/constants/router'
import {
  Container,
  Box,
  Paper,
  Stack,
  Grid,
  Typography,
  Divider,
  TextField,
  Button,
} from '@mui/material'
import UploadImage from '@components/common/UploadImage'
import { FormType } from '@/interfaces'
import {
  FETCH_PROFILE_DETAIL,
  SET_PROFILE_DETAIL,
} from '@/redux/reducers/profile.slice'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import SelectCommon from '@/components/common/SelectCommon'
import { renderLabelFormType, handleError } from '@/utils/helper'
import {
  DATE_PICKER_FORMAT,
  DATE_PICKER_INPUT_YMD,
  DAYJS_FORMAT,
  DEFAULT_LANGUAGE,
  EMAIL_REGEX,
  PHONE_REGEX,
  DUMMY_PROFILE,
} from '@/constants'
import dayjs from 'dayjs'
import {
  RESET_MESSAGE,
  SET_LOADING,
  SET_MESSAGE,
} from '@/redux/reducers/app.slice'
import { apiEditProfileDetail } from '@/api/profile'
import { AUTH_GET_INFO } from '@/redux/reducers/users/auth.slice'
import { enUS, ja } from 'date-fns/locale'

// Profile Page
function Profile() {
  // Task #234289
  const { currentUser } = useAppSelector(state => state.auth)
  if (currentUser.isBox) {
    return (
      <Navigate
        to={generatePath(ROUTER_PATH.MONITORING_DETAILS, {
          id: `${currentUser.id}`,
        })}
      />
    )
  }
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const params = useParams()
  const [formType, setFormType] = useState<FormType>('view')
  const { profile } = useAppSelector(state => state.profile)
  const { languageMaster } = useAppSelector(state => state.app)

  const Schema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(t('validation.username_is_required'))
      .min(1, t('validation.username_valid'))
      .max(25, t('validation.username_invalid_max')),
    birthday: Yup.string().required(t('validation.birthday_required')),
    sex: Yup.string().required(t('validation.sex_required')),
    address: Yup.string()
      .trim()
      .max(255, t('validation.address_max'))
      .required(t('validation.address_required')),
    tel: Yup.string()
      .max(25, t('validation.valid_max_25'))
      .required(t('validation.phone_number_required'))
      .matches(PHONE_REGEX, t('validation.phone_number_format')),
    email: Yup.string()
      .trim()
      .max(100, t('validation.email_valid_max'))
      .required(t('validation.email_required'))
      .matches(EMAIL_REGEX, t('validation.email_invalid')),
    memo: Yup.string().trim().max(500, t('validation.description_invalid')),
  })
  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: profile,
    mode: 'all',
  })

  // Navigate after submit form
  const handleNavigate = () => {
    if (formType === 'view') {
      navigate(
        generatePath(ROUTER_PATH.PROFILE_EDIT, {
          action: 'edit',
        })
      )
    } else {
      navigate(ROUTER_PATH.PROFILE)
    }
  }

  // Call API to edit profile data
  const handleSubmitData = async (data: any) => {
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      await apiEditProfileDetail(data)
      handleNavigate()
      dispatch(FETCH_PROFILE_DETAIL())
      dispatch(AUTH_GET_INFO())
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // Catch submit data
  const onSubmit = (data: any) => {
    const dataSubmit = {
      name: data.name,
      birthday: dayjs(data.birthday).format(DAYJS_FORMAT),
      sex: parseInt(data.sex, 10),
      address: data.address,
      tel: data.tel,
      email: data.email,
      avatar_id: parseInt(data.upload_id, 10),
      memo: data.memo,
      old_updated_at: data.updated_at,
    }
    handleSubmitData(dataSubmit)
  }

  const isBlank = profile.group_box1.length <= 0 || profile.parent_id === 0

  useEffect(() => {
    dispatch(FETCH_PROFILE_DETAIL())
    return () => {
      dispatch(SET_PROFILE_DETAIL(DUMMY_PROFILE))
    }
  }, [])

  useEffect(() => {
    reset(profile)
  }, [profile, formType])

  useEffect(() => {
    if (params.action === 'edit') setFormType('edit')
    else setFormType('view')
  }, [params])

  return (
    <>
      <Typography mb={1} variant="h6" fontWeight="bold">
        {formType === 'view' && t('profile.title_view')}
        {formType === 'edit' && t('profile.title_edit')}
      </Typography>
      <Box component={Paper} pt={2} pb={1} className="monitor-container">
        <Container fixed>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            {formType !== 'view' && (
              <Typography sx={{ ml: 2, mb: 2, fontWeight: 'bold' }}>
                {t('monitoring.monitor_detail.please_enter')}
              </Typography>
            )}
            <Grid container spacing={10}>
              <Grid item xs={7}>
                <Stack direction="column" spacing={2}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="name"
                        disabled={formType === 'view'}
                        label={renderLabelFormType(t('profile.name'), formType)}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Stack direction="row" spacing={2}>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
                    >
                      <Controller
                        name="birthday"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            disabled={formType === 'view'}
                            views={['year', 'month', 'day']}
                            label={renderLabelFormType(
                              t('profile.birthday'),
                              formType
                            )}
                            allowSameDateSelection
                            openTo="year"
                            maxDate={new Date()}
                            value={field.value}
                            onChange={newValue => {
                              if (newValue === null) field.onChange(field.value)
                              else field.onChange(newValue)
                            }}
                            renderInput={param => (
                              <TextField
                                id="birthday"
                                {...param}
                                onKeyDown={(e: any) => e.preventDefault()}
                              />
                            )}
                            inputFormat={DATE_PICKER_FORMAT}
                            mask={DATE_PICKER_INPUT_YMD}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <Controller
                      name="sex"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <SelectCommon
                          api="sex_type"
                          label={renderLabelFormType(
                            t('profile.gender'),
                            formType
                          )}
                          isDisable={formType === 'view'}
                          defaultValueMaster={field.value.toString()}
                          handleChange={field.onChange}
                          errMessage={error?.message}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="address"
                        disabled={formType === 'view'}
                        label={renderLabelFormType(
                          t('profile.address'),
                          formType
                        )}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="tel"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="tel"
                        disabled={formType === 'view'}
                        label={renderLabelFormType(
                          t('profile.phoneNumber'),
                          formType
                        )}
                        error={!!error}
                        helperText={error?.message}
                        type="number"
                        className="input-number-no-arrow"
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        id="email"
                        disabled={formType === 'view'}
                        label={renderLabelFormType(
                          t('profile.email'),
                          formType
                        )}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="role"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <SelectCommon
                        api="monitor_role"
                        label={renderLabelFormType(t('profile.role'), formType)}
                        isDisable
                        defaultValueMaster={field.value.toString()}
                        handleChange={field.onChange}
                        errMessage={error?.message}
                      />
                    )}
                  />
                  {profile.role !== 1 && (
                    <Box sx={{ display: !isBlank ? 'block' : 'none' }}>
                      <Controller
                        name="parent_name"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            id="parent_name"
                            disabled={formType !== 'create'}
                            label={t('profile.administrator')}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    </Box>
                  )}
                  {profile.role !== 1 && (
                    <Stack direction="row" justifyContent="space-around">
                      <Typography variant="body1">
                        {t('profile.group_1')}
                      </Typography>
                      <Typography variant="body1">
                        {t('profile.group_2')}
                      </Typography>
                    </Stack>
                  )}
                  {profile.role !== 1 && profile.group_box1.length > 0 && (
                    <Box
                      component={Paper}
                      elevation={1}
                      sx={{ height: 190, overflow: 'auto' }}
                      p={2}
                      width="100%"
                    >
                      {profile.group_box1?.map(item => (
                        <Stack direction="column" key={item.id}>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              {item.name}
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              sx={{ paddingLeft: '40px !important' }}
                            >
                              {item.group_box2?.map((group2: any) => (
                                <span
                                  className="group-name"
                                  key={group2.id}
                                  style={{ width: 'unset' }}
                                >
                                  {group2.name}
                                </span>
                              ))}
                            </Grid>
                          </Grid>
                          <Divider />
                        </Stack>
                      ))}
                    </Box>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={5}>
                <Stack direction="column" spacing={3} width="100%">
                  {formType !== 'view' && (
                    <Typography sx={{ ml: 1, fontWeight: 'bold' }}>
                      {t('monitoring.monitor_detail.please_select')}
                    </Typography>
                  )}
                  <Stack direction="row" alignItems="start">
                    <Controller
                      name="upload_id"
                      control={control}
                      render={({ field }) => (
                        <UploadImage
                          idUpload="upload_id"
                          size={{ height: '178px', width: '255px' }}
                          isDelete={formType !== 'view'}
                          type="MONITOR_AVATAR"
                          formType={formType}
                          imageURL={formType !== 'create' ? profile.avatar : ''}
                          onChange={field.onChange}
                          maxSize={1.5}
                        />
                      )}
                    />
                  </Stack>
                  <Controller
                    name="memo"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        disabled={formType === 'view'}
                        value={field.value.length > 0 ? field.value : ''}
                        id="memo"
                        multiline
                        minRows={12}
                        label={t('monitoring.monitor_detail.detail')}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            {formType === 'view' && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={6}
                mt={2}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate(ROUTER_PATH.DASHBOARD)}
                >
                  {t('profile.back_btn')}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleNavigate}
                >
                  {t('profile.edit_btn')}
                </Button>
              </Stack>
            )}
            {formType !== 'view' && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={6}
                mt={2}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate(ROUTER_PATH.PROFILE)}
                >
                  {t('profile.back_btn')}
                </Button>
                <Button variant="contained" size="small" type="submit">
                  {t('profile.save_btn')}
                </Button>
              </Stack>
            )}
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default React.memo(Profile)
