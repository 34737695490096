// TODO: remove console.log
/* eslint-disable no-console */
import { apiRecordVideoMonitor, apiUpdateShopEquipmentMasterLink } from '@/api/monitoring'
import { ROOM_TYPE } from '@/constants'
import useHMSRoom from '@/hooks/useHMSRoom'
import { IListCamera, IListCameraRes } from '@/interfaces'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { RESET_MESSAGE, SET_MESSAGE } from '@/redux/reducers/app.slice'
import { handleError } from '@/utils'
import { useHMSActions } from '@100mslive/react-sdk'
import {
  CallEnd,
  NoPhotography,
  Videocam,
  VideocamOff,
  VolumeOff,
  VolumeUp,
} from '@mui/icons-material'
import { Box, Grid, IconButton, Paper, Stack, Switch } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ReactHlsPlayer from 'react-hls-player'
import { useTranslation } from 'react-i18next'

// Task id #233817

interface ICameraComponentProps {
  leaveCall: () => void
  isMicrophoneOn: boolean
  setIsMicrophoneOn: (data: boolean) => void
  isVideoOn: boolean
  setIsVideoOn: (data: boolean) => void
  listCamera: IListCameraRes[]
  camera: IListCamera[]
  setCamera: (camera: Array<IListCamera>) => void
  isDisconnected: boolean
  setLocalVideoEl: (video: HTMLElement) => void
  setLocalVideoTrackId: (localVideoTrackId: any) => void
  publicIp: string // 243413: publicIP from mirror
  roomCount: number
}

// Component Camera calling
function CameraComponent({
  leaveCall,
  isMicrophoneOn,
  setIsMicrophoneOn,
  isVideoOn,
  setIsVideoOn,
  listCamera,
  camera,
  setCamera,
  isDisconnected,
  setLocalVideoEl,
  setLocalVideoTrackId,
  publicIp, // 243413: publicIP from mirror
  roomCount,
}: ICameraComponentProps) {
  const dispatch = useAppDispatch()

  const { t } = useTranslation()

  // hsm
  const hmsActions = useHMSActions()

  const cameraWebcam1 = useRef<any>(null)
  const cameraWebcamZoom1 = useRef<any>(null)
  const cameraWebcam2 = useRef<any>(null)
  const cameraWebcamZoom2 = useRef<any>(null)

  const [streamLink1, setStreamLink1] = useState<any>('')
  const [streamLink2, setStreamLink2] = useState<any>('')

  const { isHaveCallPrivate } = useAppSelector(state => state.callVideo)
  const { currentUser } = useAppSelector(state => state.auth)
  console.log('hms private call - CameraCalling - state: ', {
    isHaveCallPrivate,
  })

  const { boxDetails } = useAppSelector(state => state.monitoring)

  const { remotePeers, localPeer, localVideoTrackId } = useHMSRoom(
    ROOM_TYPE.PRIVATE,
    !isMicrophoneOn,
    !isVideoOn
  )

  const videoContainer = useRef(null)

  const videoParticipant = useRef(null)

  const videoParticipantZoom = useRef(null)

  const [videoCall, setVideoCall] = useState({
    isZoom: false,
    isOn: true,
  })
  const [isRecord, setIsRecord] = useState<boolean>(false)

  useEffect(() => {
    if (listCamera.length) {
      listCamera.forEach(async (item, index) => {
        const { id, stream_link: streamLink } = item
        if (index === 0) {
          // 255353: show camera tuong ung voi tung room neu box co 2 room
          setStreamLink1(streamLink)
        }
        // neu box co 1 room thi se show 2 camera ip
        if (index === 1 && roomCount <= 1) {
          setStreamLink2(streamLink)
        }

        // 243413: update master_link
        if (publicIp !== '') {
          await apiUpdateShopEquipmentMasterLink(Number(id), {
            ip_address: publicIp,
          })
        }
      })
    }
  }, [listCamera])

  // TODO: record video
  const callApiRecordVideoMonitor = async () => {
    try {
      dispatch(RESET_MESSAGE())
      if (!isRecord) {
        await apiRecordVideoMonitor(Number(boxDetails.box_id), {
          reservation_id: boxDetails.reservation_id || 0,
          room_id: currentUser.private_room.room_id,
        })
        setIsRecord(true)
      }
    } catch (err) {
      dispatch(SET_MESSAGE(handleError(err)))
    }
  }

  useEffect(() => {
    if (localPeer && videoContainer.current) {
      if (localVideoTrackId) {
        hmsActions.attachVideo(localVideoTrackId, videoContainer.current)
        setLocalVideoEl(videoContainer.current)
        setLocalVideoTrackId(localVideoTrackId)
        callApiRecordVideoMonitor()
      }
      // else {
      //   alert(t('common.message_no_camera'))
      //   leaveCall()
      // }
    }
  }, [localPeer, isVideoOn])

  const handleZoomInCamera = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone.forEach((item, index) => {
      item.statusCamera.isZoom = indexCamera === index
    })
    setCamera(cameraClone)
    setVideoCall({ ...videoCall, isZoom: false })
  }

  const handleZoomOutCamera = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone[indexCamera].statusCamera.isZoom = false
    setCamera(cameraClone)
  }

  const toggleCameraOn = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone[indexCamera].statusCamera.status = true
    setCamera(cameraClone)
  }

  const toggleCameraOff = (indexCamera: number) => {
    const cameraClone = [...camera]
    cameraClone[indexCamera].statusCamera.status = false
    setCamera(cameraClone)
  }

  const checkIsCameraZoom = () =>
    camera.some(cameraItem => cameraItem.statusCamera.isZoom) ||
    videoCall.isZoom

  const handleLocalVideoOnOff = async (state: boolean) => {
    setIsVideoOn(state)
    await hmsActions.setLocalVideoEnabled(state)
  }

  useEffect(() => {
    const video: any = videoParticipant.current
    if (video) {
      let child = video.lastElementChild
      while (child) {
        video.removeChild(child)
        child = video.lastElementChild
      }
    }
    const videoZoom: any = videoParticipantZoom.current
    if (videoZoom) {
      let child = videoZoom.lastElementChild
      while (child) {
        videoZoom.removeChild(child)
        child = videoZoom.lastElementChild
      }
    }
    const peer = remotePeers.filter(
      item => item.roleName !== '__internal_recorder'
    )
    if (!peer.length || !peer[0] || !peer[0].videoTrack) return
    if (video) {
      hmsActions.attachVideo(peer[0].videoTrack, video)
    }
    if (videoZoom) {
      hmsActions.attachVideo(peer[0].videoTrack, videoZoom)
    }
  }, [remotePeers])

  useEffect(
    () => () => {
      // Task id #231767
      leaveCall()
    },
    []
  )

  useEffect(() => {
    if (isDisconnected) {
      leaveCall()
      dispatch(
        SET_MESSAGE({
          type: 'error',
          content: t('common.message_disconnect_call'),
        })
      )
    }
  }, [isDisconnected])

  useEffect(() => {
    const cameraClone = [...camera]
    cameraClone.forEach(item => {
      item.statusCamera.status = true
      item.statusCamera.isZoom = false
    })
    setCamera(cameraClone)
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={checkIsCameraZoom() ? 6 : 12}>
        <Grid container spacing={2}>
          {camera.map((item, index) => (
            <Grid
              item
              xs={checkIsCameraZoom() ? 6 : 4}
              sx={
                item.statusCamera.isZoom ? { display: 'none !important' } : {}
              }
              key={item.camera_id}
            >
              <Paper elevation={0} className="box-service-wrapper">
                {item.statusCamera.status ? (
                  <Box
                    className="box-service"
                    onClick={() => handleZoomInCamera(index)}
                  >
                    <ReactHlsPlayer
                      src={index === 0 ? streamLink1 : streamLink2}
                      playerRef={index === 0 ? cameraWebcam1 : cameraWebcam2}
                      width="100%"
                      autoPlay
                      height="auto"
                      muted
                    />
                    <Box className="btn-switch">
                      <Switch
                        className="box-service-switch"
                        checked={item.statusCamera.status}
                        onChange={() => toggleCameraOff(index)}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box className="box-service-no-camera">
                    <Box
                      className="icon-no-camera"
                      onClick={() => handleZoomInCamera(index)}
                    >
                      <NoPhotography />
                    </Box>
                    <Box className="btn-switch">
                      <Switch
                        className="box-service-switch"
                        checked={item.statusCamera.status}
                        onChange={() => toggleCameraOn(index)}
                      />
                    </Box>
                  </Box>
                )}
              </Paper>
            </Grid>
          ))}
          <Grid
            item
            xs={checkIsCameraZoom() ? 6 : 4}
            sx={videoCall.isZoom ? { display: 'none !important' } : {}}
          >
            <Paper elevation={0} className="box-service-wrapper">
              <Box
                className="box-service"
                onClick={() => {
                  handleZoomInCamera(-1)
                  setVideoCall({
                    ...videoCall,
                    isZoom: true,
                  })
                }}
                sx={
                  videoCall.isOn
                    ? {
                        padding: '0 !important',
                        backgroundImage: 'unset !important',
                      }
                    : {
                        padding: '0 !important',
                        backgroundImage: 'unset !important',
                        display: 'none !important',
                      }
                }
              >
                {isHaveCallPrivate && (
                  <div className="participant-webcam">
                    <video ref={videoParticipant} autoPlay muted playsInline />
                  </div>
                )}
              </Box>
              <Box className="btn-switch">
                <Switch
                  className="box-service-switch"
                  checked={videoCall.isOn}
                  onChange={() => {
                    setVideoCall({
                      ...videoCall,
                      isOn: !videoCall.isOn,
                    })
                  }}
                />
              </Box>
              <Box
                className="box-service-no-camera"
                sx={
                  videoCall.isOn
                    ? {
                        padding: '0 !important',
                        backgroundImage: 'unset !important',
                        display: 'none !important',
                      }
                    : {
                        padding: '0 !important',
                        backgroundImage: 'unset !important',
                      }
                }
              >
                <Box
                  className="icon-no-camera"
                  onClick={() => {
                    handleZoomInCamera(-1)
                    setVideoCall({
                      ...videoCall,
                      isZoom: true,
                    })
                  }}
                >
                  <NoPhotography />
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent={roomCount <= 1 ? 'center' : 'start'}
          >
            <Box className="video-call">
              {isHaveCallPrivate && (
                <div className="my-webcam">
                  <video ref={videoContainer} autoPlay muted playsInline />
                </div>
              )}
              <Stack direction="row" spacing={3} mb={3} position="absolute">
                {isVideoOn ? (
                  <IconButton
                    className="button-camera"
                    onClick={async () => {
                      await handleLocalVideoOnOff(false)
                    }}
                  >
                    <Videocam />
                  </IconButton>
                ) : (
                  <IconButton
                    className="button-camera"
                    onClick={async () => {
                      await handleLocalVideoOnOff(true)
                    }}
                  >
                    <VideocamOff />
                  </IconButton>
                )}

                {isMicrophoneOn ? (
                  <IconButton
                    className="button-volume"
                    onClick={() => {
                      setIsMicrophoneOn(false)
                      hmsActions.setLocalAudioEnabled(false)
                    }}
                  >
                    <VolumeUp />
                  </IconButton>
                ) : (
                  <IconButton
                    className="button-volume"
                    onClick={() => {
                      setIsMicrophoneOn(true)
                      hmsActions.setLocalAudioEnabled(true)
                    }}
                  >
                    <VolumeOff />
                  </IconButton>
                )}
                <IconButton
                  className="button-end-call"
                  onClick={() => leaveCall()}
                >
                  <CallEnd />
                </IconButton>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {camera.map((item, index) => (
        <Grid
          item
          xs={6}
          key={item.camera_id}
          sx={!item.statusCamera.isZoom ? { display: 'none !important' } : {}}
        >
          <Paper elevation={0} className="box-service-wrapper">
            {item.statusCamera.status ? (
              <Box
                className="box-service"
                onClick={() => handleZoomOutCamera(index)}
              >
                <ReactHlsPlayer
                  src={index === 0 ? streamLink1 : streamLink2}
                  playerRef={
                    index === 0 ? cameraWebcamZoom1 : cameraWebcamZoom2
                  }
                  width="100%"
                  autoPlay
                  height="auto"
                  muted
                />
                <Box className="btn-switch">
                  <Switch
                    className="box-service-switch"
                    checked={item.statusCamera.status}
                    onChange={() => toggleCameraOff(index)}
                  />
                </Box>
              </Box>
            ) : (
              <Box className="box-service-no-camera">
                <Box
                  className="icon-no-camera"
                  onClick={() => handleZoomOutCamera(index)}
                >
                  <NoPhotography />
                </Box>
                <Box className="btn-switch">
                  <Switch
                    className="box-service-switch"
                    checked={item.statusCamera.status}
                    onChange={() => toggleCameraOn(index)}
                  />
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>
      ))}
      <Grid
        item
        xs={6}
        sx={!videoCall.isZoom ? { display: 'none !important' } : {}}
      >
        <Paper elevation={0} className="box-service-wrapper">
          <Box
            className="box-service"
            onClick={() => {
              handleZoomInCamera(-1)
              setVideoCall({
                ...videoCall,
                isZoom: false,
              })
            }}
            sx={
              videoCall.isOn
                ? {
                    padding: '0 !important',
                    backgroundImage: 'unset !important',
                  }
                : {
                    padding: '0 !important',
                    backgroundImage: 'unset !important',
                    display: 'none !important',
                  }
            }
          >
            {isHaveCallPrivate && (
              <div className="participant-webcam-zoom">
                <video ref={videoParticipantZoom} autoPlay muted playsInline />
              </div>
            )}
          </Box>
          <Box className="btn-switch">
            <Switch
              className="box-service-switch"
              checked={videoCall.isOn}
              onChange={() => {
                setVideoCall({
                  ...videoCall,
                  isOn: !videoCall.isOn,
                })
              }}
            />
          </Box>
          <Box
            className="box-service-no-camera"
            sx={
              videoCall.isOn
                ? {
                    padding: '0 !important',
                    backgroundImage: 'unset !important',
                    display: 'none !important',
                  }
                : {
                    padding: '0 !important',
                    backgroundImage: 'unset !important',
                  }
            }
          >
            <Box
              className="icon-no-camera"
              onClick={() => {
                handleZoomInCamera(-1)
                setVideoCall({
                  ...videoCall,
                  isZoom: false,
                })
              }}
            >
              <NoPhotography />
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default React.memo(CameraComponent)
