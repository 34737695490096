import axios, {
  AxiosInstance,
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
} from 'axios'

import { getUserToken, removeUserToken } from '@/utils/helper'
import { ROUTER_PATH } from '@/constants'

const axiosClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const urlTokenExcludes: string[] = [
  'login',
  'register',
  'forgot-password',
  'public',
]

// config header
const defaultHeaders = {
  ...axiosClient.defaults.headers.common,
  'Content-Type': 'application/json; charset=UTF-8',
}
axiosClient.defaults.headers.common = defaultHeaders

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const urlParser = config.url?.split('/')
  if (urlParser === undefined) return config

  if (!urlTokenExcludes.includes(urlParser[1])) {
    // private api
    const token = getUserToken()
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` }
  }

  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> =>
  Promise.reject(error)

const onResponse = (response: AxiosResponse): AxiosResponse => ({
  ...response,
  data: response.data.data,
})

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  const token = getUserToken()
  if (error.response?.status === 401) {
    if (token) removeUserToken()
    window.location.replace(ROUTER_PATH.AUTH.SIGN_IN)
  }
  return Promise.reject(error)
}

axiosClient.interceptors.request.use(onRequest, onRequestError)
axiosClient.interceptors.response.use(onResponse, onResponseError)

export default axiosClient
