/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react'
import '@/assets/styles/pages/ReservationManagement.scss'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { Box, Grid, Stack, TextField, Typography, Button, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RESET_MESSAGE, SET_LOADING, SET_MESSAGE } from '@/redux/reducers/app.slice'
import { handleError } from '@/utils'
import { apiGetReservationList } from '@/api/reservation'
import Table from 'antd/es/table'
import { showTotalPaginationTable } from '@/utils/helper'
import { ColumnsType } from 'antd/lib/table/interface'
import { DATE_PICKER_INPUT_YMD, DEFAULT_LANGUAGE, DAYJS_FORMAT_NEW, DATE_PICKER_FORMAT } from '@/constants'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { enUS, ja } from 'date-fns/locale'
import dayjs from 'dayjs'
import useMasterData from '@/hooks/useMasterData'
import useSocket from '@/hooks/useSocket'
import ConfirmScanDialog from '@components/common/ConfirmScanDialog'
import CloseIcon from '@mui/icons-material/Close'

// Reservation List Page
function ReservationManagement() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { socket } = useSocket()

  const [toggleDialog, setToggleDialog] = useState<boolean>(false)
  const [dialogContent, setDialogContent] = useState<any>('')
  const handleToggleDialog = () => setToggleDialog(!toggleDialog)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const { languageMaster } = useAppSelector(state => state.app)
  const [reservationList, setReservationList] = useState<any[]>([])
  const [meta, setMeta] = useState<any>({})
  const [date, setDate] = React.useState<any>(dayjs());
  const [boxName, setBoxName] = React.useState<any>('');
  const [status, setStatus] = React.useState<any>('');
  const [isMentor, setIsMentor] = React.useState<any>('');
  const [record, setRecord] = React.useState<any>(null);

  const reservationStatus = useMasterData('reservation_status')
  const yesNoStatus = useMasterData('yes_no_status')

  const defaultSearch = {
    date,
    boxName: '',
    status: '',
    isMentor: '',
    pageNumber,
    pageSize
  }

  const getDeliverTime = () => {
    const date = new Date() // 获取一个时间对象
    const a = String(date.getFullYear()) // 获取完整的年份(4位,1970)
    let b = String(date.getMonth() + 1) // 获取月份(0-11,0代表1月,用的时候记得加上1)
    if (b.length === 1) {
      b = 0 + b
    }
    let c = String(date.getDate()) // 获取日(1-31)
    if (c.length === 1) {
      c = 0 + c
    }
    let d = String(date.getMilliseconds()) // 获取毫秒数)
    if (d.length === 1) {
      d = 0 + 0 + d
    }
    if (d.length === 2) {
      d = 0 + d
    }
    let e = String(date.getHours()) // 获取小时数(0-23)
    if (e.length === 1) {
      e = 0 + e
    }
    let f = String(date.getMinutes()) // 获取分钟数(0-59)
    if (f.length === 1) {
      f = 0 + f
    }
    let g = String(date.getSeconds()) // 获取秒数(0-59)
    if (g.length === 1) {
      g = 0 + g
    }
    const h = Number(a + b + c + e + f + g + d)
    return h
  }
  
  const fetchData = async (data: any) => {
    const dateStr = dayjs(data.date).isValid() ? dayjs(data.date).format(DAYJS_FORMAT_NEW) : ''
    try {
      dispatch(SET_LOADING(true))
      dispatch(RESET_MESSAGE())
      const getReservationList = async () => {
        const response = await apiGetReservationList({
          ...data,
          date: dateStr
        })
        setReservationList(response.data.data)
        setMeta(response.data.meta)
      }
      await getReservationList()
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  useEffect(() => {
    fetchData(defaultSearch)
  }, [])

  useEffect(() => {
    fetchData(defaultSearch)
  }, [pageNumber])

  const filterDataRequest = (pagination: any) => {
    setPageNumber(pagination.current)
    setPageSize(pagination.pageSize)
  }

  const onSearch = () => {
    fetchData({
      ...defaultSearch,
      date,
      boxName,
      status,
      isMentor
    })
  }

  const onScan = () => {
    if (socket) {
      const body = {
        companyCode: 'GIN',
        shopId: record.shop_id,
        mirrorCode: record.mirror_code,
        deliveredTime: getDeliverTime(),
        faceRecognitionFlag: record.register_flag,
        reservationTime: record.treatment_time,
        userId: record.user?.id,
        pack_info: record.pack_info,
        autoFaceFlag: record.auto_face,
        reservation_id: record.id,
        promotionList: [
          {
            imageFileName: '',
            imageUrl: null // TODO
          },
          {
            imageFileName: '',
            imageUrl: null // TODO
          },
        ],
        auth_image: record.auth_image,
        mentor_id: record.mentor_id,
        knife: record.knife,
        mentor_time: record.mentor_time,
      }
      socket.emit('mirror_wakeup_from_monitor', JSON.stringify(body))
      setToggleDialog(false)
      dispatch(SET_MESSAGE({
        type: 'success',
        content: t('reservation_management.scan_msg'),
      }))
    } else {
      dispatch(SET_MESSAGE({
        type: 'error',
        content: 'Socket connection error',
      }))
    }
  }

  const onClose = () => {
    if (socket) {
      const body = {
        companyCode: 'GIN',
        shopId: record.shop_id,
        mirrorCode: record.mirror_code,
        deliveredTime: getDeliverTime(),
        userId: record.user?.id,
        reservationId: record.id,
        eventInformation: [
          {
            eventPageName: '30_電話中',
            eventPageId: 'customer-support-chat',
            eventButtonId: 'hangup',
            eventType: '',
            eventUpdown: '',
            eventValue: '',
          },
        ],
        courseDetailSetting: [
          {
            courseCode: '',
            courseMode: '',
            courseValue: '',
            courseValueType: '',
          },
        ],
      }
      socket.emit('on_customize_course', JSON.stringify(body))
      setToggleDialog(false)
      dispatch(SET_MESSAGE({
        type: 'success',
        content: t('reservation_management.close_msg'),
      }))
    } else {
      dispatch(SET_MESSAGE({
        type: 'error',
        content: 'Socket connection error',
      }))
    }
  }

  const onConfirmDialog = () => {
    if (dialogContent === t('reservation_management.confirm_scan_msg')) {
      onScan()
    }
    if (dialogContent === t('reservation_management.confirm_close_msg')) {
      onClose()
    }
  }

  const columns: ColumnsType<any> = [
    {
      key: 'id',
      dataIndex: 'id',
      width: '10%',
      title: t('reservation_management.columns.id'),
      ellipsis: true,
    },
    {
      key: 'nickname',
      dataIndex: 'nickname',
      width: '30%',
      title: t('reservation_management.columns.nickname'),
      ellipsis: true,
      render: (_, record) => `${record.shop.id}, ${record.shop.nickname}`
    },
    {
      key: 'start_time',
      dataIndex: 'start_time',
      width: '15%',
      title: t('reservation_management.columns.start_time'),
      ellipsis: true,
      render: (_, record) => `${dayjs(record.start_time).format('YYYY/MM/DD HH:mm')}`
    },
    {
      key: 'status',
      dataIndex: 'status',
      width: '10%',
      title: t('reservation_management.columns.status'),
      ellipsis: true,
      render: (_, record) => {
        const row = reservationStatus.filter((r:any) => Number(r.value) === record.status)
        return `${
          languageMaster === DEFAULT_LANGUAGE
            ? row[0]?.title_jp
            : row[0]?.title_en
        }`
      }
    },
    {
      key: 'service_box_id',
      dataIndex: 'service_box_id',
      width: '11%',
      title: t('reservation_management.columns.is_mentor'),
      ellipsis: true,
      render: (_, record) => {
        const isMentor = record.service_box_id ? 1 : 0
        const row = yesNoStatus.filter((r:any) => Number(r.value) === isMentor)
        return `${
          languageMaster === DEFAULT_LANGUAGE
            ? row[0]?.title_jp
            : row[0]?.title_en
        }`
      }
    },
    {
      key: 'user',
      dataIndex: 'user',
      title: t('reservation_management.columns.account'),
      ellipsis: true,
      width: '15%',
      render: (_, record) => `${record.user.account}`
    },
    {
      key: 'user',
      dataIndex: 'user',
      title: t('reservation_management.columns.customer_name'),
      ellipsis: true,
      width: '30%',
      render: (_, record) => `${record.user.nickname}`
    },
    {
      key: 'room_num',
      dataIndex: 'room_num',
      title: t('reservation_management.columns.room_num'),
      ellipsis: true,
      width: '10%',
    },
    {
      title: t('monitor_management.actions'),
      width: '20%',
      render: record => (
        <Stack direction="row" p={0}>
          {record.is_allow_scan === 1 ? (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              sx={{
                marginRight: '15px'
              }}
              onClick={() => {
                setDialogContent(t('reservation_management.confirm_scan_msg'))
                setRecord(record)
                setToggleDialog(true)
              }}
            >
              {t('reservation_management.btn_scan')}
            </Button>) : null
          }
          {record.is_allow_close === 1 ? (
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => {
                setDialogContent(t('reservation_management.confirm_close_msg'))
                setRecord(record)
                setToggleDialog(true)
              }}
            >
              {t('reservation_management.btn_close')}
            </Button>) : null
          }
        </Stack>
      )
    }
  ]

  return (
    <Box className="reservation-list-container" p={1}>
      <Typography variant="h6" mb={2}>
        {t('reservation_management.title')}
      </Typography>
      <Stack direction="row" sx={{ marginBottom: '20px' }}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={DEFAULT_LANGUAGE === languageMaster ? ja : enUS}
        >
          <DatePicker
            value={date}
            label={t('reservation_management.date')}
            onChange={newValue => {
              setDate(newValue)
            }}
            inputFormat={DATE_PICKER_FORMAT}
            renderInput={params => (
              <TextField size="small" sx={{ width: '180px !important', marginRight: '20px' }} {...params} />
            )}
            mask={DATE_PICKER_INPUT_YMD}
          />
        </LocalizationProvider>
        <TextField
          sx={{
            maxWidth: 250,
            marginRight: '10px',
          }}
          size="small"
          onChange={e => {
            setBoxName(e.target.value)
          }}
          variant="outlined"
          label={t('reservation_management.search_box_name')}
        />
        <TextField
          sx={{
            maxWidth: 180,
            marginRight: '10px',
            '.MuiSelect-select': {
              paddingRight: '0 !important'
            }
          }}
          value={status}
          size="small"
          onChange={e => {
            setStatus(e.target.value)
          }}
          variant="outlined"
          label={t('reservation_management.status')}
          select
          InputProps={{
            endAdornment: (
              <Box
                tabIndex={0}
                role="button"
                style={{
                  marginRight: '20px',
                  marginTop: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => {setStatus('')}}
              >
                <CloseIcon />
              </Box>
            ),
          }}
        >
          {reservationStatus.map((item:any) => (
            <MenuItem
              className="status"
              key={item.value}
              value={item.value}
            >
              {DEFAULT_LANGUAGE === languageMaster ? item.title_jp : item.title_en}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={{
            maxWidth: 170,
            marginRight: '10px',
          }}
          size="small"
          value={isMentor}
          onChange={e => {
            setIsMentor(e.target.value)
          }}
          variant="outlined"
          label={t('reservation_management.is_mentor')}
          select
          InputProps={{
            endAdornment: (
              <Box
                tabIndex={0}
                role="button"
                style={{
                  marginRight: '20px',
                  marginTop: '5px',
                  cursor: 'pointer',
                }}
                onClick={() => {setIsMentor('')}}
              >
                <CloseIcon />
              </Box>
            ),
          }}
        >
          {yesNoStatus.map((item:any) => (
            <MenuItem
              className="isMentor"
              key={item.value}
              value={item.value}
            >
              {DEFAULT_LANGUAGE === languageMaster ? item.title_jp : item.title_en}
            </MenuItem>
          ))}
        </TextField>
        <Button
          variant="contained"
          type="button"
          size="small"
          onClick={onSearch}
        >
          {t('reservation_management.search_button')}
        </Button>
      </Stack>
      <Grid container direction="column" spacing={2}>
      <Grid item>
          <Table
            columns={columns}
            dataSource={reservationList}
            onChange={filterDataRequest}
            rowKey="id"
            locale={{
              emptyText: t('monitoring_history.not_found'),
            }}
            pagination={{
              pageSize,
              total: meta.total,
              current: meta ? meta?.current_page : 1,
              showSizeChanger: false,
              showTotal: () =>
                showTotalPaginationTable(
                  meta?.per_page,
                  meta?.current_page,
                  reservationList.length,
                  meta?.total
                ),
            }}
          />
        </Grid>
      </Grid>
      <ConfirmScanDialog
        dialogContent={dialogContent}
        openDialog={toggleDialog}
        setOpenDialog={handleToggleDialog}
        onConfirmDialog={onConfirmDialog}
        okText={t('common.ok')}
        cancelText={t('common.cancel')}
      />
    </Box>
  )
}

export default React.memo(ReservationManagement)
