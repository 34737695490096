// TODO: remove console.log
/* eslint-disable no-console */
import { apiGetAppToken } from '@/api/hms'
import { ROOM_TYPE } from '@/constants'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { SET_LOADING, SET_MESSAGE } from '@/redux/reducers/app.slice'
import { UPDATE_ROOM_STATUS } from '@/redux/reducers/callVideo.slice'
import { getEnv, handleError } from '@/utils'
import { HMSNoiseSuppressionPlugin } from '@100mslive/hms-noise-suppression'
import {
  selectLocalPeer,
  selectLocalVideoTrackID,
  selectPeers,
  selectRemotePeers,
  selectRoom,
  selectRoomState,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk'

import { useEffect, useState } from 'react'

const GLOBAL_MAX_CALL = Number(getEnv('GLOBAL_MAX_CALL'))

export default function useHMSRoom(
  roomType: number,
  isAudioMuted = false,
  isVideoMuted = false
) {
  // hms actions
  const hmsActions = useHMSActions()
  // hms state
  const roomState = useHMSStore(selectRoomState)
  const currentRoom = useHMSStore(selectRoom)
  const peers = useHMSStore(selectPeers)
  const localPeer = useHMSStore(selectLocalPeer)
  const localVideoTrackId = useHMSStore(selectLocalVideoTrackID)
  const remotePeers = useHMSStore(selectRemotePeers)
  const durationInMs = 80
  const noiseSuppressionPlugin = new HMSNoiseSuppressionPlugin(durationInMs)
  // local state
  const [roomInfo, setRoomInfo] = useState<any>(null)
  console.log('hms RoomInfo: ', roomInfo)
  const [appToken, setAppToken] = useState<string>('')

  const { currentUser } = useAppSelector(state => state.auth)
  const dispatch = useAppDispatch()

  const getAppToken = async () => {
    const mirrorCode = localStorage.getItem('mirrorCode') ?? '0'
    console.log('hms mirror code: ', mirrorCode)
    try {
      dispatch(SET_LOADING(true))
      const { data } = await apiGetAppToken(
        roomInfo.room_id,
        roomInfo.type,
        roomInfo.passcode,
        mirrorCode
      )
      setAppToken(data.token)
    } catch (err) {
      console.error('hms error: ', err)
    } finally {
      dispatch(SET_LOADING(false))
    }
  }

  // create new room
  useEffect(() => {
    if (!roomType && !currentUser.id) return

    if (roomType === ROOM_TYPE.PRIVATE) {
      setRoomInfo(currentUser.private_room)
    } else {
      setRoomInfo(currentUser.public_room)
    }
  }, [roomType])

  useEffect(() => {
    // get app token
    if (roomInfo) {
      getAppToken()
    }
  }, [roomInfo])
  // Noise suppression
  useEffect(() => {
    noiseSuppressionPlugin.init()
    try {
      noiseSuppressionPlugin.setEnabled(true) // true/false
      console.log('noise suppression enabled')
    } catch (error) {
      dispatch(SET_MESSAGE(handleError(error)))
    }
  }, [roomInfo])

  useEffect(() => {
    if (appToken) {
      const config = {
        userName: `mentor_${currentUser.name}`,
        authToken: appToken,
        settings: {
          isAudioMuted,
          isVideoMuted,
        },
      }
      hmsActions.join(config)
    }
  }, [appToken])

  useEffect(
    () => () => {
      hmsActions.leave()
    },
    []
  )

  useEffect(() => {
    dispatch(UPDATE_ROOM_STATUS(peers.length >= GLOBAL_MAX_CALL))
  }, [peers])

  return {
    roomState,
    currentRoom,
    peers,
    localPeer,
    localVideoTrackId,
    remotePeers,
  }
}
