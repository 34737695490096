import axiosClient from './axiosClient'

export const apiGetAppToken = (
  roomId: string,
  type: string,
  passcode: string,
  mirrorCode: string
) =>
  axiosClient.post('/hms/token', {
    room_id: roomId,
    type,
    passcode,
    mirror_code: mirrorCode,
  })
