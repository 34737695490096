import { ICallVideo } from '@/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: ICallVideo = {
  isHaveCall: false,
  isHaveCallPrivate: false,
  roomName: '',
  isCalling: false,
  isFull: false,
}

export const callVideoSlice = createSlice({
  name: 'callVideo',
  initialState,
  reducers: {
    PICKUP_A_CALL: (state, action: PayloadAction<string>) => {
      state.isHaveCallPrivate = true
      state.roomName = action.payload
    },
    LEAVE_CALL: state => {
      state.isHaveCallPrivate = false
    },
    HANDLE_CALL: (state, action: PayloadAction<boolean>) => {
      state.isCalling = action.payload
    },
    PICKUP_A_CALL_GLOBAL: state => {
      state.isHaveCall = true
    },
    LEAVE_CALL_GLOBAL: state => {
      state.isHaveCall = false
    },
    UPDATE_ROOM_STATUS: (state, action: PayloadAction<boolean>) => {
      state.isFull = action.payload
    },
  },
})

// Actions
export const {
  PICKUP_A_CALL,
  LEAVE_CALL,
  HANDLE_CALL,
  PICKUP_A_CALL_GLOBAL,
  LEAVE_CALL_GLOBAL,
  UPDATE_ROOM_STATUS,
} = callVideoSlice.actions

// Reducer
export default callVideoSlice.reducer
