import '@/assets/styles/layout/header.scss'
import React, { useState, useRef, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Divider,
  Button,
  Box,
  Badge,
  Typography,
  IconButton,
  Popover,
  Stack,
} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { ROUTER_PATH } from '@/constants'
import { useTranslation } from 'react-i18next'
import NotificationItem from '@components/common/NotificationItem'
import { useAppSelector } from '@/redux/hooks'

function Notifications() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  const { notice } = useAppSelector(state => state.contact)

  const renderNoticeItem = useMemo(
    () =>
      notice.map(notification => (
        <NotificationItem
          key={notification.id}
          notice={notification}
          setOpen={setOpen}
        />
      )),
    [notice]
  )
  const isRead = useMemo(
    () => notice.filter(item => item.seen === false).length === 0,
    [notice]
  )

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleViewAll = () => {
    setOpen(false)
    navigate(ROUTER_PATH.CONTACT)
  }

  return (
    <>
      <Box onClick={handleOpen}>
        <IconButton ref={ref}>
          <Badge invisible={isRead} badgeContent="" color="error" variant="dot">
            <NotificationsIcon fontSize="medium" />
          </Badge>
        </IconButton>
      </Box>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={ref.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mr: 2,
            overflow: 'hidden',
            maxHeight: 540,
            width: 400,
            border: '1px solid #3399BD',
            borderRadius: 4,
          },
        }}
      >
        <Stack justifyContent="center" alignItems="center" p={1}>
          <Typography fontSize={22} fontWeight="bold" className="notice-title">
            {t('header.notice_title')}
          </Typography>
        </Stack>
        <Divider />
        <Stack
          direction="column"
          divider={<Divider />}
          className="notice-items"
          sx={{ overflow: 'hidden', maxHeight: 438 }}
        >
          {renderNoticeItem}
        </Stack>

        <Divider />
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Button
            disableRipple
            sx={{
              fontWeight: 600,
              fontSize: 14,
              textTransform: 'none',
            }}
            onClick={handleViewAll}
          >
            {t('header.view_all')}
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default React.memo(Notifications)
