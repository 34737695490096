import React from 'react'
import { Box } from '@mui/material'
import { TabPanelProps } from '@/interfaces'

// Component Tab Panel
function TabPanel({ index, value, children }: TabPanelProps) {
  return <div>{value === index && <Box p={1}>{children}</Box>}</div>
}

export default React.memo(TabPanel)
