import '@/assets/styles/pages/monitoring.scss'
import React from 'react'
import { Box, Grid, Paper, Typography, Stack } from '@mui/material'
import { useAppSelector } from '@/redux/hooks'
import { useNavigate, generatePath } from 'react-router-dom'
import { ROUTER_PATH, DEFAULT_LANGUAGE } from '@/constants'
import { IMonitoringBoxes } from '@/interfaces'
import useMasterData from '@/hooks/useMasterData'
import { getValueMasterData, convertTimeToJapan } from '@/utils/helper'

// Component list of boxes
function BoxList() {
  const boxStatus = getValueMasterData(useMasterData('monitor_box_status'))

  const navigate = useNavigate()

  const { listBoxes } = useAppSelector(state => state.monitoring)
  const { languageMaster } = useAppSelector(state => state.app)

  // Get the correct status of box
  const statusBox = (data: IMonitoringBoxes) => {
    if (!boxStatus) return ''
    if (Number(data.status) === Number(boxStatus[2]?.value)) {
      return languageMaster === DEFAULT_LANGUAGE
        ? boxStatus[2]?.title_jp
        : boxStatus[2]?.title_en
    }
    return `${
      languageMaster === DEFAULT_LANGUAGE
        ? boxStatus[1]?.title_jp
        : boxStatus[1]?.title_en
    } (${
      data.start_time ? convertTimeToJapan(data.start_time).format('HH:mm') : ''
    } - ${
      data.end_time ? convertTimeToJapan(data.end_time).format('HH:mm') : ''
    })`
  }

  return (
    <Grid container spacing={1} sx={{ marginLeft: '1.875rem !important' }}>
      {listBoxes.map(item => (
        <Grid item xs={2.4} key={item.box_id} pl="0 !important" pr={1}>
          <Paper
            elevation={0}
            className="box-service-wrapper"
            onClick={() => {
              if (
                boxStatus &&
                Number(item.status) !== Number(boxStatus[3]?.value)
              )
                navigate(
                  generatePath(ROUTER_PATH.MONITORING_DETAILS, {
                    id: item.box_id.toString(),
                  })
                )
            }}
          >
            <Box
              className="box-service"
              sx={{
                backgroundImage: `url(${item!.images[0]})`,
              }}
            >
              {boxStatus &&
              Number(item.status) === Number(boxStatus[3]?.value) ? (
                <Stack p={1}>
                  <Typography
                    className="box-name"
                    position="absolute"
                    width="90% !important"
                  >
                    {item.box_name}
                  </Typography>
                  <Typography className="under-repair">
                    {boxStatus && languageMaster === DEFAULT_LANGUAGE
                      ? boxStatus[3]?.title_jp
                      : boxStatus[3]?.title_en}
                  </Typography>
                </Stack>
              ) : (
                <Stack
                  direction="column"
                  spacing={1}
                  position="absolute"
                  width="100%"
                  p={1}
                >
                  <Typography className="box-name">{item.box_name}</Typography>
                  <Typography className="time-use">
                    {statusBox(item)}
                  </Typography>
                </Stack>
              )}
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export default React.memo(BoxList)
