import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { INotice } from '@/interfaces'
import dayjs from 'dayjs'
import { DAYJS_FORMAT_NEW, HOUR_FORMAT, ROUTER_PATH } from '@/constants'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function NotificationItem({
  notice,
  setOpen,
}: {
  notice: INotice
  setOpen(state: boolean): void
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Stack
      direction="column"
      minHeight={87}
      px={2}
      py={0.5}
      justifyContent="space-between"
      onDoubleClick={() => {
        setOpen(false)
        navigate(ROUTER_PATH.CONTACT)
      }}
    >
      {/* Task #253212 */}
      <Typography className="notice-content">{notice.content}</Typography>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ fontSize: 14, color: 'GrayText' }}>
          {dayjs(notice.regist_date).format(
            `${DAYJS_FORMAT_NEW} ${HOUR_FORMAT}`
          )}
        </Typography>
        {!notice.seen ? (
          <Button variant="contained" sx={{ height: '24px !important' }}>
            {t('contact.new')}
          </Button>
        ) : (
          <Box />
        )}
      </Stack>
    </Stack>
  )
}

export default React.memo(NotificationItem)
