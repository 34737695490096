import '@/assets/styles/pages/Monitor/monitor.scss'
import Table from 'antd/es/table'
import { ColumnsType } from 'antd/lib/table/interface'
import React, { useEffect, useState } from 'react'

import { ROUTER_PATH } from '@/constants'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'

import useSocket from '@/hooks/useSocket'
import { showTotalPaginationTable } from '@/utils/helper'
import CircleIcon from '@mui/icons-material/Circle'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { generatePath, Navigate } from 'react-router-dom'
import { FETCH_LIST_MIRROR, SET_MIRROR_LIST_STATUS } from '@/redux/reducers/mirror.slice'

// Monitor List Page
function MonitorManagement() {
  const { currentUser } = useAppSelector(state => state.auth)
  const { listMirror, metaMirrorManagementState } = useAppSelector(
    state => state.mirror
  )

  const { isLoading } = useAppSelector(state => state.app)
  const { socket, isConnected } = useSocket()
  const dispatch = useAppDispatch()
  if (currentUser.role === 4) {
    return <Navigate to={ROUTER_PATH.DASHBOARD} />
  }

  const [queryParamsRequest, setQueryParamsRequest] = useState<{
    pageSize: number
    pageNumber: number
  }>({
    pageSize: 50,
    pageNumber: 1,
  })

  useEffect(() => {
    if (isConnected) {
      socket.on('check_mirror_active', (data: any) => {
        let paramsSocket: any = data
        if (typeof data === 'string') {
          paramsSocket = JSON.parse(data)
        }
        const newListMirror = [...listMirror];
        const index = newListMirror.findIndex((x: any)=> x.mirror_code === paramsSocket?.mirror_code)
        if (index !== -1) {
          const currentMirror = newListMirror[index]
          const status = paramsSocket?.status === 'active' ? 'active' : 'inactive'
          if (Number(paramsSocket?.displayType) === 1) {
            newListMirror[index] = {...currentMirror, top_status: status}
          } else if (Number(paramsSocket?.displayType) === 2) {
            newListMirror[index] = {...currentMirror, bottom_status: status}
          } else {
            newListMirror[index] = {...currentMirror, top_status: status, bottom_status: status}
          }
          dispatch(SET_MIRROR_LIST_STATUS(newListMirror))
        }
      })
    }
    return () => {
      if (socket) {
        socket.off('check_mirror_active')
      }
    }
  }, [isConnected, listMirror])

  if (currentUser.isBox) {
    return (
      <Navigate
        to={generatePath(ROUTER_PATH.MONITORING_DETAILS, {
          id: `${currentUser.id}`,
        })}
      />
    )
  }
  const { t } = useTranslation()
  // const { monitorList } = useAppSelector(state => state.monitor)

  const columns: ColumnsType<any> = [
    {
      key: 'mirror_code',
      dataIndex: 'mirror_code',
      title: t('mirror_management.mirror_code'),
      width: '20%',
      ellipsis: true,
    },
    {
      key: 'nickname',
      dataIndex: 'nickname',
      title: t('mirror_management.box_name'),
      width: '40%',
      ellipsis: true,
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('mirror_management.top_mirror'),
      render: (_, record) => (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          {record.top_status === 'active' ? (
            <CircleIcon sx={{ color: 'green' }} />
          ) : (
            <CircleIcon sx={{ color: 'red' }} />
          )}
        </Stack>
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('mirror_management.bottom_mirror'),
      render: (_, record) => (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          {record.bottom_status === 'active' ? (
            <CircleIcon sx={{ color: 'green' }} />
          ) : (
            <CircleIcon sx={{ color: 'red' }} />
          )}
        </Stack>
      ),
    },
  ]

  useEffect(() => {
    dispatch(FETCH_LIST_MIRROR(queryParamsRequest))
  }, [queryParamsRequest])

  const filterDataRequest = (pagination: any, filters: any) => {
    let queryParamsClone = { ...queryParamsRequest }
    queryParamsClone = {
      ...queryParamsClone,
      pageNumber: pagination.current,
    }
    if (Array.isArray(filters.area))
      queryParamsClone = {
        ...queryParamsClone,
        pageNumber:
          queryParamsRequest.pageNumber === pagination.current
            ? 1
            : pagination.current,
      }
    else
      queryParamsClone = {
        ...queryParamsClone,
        pageNumber:
          queryParamsRequest.pageNumber === pagination.current
            ? 1
            : pagination.current,
      }
    setQueryParamsRequest(queryParamsClone)
  }

  return (
    <Box className="monitor-list-container" p={1}>
      <Typography variant="h6" mb={2}>
        {t('mirror_management.title')}
      </Typography>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={listMirror}
            onChange={filterDataRequest}
            locale={{
              emptyText: t('monitoring_history.not_found'),
              filterReset: t('common.reset'),
              filterConfirm: t('common.apply'),
              triggerAsc: t('common.sort_asc'),
              triggerDesc: t('common.sort_desc'),
              cancelSort: t('common.cancel_sort'),
            }}
            pagination={{
              pageSize: metaMirrorManagementState.per_page,
              total: metaMirrorManagementState.total,
              current: metaMirrorManagementState
                ? metaMirrorManagementState?.current_page
                : 1,
              showSizeChanger: false,
              showTotal: () =>
                showTotalPaginationTable(
                  metaMirrorManagementState.per_page,
                  metaMirrorManagementState.current_page,
                  listMirror.length,
                  metaMirrorManagementState.total
                ),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default React.memo(MonitorManagement)
