// TODO: remove console.log
/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react'
import { Box, Stack } from '@mui/material'
import { CusTomHMSPeer } from '@/interfaces/hms'
import {
  selectAudioTrackByPeerID,
  useHMSActions,
  useHMSStore,
  selectIsPeerAudioEnabled,
} from '@100mslive/react-sdk'
import { MicOff, Mic } from '@mui/icons-material'

interface Props {
  participant: CusTomHMSPeer
  valueVolume: number
  isZoom: boolean
}

// Component user's main stream
function ParticipantStreamMain({ participant, valueVolume, isZoom }: Props) {
  const videoParticipant = useRef(null)
  const track = useHMSStore(selectAudioTrackByPeerID(participant.id))
  const isAudioEnabled = useHMSStore(selectIsPeerAudioEnabled(participant.id))
  const hmsActions = useHMSActions()

  useEffect(() => {
    const video: any = videoParticipant.current
    if (video) {
      let child = video.lastElementChild
      while (child) {
        video.removeChild(child)
        child = video.lastElementChild
      }
    }
    if (participant && participant.videoTrack && video) {
      console.log('hms public call - steam main - attachVideo')
      hmsActions.attachVideo(participant.videoTrack, video)
    }
  }, [participant])

  useEffect(() => {
    if (track) {
      hmsActions.setVolume(valueVolume, track.id)
    }
  }, [valueVolume, isZoom])

  return (
    <Box
      className="global-call-stream-main"
      sx={!participant.isZoom ? { display: 'none' } : {}}
      position="relative"
    >
      <video ref={videoParticipant} autoPlay muted playsInline />
      <Stack
        className="global-call-stream-participants-info"
        direction="row"
        width="100%"
        justifyContent="space-between"
      >
        <Box visibility="hidden" sx={{ marginLeft: 7 }}>
          {participant.name}
        </Box>
        <Box sx={{ marginRight: 7 }}>
          {!isAudioEnabled ? (
            <MicOff fontSize="small" />
          ) : (
            <Mic fontSize="small" />
          )}
        </Box>
      </Stack>
    </Box>
  )
}
export default React.memo(ParticipantStreamMain)
